import {
  distinctUntilChanged,
  map,
  share,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { BackendStatePolling } from './backend-state-polling';
import { Finalizable } from '../../../../utils/finalizable';
import { Observable, firstValueFrom } from 'rxjs';
import { BackendService } from '../../backend.service';
import { UserSessionEventTrackingService } from '../../user-session/user-session-event-tracking.service';
import { UserSessionSystemEventName } from '../../user-session/user-session-system-events';

const SHOW_SKIP_ROBOT_INQUIRY_MILLIS = 30 * 1000;
export const FINALIZE_ROBOT_COMMUNICATION_THRESHOLD_MILLIS = 30 * 1000;

export class RobotBlocking extends Finalizable {
  private accessGroups: string[] = [];
  private blockedSinceMillis: number | null = null;

  readonly isBlockedForMillis$ = this.backendStatePolling.robotState$.pipe(
    tap((robot) => {
      this.accessGroups = robot.accessGroups ?? [];
    }),
    map((robot) => {
      if (!robot.stopState?.stoppedSince) {
        this.blockedSinceMillis = null;
        return 0;
      }
      if (this.blockedSinceMillis === null) {
        this.blockedSinceMillis = Date.now();
      }
      if (!robot.stopState.isBlocked) {
        return 0;
      }
      const blockedSince =
        new Date(robot.stopState.stoppedSince)?.getTime() ?? 0;
      return Date.now() - blockedSince;
    }),
    share(),
  );

  readonly robotIsBlockedByCurrentUserMilles$ = this.isBlockedForMillis$.pipe(
    map(() =>
      this.blockedSinceMillis !== null
        ? Date.now() - this.blockedSinceMillis
        : 0,
    ),
    share(),
  );

  private showSkipRobotSince: number | null = null;

  readonly finalizeBlockedRobotCommunicationInMillis$: Observable<
    number | null
  > = this.robotIsBlockedByCurrentUserMilles$.pipe(
    switchMap(async (blockedByCurrentUserForMillis) => {
      if (blockedByCurrentUserForMillis < SHOW_SKIP_ROBOT_INQUIRY_MILLIS) {
        this.showSkipRobotSince = null;
        return null;
      }
      const { exists } = await firstValueFrom(
        this.backendService.get(
          `/robot-operator-matching/has-free-robot-slots?access_groups=${this.accessGroups.join(
            ',',
          )}`,
        ),
      );

      if (!exists) {
        this.userSessionEventTrackingService.trackSystemEvent(
          UserSessionSystemEventName.NO_SLOTS_FOR_BLOCKED_ROBOT,
          {
            robotId: this.robotId,
            blockedForSeconds: blockedByCurrentUserForMillis / 1000,
          },
        );
        this.showSkipRobotSince = null;
        return null;
      }

      const now = Date.now();
      if (this.showSkipRobotSince === null) {
        this.showSkipRobotSince = now;
      }

      return (
        FINALIZE_ROBOT_COMMUNICATION_THRESHOLD_MILLIS -
        (Date.now() - this.showSkipRobotSince)
      );
    }),
    distinctUntilChanged(),
    share(),
  );

  readonly blockedRobotIsReadyForFinalization$ =
    this.finalizeBlockedRobotCommunicationInMillis$.pipe(
      takeUntil(this.finalized$),
      map((finalizeBlockedRobotCommunicationInMillis) => {
        return (
          finalizeBlockedRobotCommunicationInMillis !== null &&
          finalizeBlockedRobotCommunicationInMillis <= 0
        );
      }),
    );

  constructor(
    private readonly robotId: string,
    private readonly backendService: BackendService,
    private readonly backendStatePolling: BackendStatePolling,
    private readonly userSessionEventTrackingService: UserSessionEventTrackingService,
  ) {
    super();
  }

  snoozeSkipRobotInquiry() {
    this.blockedSinceMillis = null;
    this.showSkipRobotSince = null;
  }

  protected override async onFinalize(): Promise<void> {
    // nothing to do, just implement an interface
  }
}
