import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import {
  Compartment,
  CompartmentState,
} from '../robots-service/backend/robot.dto';

export interface CompartmentsDialogData {
  compartments$: Observable<Compartment[]>;
  isNotInControl$: Observable<boolean>;
  openCompartmentCallback: (compartmentId: string) => void;
  closeCompartmentCallback: (compartmentId: string) => void;
}

@Component({
  selector: 'app-compartments-dialog',
  templateUrl: './compartments-dialog.component.html',
  styleUrls: ['./compartments-dialog.component.sass'],
})
export class CompartmentsDialogComponent implements OnDestroy {
  data: CompartmentsDialogData;
  waitingForUpdate = false;

  private destroyed$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CompartmentsDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) data: CompartmentsDialogData,
  ) {
    this.data = data;
  }

  ngOnDestroy() {
    this.destroyed$.next(undefined);
  }

  isLocked(compartment: Compartment): boolean {
    return compartment.state === CompartmentState.CLOSED_AND_LOCKED;
  }

  setRobotCompartmentRequest(compartment: Compartment, checked: boolean) {
    if (checked === true) {
      this.data.closeCompartmentCallback(compartment.id);
    } else {
      this.data.openCompartmentCallback(compartment.id);
    }
    this.waitingForUpdate = true;
    setTimeout(() => (this.waitingForUpdate = false), 2000);
  }

  compartmentStateToIconName(compartment: Compartment) {
    if (
      compartment.state === CompartmentState.OPEN ||
      compartment.state === CompartmentState.OPEN_AND_PRIMED
    ) {
      return 'arrow_upward';
    }

    if (compartment.state === CompartmentState.CLOSED_AND_UNLOCKED) {
      return 'lock_open';
    }

    if (compartment.state === CompartmentState.CLOSED_AND_LOCKED) {
      return 'lock';
    }
    return 'no_encryption';
  }
}
