import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  CollectionDto,
  DatasetSyncConfigDto,
  FrameDto,
  LabelTypeDto,
} from '../ml-data-types';
import { DataItem } from '../shared/selected-item.component';
import { MlDataService } from '../ml-data.service';
import { MlActionService } from '../ml-action.service';
import { ErrorService } from '../../../app/core/error-system/error.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialog,
  ConfirmationDialogData,
} from '../../../app/core/confirmation-dialog/confirmation-dialog.component';
import { firstValueFrom } from 'rxjs';

const addCollectionErrorMessage = 'Failed to add collection to dataset';
const deleteCollectionErrorMessage = 'Failed to delete collection from dataset';

@Component({
  selector: 'app-collection-selection',
  templateUrl: './collection-selection.component.html',
  styleUrls: ['./collection-selection.component.sass'],
})
export class CollectionSelectionComponent implements OnChanges {
  @Input()
  collection!: DataItem;

  @Input()
  selectedFrame?: FrameDto;

  datasetSyncConfigs?: DatasetSyncConfigDto[];
  labelNames: string[] = [];
  selectedLabelName?: string;
  datasetName: string = '';

  isDataComplete = false;

  private labels: LabelTypeDto[] = [];

  constructor(
    private readonly mlDataService: MlDataService,
    private readonly mlActionService: MlActionService,
    private readonly errorService: ErrorService,
    private readonly dialog: MatDialog,
  ) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const currentCollection: CollectionDto | undefined =
      changes['collection'].currentValue;
    const previousCollection: CollectionDto | undefined =
      changes['collection'].previousValue;

    if (currentCollection && currentCollection?.id !== previousCollection?.id) {
      await this.fetchDataSyncConfigs();
    }
  }

  updateInput() {
    this.isDataComplete =
      this.selectedLabelName !== undefined && this.datasetName !== '';
  }

  updateDatasetName(datasetName: string) {
    this.datasetName = datasetName;
    this.updateInput();
  }

  async addCollectionToDataset() {
    try {
      if (!this.selectedLabelName) {
        return;
      }
      const labelTypeId = this.labels.find(
        (label) => label.name === this.selectedLabelName,
      );
      if (!labelTypeId) {
        await this.mlActionService.addLabelType(this.selectedLabelName);
        await this.fetchDataSyncConfigs();
        await this.addCollectionToDataset();
        return;
      }
      const createSegmentAiSyncConfig = await firstValueFrom(
        this.dialog
          .open<ConfirmationDialog, ConfirmationDialogData>(
            ConfirmationDialog,
            {
              data: {
                message: `Are you sure you want to add collection to dataset?`,
              },
            },
          )
          .afterClosed(),
      );

      if (!createSegmentAiSyncConfig) {
        return;
      }

      await this.mlActionService.createSegmentsAiSyncConfig({
        datasetName: this.datasetName!,
        collectionId: this.collection.id,
        labelTypeId: this.labels.find(
          (label) => label.name === this.selectedLabelName,
        )!.id,
      });
      this.updateDatasetName('');
      await this.fetchDataSyncConfigs();
      this.errorService.dismissError(addCollectionErrorMessage);
    } catch (e) {
      this.errorService.reportError(addCollectionErrorMessage);
      console.error(addCollectionErrorMessage, e);
    }
  }

  async deleteCollectionFromDataset(datasetConfigId: number) {
    try {
      const deleteSegmentAiSyncConfig = await firstValueFrom(
        this.dialog
          .open<ConfirmationDialog, ConfirmationDialogData>(
            ConfirmationDialog,
            {
              data: {
                message: `Are you sure you want to remove collection from dataset?`,
              },
            },
          )
          .afterClosed(),
      );

      if (!deleteSegmentAiSyncConfig) {
        return;
      }

      await this.mlActionService.deleteSegmentsAiSyncConfig(datasetConfigId);
      await this.fetchDataSyncConfigs();
      this.errorService.dismissError(deleteCollectionErrorMessage);
    } catch (e) {
      this.errorService.reportError(deleteCollectionErrorMessage);
      console.error(deleteCollectionErrorMessage, e);
    }
  }

  private async fetchDataSyncConfigs() {
    this.labels = await this.mlDataService.getLabelTypes();
    this.labelNames = this.labels.map((label) => label.name);

    this.datasetSyncConfigs =
      await this.mlDataService.getSegmentsAiSyncConfigByCollectionId(
        this.collection.id,
      );
  }
}
