import { Injectable } from '@angular/core';
import { WebSocketService } from '../web-socket/web-socket.service';
import {
  BehaviorSubject,
  EmptyError,
  firstValueFrom,
  merge,
  Subject,
} from 'rxjs';
import { filter, scan, shareReplay, takeUntil } from 'rxjs/operators';
import { millisBetween } from '../../../utils/millis-between';
import { resolveWithTimeout } from '../../../utils/resolve-with-timeout';
import { RobotOperatorViewObj } from './robot-operators-data';
import { visiblePageTimer } from '../../../utils/page-visibility';
import {
  BackendMessage,
  BackendMessageType,
  FAILED_ESTABLISH_CONNECTION_MSG,
  LOST_CONNECTION_MSG,
  MAX_RECONNECT_DEBOUNCE_MILLIS,
  PingMessage,
  PONG_DEATH_WATCHDOG_MILLIS,
  PONG_INTERVAL_WATCHDOG_MILLIS,
  ResourceUpdatedMessage,
  ROBOT_CONTROL_SESSION_ID_MISMATCH_COUNT_THRESHOLD,
  ROBOT_CONTROL_SESSION_ID_MISMATCH_MSG,
  SOCKET_CONNECTION_FAILURE_BACKOFF_MILLIS,
  UserMessage,
  UserMessageType,
  WEBSOCKET_CONNECT_LIMIT,
} from './user-session.utils';
import { sleep } from '../../../utils/sleep';
import { not } from 'ramda';

@Injectable({
  providedIn: 'root',
})
export class UserSessionConnectionService {
  private socketConnectionFailureCount = 0;

  private sessionId = `${(Math.random() * 100000).toFixed()}-${Date.now()}`;

  readonly connection = this.webSocketService.connect<
    BackendMessage,
    UserMessage
  >('user-session', this.sessionId);

  private _assignedRobots$ = new BehaviorSubject<string[]>([]);
  readonly assignedRobots$ = this._assignedRobots$.asObservable();

  private _robotOperatorData$ = new Subject<RobotOperatorViewObj>();
  readonly robotOperatorData$ = this._robotOperatorData$.pipe(shareReplay(1));

  readonly ping$ = new Subject<void>();
  readonly pingCount$ = this.ping$.pipe(scan((prev) => prev + 1, 0));

  private _checkIn = new Subject<void>();
  readonly checkIn$ = this._checkIn.asObservable();

  private _sessionCollision$ = new Subject<void>();
  readonly sessionCollision$ = this._sessionCollision$.asObservable();

  private _error$ = new Subject<string>();
  readonly error$ = this._error$.asObservable();

  private _initialMessageNeeded$ = new Subject<void>();
  readonly initialMessageNeeded$ = this._initialMessageNeeded$.asObservable();

  private _preassignedRobotId$ = new BehaviorSubject<string | undefined>(
    undefined,
  );
  readonly preassignedRobotId$ = this._preassignedRobotId$.asObservable();

  private _resourceUpdatedEvent$ = new Subject<
    Omit<ResourceUpdatedMessage, 'type'>
  >();
  resourceUpdatedEvent$ = this._resourceUpdatedEvent$.asObservable();

  private _stayOnline$ = new BehaviorSubject<boolean>(false);
  readonly stayOnline$ = this._stayOnline$.asObservable();
  readonly stopConnection$ = this._stayOnline$.pipe(filter(not));

  private terminateCurrentConnection$ = new Subject<void>();

  private isConnecting = false;
  private sessionIdMismatchCount = 0;

  private lastPingDate?: Date;
  private robotControlSessionActive = false;

  constructor(private readonly webSocketService: WebSocketService) {
    this.connection.message$.subscribe((message) => {
      switch (message.type) {
        case BackendMessageType.ROBOTS_ASSIGNED:
          this._assignedRobots$.next(message.assignedRobots);
          break;
        case BackendMessageType.PING:
          this.handlePong(message);
          break;
        case BackendMessageType.ROBOT_OPERATOR_DATA:
          this._robotOperatorData$.next({ operators: message.operators });
          break;
        case BackendMessageType.CHECK_IN:
          this._checkIn.next();
          break;
        case BackendMessageType.RESOURCE_UPDATED:
          this._resourceUpdatedEvent$.next({
            resourceType: message.resourceType,
            resourceId: message.resourceId,
          });
          break;
        case BackendMessageType.PREASSIGNED_ROBOT:
          this._preassignedRobotId$.next(
            message.robotId === '' ? undefined : message.robotId,
          );
          break;
          break;
        default:
          console.error('Unknown user session message type', message);
      }
    });

    this.connection.unexpectedClose$.subscribe(() => {
      this.restartConnection();
    });

    this.connection.error$
      .pipe(takeUntil(this.stopConnection$))
      .subscribe(async (e) => {
        console.error('User log connection error', e);
        this._error$.next(LOST_CONNECTION_MSG);
      });
  }

  private handlePong(message: PingMessage) {
    if (
      message.sessionId !== this.connection.sessionId &&
      this.robotControlSessionActive
    ) {
      this.sessionIdMismatchCount++;
    } else {
      this.sessionIdMismatchCount = 0;
    }

    if (
      this.sessionIdMismatchCount >
      ROBOT_CONTROL_SESSION_ID_MISMATCH_COUNT_THRESHOLD
    ) {
      this._error$.next(ROBOT_CONTROL_SESSION_ID_MISMATCH_MSG);
      this._sessionCollision$.next();
      return;
    }

    this.connection.send({
      type: UserMessageType.PONG,
      requestEpoch: message.requestEpoch,
    });

    this.ping$.next();
    this.lastPingDate = new Date();
  }

  async startConnection() {
    if (this.isConnecting) {
      return;
    }
    this.isConnecting = true;
    try {
      this._stayOnline$.next(true);
      this.terminateCurrentConnection$.next();
      await resolveWithTimeout(
        firstValueFrom(
          this.connection
            .start()
            .pipe(
              takeUntil(this.stopConnection$),
              takeUntil(this.terminateCurrentConnection$),
            ),
        ),
        WEBSOCKET_CONNECT_LIMIT * (this.socketConnectionFailureCount + 1),
        'Failed to establish connection',
      );
      this._initialMessageNeeded$.next();
      this.startWatchDog();
      this.socketConnectionFailureCount = 0;
    } catch (e) {
      if (e instanceof EmptyError) {
        return;
      }
      console.error('User log connection error', e);
      this._error$.next(FAILED_ESTABLISH_CONNECTION_MSG);
      await sleep(
        Math.min(
          SOCKET_CONNECTION_FAILURE_BACKOFF_MILLIS *
            2 ** this.socketConnectionFailureCount,
          MAX_RECONNECT_DEBOUNCE_MILLIS,
        ),
      );
      this.socketConnectionFailureCount++;
      this.restartConnection();
    } finally {
      this.isConnecting = false;
    }
  }

  private startWatchDog() {
    visiblePageTimer(
      PONG_INTERVAL_WATCHDOG_MILLIS,
      PONG_INTERVAL_WATCHDOG_MILLIS,
    )
      .pipe(takeUntil(this.terminateCurrentConnection$))
      .subscribe(async () => {
        const now = new Date();
        const lastCheckMillisSinceEpoch = this.lastPingDate ?? new Date(0);
        const timeSinceLastCheckInMillis = millisBetween(
          lastCheckMillisSinceEpoch,
          now,
        );
        if (timeSinceLastCheckInMillis > PONG_DEATH_WATCHDOG_MILLIS) {
          console.error(
            'No ping, Restart connection',
            timeSinceLastCheckInMillis,
          );
          await this.restartConnection();
        }
      });
  }

  private async restartConnection() {
    if (this._stayOnline$.getValue() === false || this.isConnecting) {
      return;
    }
    this.connection.close();
    await this.startConnection();
  }

  stopConnection() {
    this._stayOnline$.next(false);
    this.terminateCurrentConnection$.next();

    this.lastPingDate = undefined;
    this.connection.close();
  }

  startRobotControlSession() {
    this.connection.send({
      type: UserMessageType.START_ROBOT_CONTROL,
    });

    this.robotControlSessionActive = true;
  }

  stopRobotControlSession() {
    this.connection.send({
      type: UserMessageType.STOP_ROBOT_CONTROL,
    });

    this.robotControlSessionActive = false;
  }
}
