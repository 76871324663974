<div class="selection-container" [class.active-selection-container]="active">
  <div class="selection-insides">
    <div *ngIf="!active" (click)="setActive(true)" class="placeholder">
      <div>{{ selected ?? label }}</div>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
    <input
      #selectionInput
      *ngIf="active"
      type="text"
      [value]="searchString"
      (input)="filterOptions($event.target.value)"
      (focus)="active = true"
      (blur)="browseOptions === false && (active = false)"
      class="selection-input"
    />
  </div>
  <div
    *ngIf="active"
    (mouseenter)="browseOptions = true"
    (mouseleave)="browseOptions = false"
    class="options"
  >
    <div
      *ngFor="let option of filteredOptions"
      class="options-item"
      (click)="selectOption(option)"
    >
      {{ option }}
    </div>
  </div>
</div>
