<div class="collection-selection-title" *ngIf="!selectedFrame">
  Collection Info
</div>
<div class="collection-selection-title" *ngIf="selectedFrame">Frame Info</div>
<mat-divider></mat-divider>

<div>
  <app-selected-item
    class="collection-selection-item-container"
    style="display: contents"
    [metadataItem]="selectedFrame ?? collection"
    [selectedItem]="selectedFrame ?? collection"
    [previewImage]="selectedFrame?.url"
  >
  </app-selected-item>
</div>

<mat-divider></mat-divider>

<div class="collection-selection-title" *ngIf="!selectedFrame">Datasets</div>

<mat-divider></mat-divider>

<div *ngIf="!selectedFrame">
  <div class="collection-dataset-list-container">
    <div
      *ngFor="let datasetSyncConfig of datasetSyncConfigs"
      class="collection-selection-title"
    >
      {{ datasetSyncConfig.datasetName }}

      <mat-icon
        class="delete-dataset-button"
        (click)="deleteCollectionFromDataset(datasetSyncConfig.id)"
        >clear</mat-icon
      >
    </div>
  </div>
  <mat-divider></mat-divider>

  <div class="collection-dataset-input-padding">
    <input
      matInput
      placeholder="Dataset name"
      [value]="datasetName"
      (input)="updateDatasetName($event.target.value)"
      class="collection-add-dataset-input"
    />

    <selection-dropbox
      class="collection-add-dataset-type-input"
      label="Label types"
      [options]="labelNames"
      [(selected)]="selectedLabelName"
      (selectedChange)="updateInput()"
      customInput="true"
    ></selection-dropbox>
  </div>
  <div class="add-dataset-button">
    <button
      *ngIf="isDataComplete"
      mat-icon-button
      (click)="addCollectionToDataset()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
