import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { MaterialModule } from '../material/material.module';
import { FormsModule } from '@angular/forms';
import { MapEditorComponent } from './map-editor.component';
import { VisibilityDialogComponent } from './dialogs/visibility-dialog.component';
import { RouterModule } from '@angular/router';
import { ChangeMapVersionDialogComponent } from './dialogs/change-map-version-dialog.component';
import { LoadChangesetDialogComponent } from './dialogs/load-changeset-dialog.component';
import { SaveChangesetDialogComponent } from './dialogs/save-changeset-dialog.component';
import { ViewLocationDialogComponent } from './dialogs/view-location-dialog.component';

@NgModule({
  declarations: [
    MapEditorComponent,
    LoadChangesetDialogComponent,
    SaveChangesetDialogComponent,
    ChangeMapVersionDialogComponent,
    ViewLocationDialogComponent,
    VisibilityDialogComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    MaterialModule,
    FormsModule,
    RouterModule,
  ],
})
export class MapEditorModule {}
