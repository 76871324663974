import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MlDataService } from '../ml-data.service';
import { CollectionDto } from '../ml-data-types';
import { MlActionService } from '../ml-action.service';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.sass'],
})
export class LeftSidebarComponent implements OnInit {
  addCollectionModeOn = false;

  collections: CollectionDto[] = [];

  @Output()
  selectCollection = new EventEmitter<CollectionDto | null>();

  selectedCollection: CollectionDto | null = null;

  constructor(
    private mlDataService: MlDataService,
    private mlActionService: MlActionService,
  ) {}

  async ngOnInit() {
    await this.fetchCollections();
  }

  setCollection(collection: CollectionDto) {
    this.selectedCollection =
      this.selectedCollection?.id === collection.id ? null : collection;
    this.selectCollection.emit(this.selectedCollection);
  }

  async createCollection(collectionName: string) {
    this.addCollectionModeOn = false;
    await this.mlActionService.createCollections(collectionName);
    await this.fetchCollections();
  }

  async fetchCollections() {
    this.collections = await this.mlDataService.getCollections();
  }
}
