<div class="main-container">
  <div class="controls-container">
    <div *ngIf="_isFocused" class="map-and-control-grid">
      <div class="robot-control-container">
        <robot-control
          [isKeyboardEnabled]="_isFocused"
          [robotCommunication]="robotCommunication"
          [activeControl]="true"
          [highResolution]="true"
        ></robot-control>
        <div *ngIf="stopRobotControl$ | async" class="close-overlay">
          <mat-icon class="block-icon">block</mat-icon>
          Please, close connection
        </div>
      </div>
      <mini-map
        [isCustomGpsClickEnabled]="false"
        [isDirectionsClickEnabled]="false"
        [robotCommunication]="robotCommunication"
      ></mini-map>
    </div>

    <div
      *ngIf="!_isFocused"
      class="robot-and-overlay"
      (mouseenter)="onMouseIn()"
      (mouseleave)="onMouseOut()"
      [class.full-screen]="showFullScreenPreview$ | async"
      [class.needs-attention]="requireAttention$ | async"
    >
      <robot-control
        [isKeyboardEnabled]="_isFocused || isMouseHovering"
        [robotCommunication]="robotCommunication"
        [activeControl]="!(showFullScreenPreview$ | async)"
        [highResolution]="true"
        (needsAttention)="
          handleAttentionRequest('crossing-confirmation', $event)
        "
      ></robot-control>
      <full-screen-overlay
        *ngIf="
          (robotCommunication?.finalizeBlockedRobotCommunicationInMillis$
            | async) > 0
        "
        style="display: contents; width: 100px; height: 100px"
        message="Robot is currently blocked. It will be assigned to another operator in"
        buttonMessage="Keep control"
        [countDown]="
          robotCommunication?.finalizeBlockedRobotCommunicationInMillis$ | async
        "
        (buttonClick)="snoozeSkipRobotInquiry($event)"
      ></full-screen-overlay>

      <full-screen-overlay
        *ngIf="showSkipRobotForUnsupervisedAutonomyOverlay$ | async"
        style="display: contents; width: 100px; height: 100px"
        message="Robot should be skipped to activate unsupervised autonomy. Will be skipped in"
        buttonMessage="Keep control"
        [countDown]="
          robotCommunication?.finalizeUnsupervisedAutonomyRobotCommunicationInMillis$
            | async
        "
        (buttonClick)="snoozeSkipRobotInquiry($event)"
      ></full-screen-overlay>

      <div *ngIf="isControlledByOtherOperator" class="close-overlay">
        <mat-icon class="block-icon">block</mat-icon>
        Control was claimed by another operator
      </div>
      <mini-map
        *ngIf="showFullScreenPreview$ | async"
        [isCustomGpsClickEnabled]="false"
        [isDirectionsClickEnabled]="false"
        [robotCommunication]="robotCommunication"
      ></mini-map>
    </div>

    <div class="controls">
      <app-robot-control-panel
        class="controls-panel"
        [robotCommunication]="robotCommunication"
        [isClaimControlEnabled]="false"
        [isManualControlEnabled]="false"
        [autonomyControlType]="'PARKING_MODE'"
        [hazardLightControlType]="'HAZARD_LIGHT_TOGGLE'"
        [isNavigationToMaintenanceEnabled]="_isFocused"
        [isCurbClimbingControlEnabled]="_isFocused"
        [isReadyForOrderControlEnabled]="false"
        [isIssueReportingEnabled]="_isFocused"
        [active]="_isFocused || isMouseHovering"
        [isRelocalizeEnabled]="_isFocused"
        [isCalibrateEndstopEnabled]="_isFocused"
        [isNetworkInterfaceControlEnabled]="true"
        [isBlockageReportEnabled]="_isFocused"
      >
      </app-robot-control-panel>
      <button
        mat-mini-fab
        color="primary"
        style="margin-left: 16px"
        (click)="emitFocusChange(!_isFocused)"
        [matTooltip]="
          _isFocused ? 'Control multiple robots' : 'Focus on single robot'
        "
      >
        <mat-icon *ngIf="_isFocused">zoom_in_map</mat-icon>
        <mat-icon *ngIf="!_isFocused">zoom_out_map</mat-icon>
      </button>

      <button
        *ngIf="!_isFocused"
        mat-mini-fab
        color="primary"
        style="margin-left: 16px"
        (click)="clickRemoveRobot()"
        [matTooltip]="'Disable a robot slot'"
      >
        <mat-icon>clear</mat-icon>
      </button>

      <button
        *ngIf="!_isFocused"
        mat-mini-fab
        color="primary"
        style="margin-left: 16px"
        (click)="skipRobot()"
        [matTooltip]="'Skip robot'"
      >
        <mat-icon>forward_arrow</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="_isFocused" class="status-tree-container">
    <optional-status-tree
      [robotCommunication]="robotCommunication"
    ></optional-status-tree>
  </div>
</div>
