import { Component, OnDestroy, OnInit } from '@angular/core';
import { BackendService } from '../../core/backend.service';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RobotsBackendService } from '../../core/robots-service/robots-backend.service';
import { FormControl } from '@angular/forms';
import { DateTime } from 'luxon';

// Only at least 6 minute old records can be requested
// This is due to how the blackbox works, it records 5 minutes
// at a time and only after these 5 minutes they are available.
const MIN_REQUESTED_RECORD_AGE_MINUTES = 6 * 60 * 1000;

@Component({
  selector: 'robot-blackbox',
  templateUrl: './robot-blackbox.component.html',
  styleUrls: ['./robot-blackbox.component.sass'],
})
export class RobotBlackboxComponent implements OnInit, OnDestroy {
  private robotId: string | null = null;
  robotSerialNumber: number | null = null;

  blackboxRequestStartTime: Date = new Date(Date.now() - 30 * 60 * 1000);

  blackboxRequestDurationMinutes: number | undefined = 2;

  private destroy$ = new ReplaySubject<void>(1);
  records: BlackboxRecord[] = [];

  selectedTimeZone: string | undefined = undefined;
  readonly supportedTimeZones = [
    'America/New_York',
    'America/Los_Angeles',
    'Asia/Tokyo',
    'Europe/Berlin',
    'UTC',
  ];

  constructor(
    private backendService: BackendService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private robotsService: RobotsBackendService,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.robotId = params.get('robot-id');
      if (this.robotId != null) {
        this.populateRobotSerialNumber(this.robotId);
      }
      this.onUpdate();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onUpdate() {
    if (this.robotId === null) {
      return;
    }
    const path = `/robot-blackbox?robotId=${this.robotId}`;
    this.backendService
      .get(path)
      .pipe(takeUntil(this.destroy$))
      .subscribe((records: BlackboxRecords) => {
        this.records = records.records;
      });
  }

  updateDuration(durationMinutes?: string) {
    if (durationMinutes === undefined) {
      this.blackboxRequestDurationMinutes = undefined;
      return;
    }
    const parsed = Number.parseFloat(durationMinutes);
    if (Number.isNaN(parsed)) {
      this.blackboxRequestDurationMinutes = undefined;
      return;
    } else {
      this.blackboxRequestDurationMinutes = parsed;
    }
  }

  private locateInputDateInSelectedTimezone(date: Date) {
    return DateTime.local(
      date.getFullYear(),
      //Javascript month is zero indexed but 1 indexed is expected
      date.getMonth() + 1,
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
      { zone: this.selectedTimeZone },
    ).toJSDate();
  }

  formatDate(dateString: string) {
    let dateTime;
    if (this.selectedTimeZone !== undefined) {
      dateTime = DateTime.fromISO(dateString).setZone(this.selectedTimeZone);
    } else {
      dateTime = DateTime.fromISO(dateString).toLocal();
    }
    return dateTime.toLocaleString(DateTime.DATETIME_SHORT, {
      locale: 'de-DE',
    });
  }

  requestRecording() {
    const startTime: Date = this.locateInputDateInSelectedTimezone(
      this.blackboxRequestStartTime,
    );
    const duration = this.blackboxRequestDurationMinutes;
    if (startTime === undefined || duration === undefined || duration <= 0) {
      this.snackBar.open(
        "Can't send request, please fill out all fields.",
        'Ok',
      );
      return;
    }

    const endTime = new Date(
      startTime.getTime() + duration * 60 * 1000 /*Minutes to ms */,
    );

    const duration_to_request_end = Date.now() - endTime.getTime();
    if (duration_to_request_end < MIN_REQUESTED_RECORD_AGE_MINUTES) {
      this.snackBar.open(
        `Please try again in ${Math.ceil(
          (MIN_REQUESTED_RECORD_AGE_MINUTES - duration_to_request_end) /
            (60 * 1000),
        )} minutes. Records can only be accessed after a certain time.`,
        'Ok',
      );
      return;
    }

    const path = '/robot-blackbox';
    this.backendService
      .post(path, {
        robotId: this.robotId,
        startTime: startTime,
        endTime: endTime,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.snackBar.open('Request sent, might take a bit.', 'Ok');
        this.onUpdate();
      });
  }

  private populateRobotSerialNumber(robotId: string) {
    this.robotsService
      .getRobot(robotId)
      .then((robot) => (this.robotSerialNumber = robot.serialNumber))
      .catch((e) =>
        console.error(`Failed to fetch robot '${robotId}'. Reason:`, e),
      );
  }
}
