import destination from '@turf/destination';
import bearing from '@turf/bearing';
import pointToLineDistance from '@turf/point-to-line-distance';
import { flattenEach } from '@turf/meta';
import { point, MultiLineString } from '@turf/helpers';
import { getCoords } from '@turf/invariant';
import WebMercatorViewport from 'viewport-mercator-project';
import {
  Viewport,
  Pick,
  EditHandleFeature,
  EditHandleType,
  ModeProps,
} from './types';
import {
  Geometry,
  Position,
  Point,
  LineString,
  FeatureOf,
  FeatureWithProps,
} from './geojson-types';
import { TileLayer } from '@deck.gl/geo-layers/typed';
import { BitmapLayer } from '@deck.gl/layers/typed';
import { Layer } from '@deck.gl/core/typed';
import { MapElementDto } from '@cartken/map-types';

export type Bounds = [minX: number, minY: number, maxX: number, maxY: number];

export function getBounds(coordinates: number[][]): Bounds {
  const bounds: Bounds = [
    coordinates[0][0],
    coordinates[0][1],
    coordinates[0][0],
    coordinates[0][1],
  ];
  for (let i = 1; i < coordinates.length; ++i) {
    bounds[0] = Math.min(bounds[0], coordinates[i][0]);
    bounds[1] = Math.min(bounds[1], coordinates[i][1]);
    bounds[2] = Math.max(bounds[2], coordinates[i][0]);
    bounds[3] = Math.max(bounds[3], coordinates[i][1]);
  }

  return bounds;
}

export function createTileLayer(
  urlTemplate: string | string[],
  id: string,
  minZoom = 0,
  maxZoom = 19,
  tileSize = 256,
  opacity = 1,
  boundingPolygon?: number[][],
  visible = true,
): Layer {
  return new TileLayer({
    id,
    data: urlTemplate,
    minZoom,
    maxZoom,
    tileSize,
    visible,
    maxRequests: 20, // Concurrent requests to make loading faster.
    extent: boundingPolygon ? getBounds(boundingPolygon) : undefined,
    renderSubLayers: (props) => {
      const [min, max] = props.tile.boundingBox;
      return new BitmapLayer(props, {
        data: null as any,
        image: props.data,
        bounds: [min[0], min[1], max[0], max[1]],
      });
    },
    onTileError: (e) => {},
    opacity,
  });
}

export type NearestPointType = FeatureWithProps<
  Point,
  { dist: number; index: number }
>;

//
// a GeoJSON helper function that calls the provided function with
// an argument that is the most deeply-nested array having elements
// that are arrays of primitives as an argument, e.g.
//
// {
//   "type": "MultiPolygon",
//   "coordinates": [
//       [
//           [[30, 20], [45, 40], [10, 40], [30, 20]]
//       ],
//       [
//           [[15, 5], [40, 10], [10, 20], [5, 10], [15, 5]]
//       ]
//   ]
// }
//
// the function would be called on:
//
// [[30, 20], [45, 40], [10, 40], [30, 20]]
//
// and
//
// [[15, 5], [40, 10], [10, 20], [5, 10], [15, 5]]
//
export function recursivelyTraverseNestedArrays(
  array: Array<any>,
  prefix: Array<number>,
  fn: Function,
) {
  if (!Array.isArray(array[0])) {
    return true;
  }
  for (let i = 0; i < array.length; i++) {
    if (recursivelyTraverseNestedArrays(array[i], [...prefix, i], fn)) {
      fn(array, prefix);
      break;
    }
  }
  return false;
}

export function generatePointsParallelToLinePoints(
  p1: Position,
  p2: Position,
  mapCoords: Position,
): Position[] {
  const lineString: LineString = {
    type: 'LineString',
    coordinates: [p1, p2],
  };
  const ddistance = pointToLineDistance(mapCoords, lineString);
  const lineBearing = bearing(p1, p2);

  // Check if current point is to the left or right of line
  // Line from A=(x1,y1) to B=(x2,y2) a point P=(x,y)
  // then (x−x1)(y2−y1)−(y−y1)(x2−x1)
  const isPointToLeftOfLine =
    (mapCoords[0] - p1[0]) * (p2[1] - p1[1]) -
    (mapCoords[1] - p1[1]) * (p2[0] - p1[0]);

  // Bearing to draw perpendicular to the line string
  const orthogonalBearing =
    isPointToLeftOfLine < 0 ? lineBearing - 90 : lineBearing - 270;

  // Get coordinates for the point p3 and p4 which are perpendicular to the lineString
  // Add the distance as the current position moves away from the lineString
  const p3 = destination(p2, ddistance, orthogonalBearing);
  const p4 = destination(p1, ddistance, orthogonalBearing);

  return [p3.geometry.coordinates, p4.geometry.coordinates] as Position[];
}

export function distance2d(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
): number {
  const dx = x1 - x2;
  const dy = y1 - y2;
  return Math.sqrt(dx * dx + dy * dy);
}

export function mix(a: number, b: number, ratio: number): number {
  return b * ratio + a * (1 - ratio);
}

export function nearestPointOnProjectedLine(
  line: FeatureOf<LineString>,
  inPoint: FeatureOf<Point>,
  viewport: Viewport,
  altitudeTransformer?: (a: number | undefined) => number,
): NearestPointType {
  const wmViewport = new WebMercatorViewport(viewport);
  // Project the line to viewport, then find the nearest point
  const coordinates: Array<Array<number>> = line.geometry.coordinates as any;
  const projectedCoords = coordinates.map(([x, y, z = 0]) =>
    wmViewport.project([x, y, altitudeTransformer?.(z) ?? z]),
  );
  const [x, y] = wmViewport.project([
    inPoint.geometry.coordinates[0],
    inPoint.geometry.coordinates[1],
    altitudeTransformer?.(inPoint.geometry.coordinates[2]) ??
      inPoint.geometry.coordinates[2] ??
      0,
  ]);

  let minSquaredDistance = Infinity;
  let minIndex = 0;
  let minRatio = 0;

  for (let index = 1; index < projectedCoords.length; ++index) {
    const [x1, y1] = projectedCoords[index - 1];
    const [x2, y2] = projectedCoords[index];
    const dx = x2 - x1;
    const dy = y2 - y1;
    const squaredLength = dx ** 2 + dy ** 2;
    if (squaredLength < 1e-5) {
      continue;
    }
    const ratio = ((x - x1) * dx + (y - y1) * dy) / squaredLength;
    if (ratio < 0 || ratio > 1) {
      continue;
    }

    const x0 = x1 + ratio * dx;
    const y0 = y1 + ratio * dy;
    const squaredDistance = (x - x0) ** 2 + (y - y0) ** 2;
    if (squaredDistance < minSquaredDistance) {
      minSquaredDistance = squaredDistance;
      minIndex = index;
      minRatio = ratio;
    }
  }

  const p1 = coordinates[minIndex - 1];
  const p2 = coordinates[minIndex];
  const nearestPoint =
    minIndex >= 1
      ? [
          p1[0] + (p2[0] - p1[0]) * minRatio,
          p1[1] + (p2[1] - p1[1]) * minRatio,
          p1[2] + (p2[2] - p1[2]) * minRatio,
        ]
      : coordinates[0];

  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: nearestPoint,
    },
    properties: {
      // TODO: calculate the distance in proper units
      dist: Math.sqrt(minSquaredDistance),
      index: minIndex - 1,
    },
  };
}

export function nearestPointOnLine<G extends LineString | MultiLineString>(
  lines: FeatureOf<LineString>,
  inPoint: FeatureOf<Point>,
  viewport?: Viewport,
): NearestPointType {
  let mercator: WebMercatorViewport | undefined;

  if (viewport) {
    mercator = new WebMercatorViewport(viewport);
  }
  let closestPoint: NearestPointType = point([Infinity, Infinity], {
    dist: Infinity,
  }) as NearestPointType;

  if (
    !lines.geometry?.coordinates.length ||
    lines.geometry?.coordinates.length < 2
  ) {
    return closestPoint;
  }

  // @ts-expect-error
  flattenEach(lines, (line: any) => {
    const coords: any = getCoords(line);
    // @ts-expect-error
    const pointCoords: any = getCoords(inPoint);

    let minDist: number | undefined;
    let to: number | undefined;
    let from: number | undefined;
    let x: number | undefined;
    let y: number | undefined;
    let segmentIdx: number | undefined;
    let dist: number | undefined;

    if (coords.length > 1 && pointCoords.length) {
      let lineCoordinates;
      let pointCoordinate;

      // If viewport is given, then translate these coordinates to pixels to increase precision
      if (mercator) {
        lineCoordinates = coords.map((lineCoordinate: number[]) =>
          mercator!.project(lineCoordinate),
        );
        pointCoordinate = mercator.project(pointCoords);
      } else {
        lineCoordinates = coords;
        pointCoordinate = pointCoords;
      }

      for (let n = 1; n < lineCoordinates.length; n++) {
        if (lineCoordinates[n][0] !== lineCoordinates[n - 1][0]) {
          const slope =
            (lineCoordinates[n][1] - lineCoordinates[n - 1][1]) /
            (lineCoordinates[n][0] - lineCoordinates[n - 1][0]);
          const inverseSlope =
            lineCoordinates[n][1] - slope * lineCoordinates[n][0];

          dist =
            Math.abs(
              slope * pointCoordinate[0] + inverseSlope - pointCoordinate[1],
            ) / Math.sqrt(slope * slope + 1);
        } else {
          dist = Math.abs(pointCoordinate[0] - lineCoordinates[n][0]);
        }

        // length^2 of line segment
        const rl2 =
          Math.pow(lineCoordinates[n][1] - lineCoordinates[n - 1][1], 2) +
          Math.pow(lineCoordinates[n][0] - lineCoordinates[n - 1][0], 2);

        // distance^2 of pt to end line segment
        const ln2 =
          Math.pow(lineCoordinates[n][1] - pointCoordinate[1], 2) +
          Math.pow(lineCoordinates[n][0] - pointCoordinate[0], 2);

        // distance^2 of pt to begin line segment
        const lnm12 =
          Math.pow(lineCoordinates[n - 1][1] - pointCoordinate[1], 2) +
          Math.pow(lineCoordinates[n - 1][0] - pointCoordinate[0], 2);

        // minimum distance^2 of pt to infinite line
        const dist2 = Math.pow(dist, 2);

        // calculated length^2 of line segment
        const calcrl2 = ln2 - dist2 + lnm12 - dist2;

        // redefine minimum distance to line segment (not infinite line) if necessary
        if (calcrl2 > rl2) {
          dist = Math.sqrt(Math.min(ln2, lnm12));
        }

        if (minDist === undefined || minDist > dist) {
          // eslint-disable-next-line max-depth
          if (calcrl2 > rl2) {
            // eslint-disable-next-line max-depth
            if (lnm12 < ln2) {
              to = 0; // nearer to previous point
              from = 1;
            } else {
              from = 0; // nearer to current point
              to = 1;
            }
          } else {
            // perpendicular from point intersects line segment
            to = Math.sqrt(lnm12 - dist2) / Math.sqrt(rl2);
            from = Math.sqrt(ln2 - dist2) / Math.sqrt(rl2);
          }
          minDist = dist;
          segmentIdx = n;
        }
      }

      if (segmentIdx !== undefined && to !== undefined) {
        const dx =
          lineCoordinates[segmentIdx - 1][0] - lineCoordinates[segmentIdx][0];
        const dy =
          lineCoordinates[segmentIdx - 1][1] - lineCoordinates[segmentIdx][1];

        x = lineCoordinates[segmentIdx - 1][0] - dx * to;
        y = lineCoordinates[segmentIdx - 1][1] - dy * to;
      }
    }

    if (
      segmentIdx !== undefined &&
      x !== undefined &&
      y !== undefined &&
      from !== undefined &&
      to !== undefined
    ) {
      // index needs to be -1 because we have to account for the shift from initial backscan
      let snapPoint = { x, y, idx: segmentIdx - 1, to, from };

      if (mercator) {
        const pixelToLatLong = mercator.unproject([snapPoint.x, snapPoint.y]);
        snapPoint = {
          x: pixelToLatLong[0],
          y: pixelToLatLong[1],
          idx: segmentIdx - 1,
          to,
          from,
        };
      }

      closestPoint = point([snapPoint.x, snapPoint.y], {
        dist: Math.abs(snapPoint.from - snapPoint.to),
        index: snapPoint.idx,
      }) as NearestPointType;
    }
  });

  return closestPoint;
}

export function getHandlesForPick(pick?: Pick): EditHandleFeature[] {
  if (!pick?.object || pick.object.geometry.type === 'Point') {
    return [];
  }
  let handles = getEditHandlesForGeometry(pick.object.geometry, pick.index);
  if (pick.object.geometry.type === 'LineString') {
    handles.splice(0, 1);
    handles.splice(handles.length - 1, 1);
  }
  return handles;
}

export function getUnderlyingFeaturePick(
  pick: Pick | undefined,
  props: ModeProps,
): Pick | undefined {
  if (!pick?.isGuide) {
    return pick;
  }
  const index = pick.object?.properties?.featureIndex;
  if (index === undefined) {
    return undefined;
  }
  const { features } = props.data;
  return { ...pick, object: features[index], index, isGuide: false };
}

export function getPickedEditHandle(
  picks: Pick[] | null | undefined,
): EditHandleFeature | null | undefined {
  const handles = getPickedEditHandles(picks);
  return handles.length ? handles[0] : null;
}

export function getPickedExistingEditHandle(
  picks: Pick[] | null | undefined,
): EditHandleFeature | undefined {
  const handles = getPickedEditHandles(picks);
  return handles.find(
    ({ properties }) =>
      properties.featureIndex >= 0 && properties.editHandleType === 'existing',
  );
}

export function getPickedIntermediateEditHandle(
  picks: Pick[] | null | undefined,
): EditHandleFeature | undefined {
  const handles = getPickedEditHandles(picks);
  return handles.find(
    ({ properties }) =>
      properties.featureIndex >= 0 &&
      properties.editHandleType === 'intermediate',
  );
}

export function getPickedEditHandles(
  picks: Pick[] | null | undefined,
): EditHandleFeature[] {
  const handles =
    (picks ?? [])
      .filter(
        (pick) =>
          pick.isGuide && pick.object.properties.guideType === 'editHandle',
      )
      .map((pick) => pick.object) || [];

  return handles;
}

export function getEditHandlesForGeometry(
  geometry: Geometry,
  featureIndex: number,
  editHandleType: EditHandleType = 'existing',
): EditHandleFeature[] {
  switch (geometry.type) {
    case 'Point':
      // positions are not nested
      return [
        {
          type: 'Feature',
          properties: {
            guideType: 'editHandle',
            editHandleType,
            positionIndexes: [],
            featureIndex,
          },
          geometry: {
            type: 'Point',
            coordinates: geometry.coordinates,
          },
        },
      ];
    case 'MultiPoint':
    case 'LineString':
      // positions are nested 1 level
      return getEditHandlesForCoordinates(
        geometry.coordinates,
        [],
        featureIndex,
        editHandleType,
      );
    case 'Polygon':
    case 'MultiLineString': {
      let handles: EditHandleFeature[] = [];
      // positions are nested 2 levels
      for (let a = 0; a < geometry.coordinates.length; a++) {
        handles = handles.concat(
          getEditHandlesForCoordinates(
            geometry.coordinates[a],
            [a],
            featureIndex,
            editHandleType,
          ),
        );
        if (geometry.type === 'Polygon') {
          // Don't repeat the first/last handle for Polygons
          handles = handles.slice(0, -1);
        }
      }
      return handles;
    }
    case 'MultiPolygon': {
      let handles: EditHandleFeature[] = [];
      // positions are nested 3 levels
      for (let a = 0; a < geometry.coordinates.length; a++) {
        for (let b = 0; b < geometry.coordinates[a].length; b++) {
          handles = handles.concat(
            getEditHandlesForCoordinates(
              geometry.coordinates[a][b],
              [a, b],
              featureIndex,
              editHandleType,
            ),
          );
          // Don't repeat the first/last handle for Polygons
          handles = handles.slice(0, -1);
        }
      }
      return handles;
    }
    default:
      throw Error(`Unhandled geometry type: ${(geometry as any).type}`);
  }
}

function getEditHandlesForCoordinates(
  coordinates: any[],
  positionIndexPrefix: number[],
  featureIndex: number,
  editHandleType: EditHandleType = 'existing',
): EditHandleFeature[] {
  const editHandles: EditHandleFeature[] = [];
  for (let i = 0; i < coordinates.length; i++) {
    const position = coordinates[i];
    editHandles.push({
      type: 'Feature',
      properties: {
        guideType: 'editHandle',
        positionIndexes: [...positionIndexPrefix, i],
        featureIndex,
        editHandleType,
      },
      geometry: {
        type: 'Point',
        coordinates: position,
      },
    });
  }
  return editHandles;
}

export function isEdgeBlocked(mapElement: MapElementDto) {
  if (
    !mapElement.properties ||
    !('blockedAt' in mapElement.properties) ||
    !mapElement.properties.blockedAt
  ) {
    return false;
  }
  if (!('blockedUntil' in mapElement.properties)) {
    return true;
  }
  return new Date(mapElement.properties.blockedUntil!).getTime() > Date.now();
}

export function isBlockedUntilExpired(mapElement: MapElementDto) {
  if (
    !mapElement.properties ||
    !('blockedAt' in mapElement.properties) ||
    !mapElement.properties.blockedAt ||
    !('blockedUntil' in mapElement.properties)
  ) {
    return false;
  }
  return new Date(mapElement.properties.blockedUntil!).getTime() <= Date.now();
}
