import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../core/auth.service';
import {
  hasOrderManagementAccess,
  OrderManagementDialog,
} from '../../operations/order-management-dialog/order-management-dialog.component';
import { Order } from '../../core/order/order';

@Component({
  selector: 'app-order-details-dialog',
  templateUrl: 'order-details-dialog.component.html',
  styleUrls: ['./order-details-dialog.component.sass'],
})
export class OrderDetailsDialogComponent implements OnInit {
  prettyJson: string;
  private order: Order;
  canEditOrderStatus$!: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Order,
    private dialog: MatDialog,
    private authService: AuthService,
  ) {
    this.prettyJson = JSON.stringify(data, null, 2);
    this.order = data;
  }

  ngOnInit(): void {
    this.canEditOrderStatus$ = this.authService.user$.pipe(
      map(hasOrderManagementAccess),
    );
  }

  updateOrder() {
    this.dialog.open(OrderManagementDialog, { data: { order: this.order } });
  }
}
