import { Observable, combineLatest, map, shareReplay } from 'rxjs';
import { BackendStatePolling } from './backend-state-polling';
import { RtcEvents } from '../webrtc/rtc-events';
import { UserSessionService } from '../../user-session/user-session.service';

export class ParkLowPriorityRobot {
  showParkLowPriorityRobotMessage$: Observable<boolean>;
  constructor(
    backendStatePolling: BackendStatePolling,
    rtcEvents: RtcEvents,
    userSessionService: UserSessionService,
  ) {
    this.showParkLowPriorityRobotMessage$ = combineLatest([
      userSessionService.preassignedRobotId$,
      userSessionService.assignedRobots$,
      backendStatePolling.orders$,
      rtcEvents.routeCorridorConfirmationState$,
    ]).pipe(
      map(
        ([
          preassignedRobotId,
          assignedRobotIds,
          orders,
          routeCorridorConfirmationState,
        ]) => {
          const hasOrders = orders.length > 0;
          const nearCrossing =
            routeCorridorConfirmationState.distanceToConfirmation &&
            routeCorridorConfirmationState.distanceToConfirmation < 10 &&
            routeCorridorConfirmationState.distanceToConfirmation > 0;
          return (
            preassignedRobotId !== undefined &&
            !assignedRobotIds.includes(preassignedRobotId) &&
            !hasOrders &&
            !nearCrossing
          );
        },
      ),
      shareReplay(1),
    );
  }
}
