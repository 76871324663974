import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  EMPTY,
  Subject,
  BehaviorSubject,
  partition,
  combineLatest,
  of,
} from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  takeUntil,
  switchMap,
  mapTo,
} from 'rxjs/operators';
import { visiblePageTimer } from '../../utils/page-visibility';
import { BackendService } from '../core/backend.service';
import { ErrorService } from '../core/error-system/error.service';
import { RobotsService } from '../core/robots-service/robots.service';
import { OperatorTaskDto, RobotSupervisionTaskDto } from './operator-task.dto';
import { SupervisionTask } from './supervision-task';

const POLLING_INTERVAL = 3000;

@Component({
  selector: 'app-operator-tasks',
  templateUrl: './operator-tasks.component.html',
  styleUrls: ['./operator-tasks.component.sass'],
})
export class OperatorTasksComponent implements OnInit, OnDestroy {
  establishingConnection = false;

  private _supervisionTask$ = new BehaviorSubject<SupervisionTask | undefined>(
    undefined,
  );
  supervisionTask$ = this._supervisionTask$.asObservable();

  private destroy$: Subject<void> = new Subject();

  constructor(
    private readonly backendService: BackendService,
    private readonly robotsService: RobotsService,
    private readonly errorService: ErrorService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
  }

  ngOnInit(): void {
    const [active, inactive] = partition(
      this.supervisionTask$.pipe(takeUntil(this.destroy$)),
      (t) => t !== undefined,
    );

    inactive.subscribe(() => this.findOperatorTasks());

    active
      .pipe(
        switchMap(
          (supervisionTask) => supervisionTask!.robotCommunication.finalized$,
        ),
      )
      .subscribe(() => {
        this._supervisionTask$.next(undefined);
      });
  }

  async findOperatorTasks() {
    const taskActive = this.supervisionTask$.pipe(
      filter((supervisionTask) => supervisionTask !== undefined),
    );

    visiblePageTimer(0, POLLING_INTERVAL)
      .pipe(
        filter(() => !this.establishingConnection),
        takeUntil(this.destroy$),
        takeUntil(taskActive),
        exhaustMap(() =>
          this.backendService.get('/operator-tasks?max-count=1').pipe(
            catchError((error) => {
              if ('status' in error && error.status === 404) {
                return EMPTY;
              }
              return error;
            }),
            this.errorService.handleStreamErrors(
              'Getting new operator task is not possible',
            ),
          ),
        ),
      )
      .subscribe((tasks: OperatorTaskDto[]) => {
        if (tasks.length !== 1) {
          return;
        }
        this.establishConnections(
          tasks.map((task: OperatorTaskDto) => task.robotSupervisionTask),
        );
      });
  }

  private async establishConnections(tasks: RobotSupervisionTaskDto[]) {
    this.establishingConnection = true;
    const [robotCommunication] =
      await this.robotsService.getRobotCommunications(
        tasks.map((task) => task.robotId),
      );

    combineLatest([
      robotCommunication?.connected$ ?? of(false),
      robotCommunication?.isAlive$ ?? of(false),
    ])
      .pipe(
        filter(([isConnected, isAlive]: [boolean, boolean]) => {
          return isConnected || !isAlive;
        }),
        mapTo(robotCommunication),
      )
      .subscribe((robotCommunicationForTask) => {
        if (robotCommunicationForTask === undefined) {
          console.error('Robot communication for task is undefined');
          return;
        }
        const task = tasks.find(
          (task) => task.robotId === robotCommunicationForTask.robotId,
        );
        this._supervisionTask$.next(
          new SupervisionTask(robotCommunicationForTask, task!),
        );
        this.establishingConnection = false;
      });
  }

  async triggerRobotSupervision(id: string) {
    const [robotCommunication] =
      await this.robotsService.getRobotCommunications([id]);

    if (robotCommunication === undefined) {
      console.error(
        'Fail to start robot supervision, communication is undefined',
      );
      return;
    }
    this._supervisionTask$.next(
      new SupervisionTask(robotCommunication, { robotId: id }),
    );
  }
}
