<app-toolbar>
  <button mat-menu-item [routerLink]="'/robots'">Robots</button>
  <button mat-menu-item [routerLink]="'/operations'">Operations</button>
  <button mat-menu-item [routerLink]="'/users'">Users</button>
  <button mat-menu-item [routerLink]="'/map-editor'">Map Editor</button>
  <button mat-menu-item [routerLink]="'/orders'">Orders</button>
  <button mat-menu-item [routerLink]="'/mapping'">Mapping</button>
  <button mat-menu-item [routerLink]="'/supervise-robots'">
    Supervise Robots
  </button>

  <button mat-menu-item [routerLink]="'/operation-management'">
    Ops Management
  </button>

  <button mat-menu-item [routerLink]="'/robot-operators-overview'">
    Operators Overview
  </button>

  <button mat-menu-item [routerLink]="'/ml-platform'">ML Platform</button>
</app-toolbar>

<button mat-raised-button (click)="switchSite()" id="site-switch-button">
  Switch to {{ switchSiteName }} <mat-icon>sync_alt</mat-icon>
</button>

<div class="flex-container">
  <button mat-stroked-button [routerLink]="'/robots'">Robots</button>
  <button mat-stroked-button [routerLink]="'/operations'">Operations</button>
  <button mat-stroked-button [routerLink]="'/users'">Users</button>
  <button mat-stroked-button [routerLink]="'/map-editor'">Map Editor</button>
  <button mat-stroked-button [routerLink]="'/orders'">Orders</button>
  <button mat-stroked-button [routerLink]="'/mapping'">Mapping</button>
  <button mat-stroked-button [routerLink]="'/supervise-robots'">
    Supervise Robots
  </button>
  <button mat-stroked-button [routerLink]="'/operation-management'">
    Ops Management
  </button>
  <button mat-stroked-button [routerLink]="'/robot-operators-overview'">
    Operators Overview
  </button>
  <button mat-stroked-button [routerLink]="'/ml-platform'">ML Platform</button>
  <button mat-stroked-button [routerLink]="'/crossing-monitoring'">
    Crossings
  </button>
</div>
