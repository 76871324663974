import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type UnsupervisedAutonomyAbortReportDialogData = {
  report: string;
  stamp: Date;
  robotSerialNumber: string;
};
@Component({
  selector: 'app-unsupervised-autonomy-abort-report-dialog',
  templateUrl: './unsupervised-autonomy-abort-report-dialog.component.html',
  styleUrls: ['./unsupervised-autonomy-abort-report-dialog.component.sass'],
})
export class UnsupervisedAutonomyAbortReportDialog {
  inputData: UnsupervisedAutonomyAbortReportDialogData;
  constructor(
    @Inject(MAT_DIALOG_DATA) data: UnsupervisedAutonomyAbortReportDialogData,
    public dialogRef: MatDialogRef<void, void>,
  ) {
    this.inputData = data;
  }

  getElasticLink() {
    return `https://robot-logs.kb.europe-west3.gcp.cloud.es.io:9243/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15m,to:now))&_a=(columns:!(),filters:!(),index:'23c15690-cfca-11ed-8cb8-0dc8a99b65a1',interval:auto,query:(language:kuery,query:'robotSerialNumber:${this.inputData.robotSerialNumber}'),sort:!(!('@timestamp',desc)))`;
  }
}
