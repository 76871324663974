import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { retry } from 'rxjs/operators';
import { BackendService } from '../../backend.service';
import { ErrorService } from '../../error-system/error.service';
import { HazardLightsState, PowerMode } from './robot.dto';

export class BackendActions {
  private readonly _sirenOn$ = new BehaviorSubject<boolean>(false);
  readonly sirenOn$ = this._sirenOn$.asObservable();

  constructor(
    readonly robotId: string,
    private readonly backendService: BackendService,
    private readonly errorService: ErrorService,
  ) {}

  async arrivedAtStop() {
    await this.backendService
      .post(`/robots/${this.robotId}/arrived-at-stop`, {})
      .pipe(
        this.errorService.handleStreamErrors(
          'Arrived to a stop command failed',
        ),
      )
      .toPromise();
  }

  async abortInfrastructureTransaction() {
    await this.backendService
      .post(`/robots/${this.robotId}/abort-infrastructure-transaction`, {})
      .pipe(
        this.errorService.handleStreamErrors(
          'Aborting infrastructure transaction failed',
        ),
      )
      .toPromise();
  }

  async sendLightingCommand(desiredHazardLightsState: HazardLightsState) {
    await this.backendService
      .post(`/robots/${this.robotId}/set-hazard-lights-state`, {
        hazardLightsState: desiredHazardLightsState,
      })
      .pipe(
        retry(5),
        this.errorService.handleStreamErrors(
          'Hazard light state update failed',
        ),
      )
      .toPromise();
  }

  async enableSirenAndAlarm(value: boolean) {
    this._sirenOn$.next(value);
    await this.sendLightingCommand(
      value ? HazardLightsState.ALARM : HazardLightsState.AUTO,
    );
  }

  async setRobotReadyForOrdersState(readyForOrders: boolean) {
    await this.backendService
      .post(`/robots/${this.robotId}/set-ready-for-orders`, {
        readyForOrders,
      })
      .pipe(
        retry(5),
        this.errorService.handleStreamErrors(
          'Failed to update robot ready for order status!',
        ),
      )
      .toPromise();
  }

  async powerSaving(isPowerSaving: boolean): Promise<void> {
    const powerMode = isPowerSaving ? PowerMode.SAVING : PowerMode.DEFAULT;
    await this.backendService
      .post(`/robots/${this.robotId}/power-mode`, { powerMode })
      .pipe(
        retry(5),
        this.errorService.handleStreamErrors('Failed to update power mode'),
      )
      .toPromise();
  }

  async automaticPowerSaving(automaticPowerSaving: boolean): Promise<void> {
    await this.backendService
      .post(`/robots/${this.robotId}/automatic-power-saving`, {
        automaticPowerSaving,
      })
      .pipe(
        this.errorService.handleStreamErrors(
          'Failed to enable automatic power saving mode',
        ),
      )
      .toPromise();
  }

  async setEndstopStateCommand(command: string): Promise<void> {
    await lastValueFrom(
      this.backendService
        .post(`/robots/${this.robotId}/set-endstop-state-command`, {
          command,
        })
        .pipe(
          this.errorService.handleStreamErrors(
            'Failed to set endstop state command',
          ),
        ),
    );
  }
}
