import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyTime',
})
export class PrettyTimePipe implements PipeTransform {
  transform(value?: Date | string | number): string {
    if (value === undefined) {
      return 'UNKNOWN';
    }
    const date = new Date(value);
    const now = new Date();
    const minutesAgo = Math.round((now.getTime() - date.getTime()) / 1000 / 60);

    if (
      date.getDate() !== now.getDate() ||
      date.getMonth() !== now.getMonth() ||
      date.getFullYear() !== now.getFullYear()
    ) {
      // different day ==> show date & time
      return `at ${date.toLocaleTimeString()} (${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()})`;
    } else if (Math.abs(minutesAgo) >= 60) {
      // same day ==> show time only
      return `at ${date.toLocaleTimeString()}`;
    } else if (minutesAgo < -1) {
      return `in ${-minutesAgo} minutes`;
    } else if (minutesAgo === -1) {
      return `in 1 minute`;
    } else if (minutesAgo === 0) {
      return 'just now';
    } else if (minutesAgo === 1) {
      return `1 minute ago`;
    } else {
      return `${minutesAgo} minutes ago`;
    }
  }
}
