import { IsExpired } from './is-expired';
import { ManualRobotControl } from '../../../../core/robots-service/webrtc/types';

function linearInterpolation(a: number, b: number, fraction: number) {
  return a + (b - a) * fraction;
}

export type NormalizedManualRobotControl = {
  normalizedSpeed: number;
  normalizedTurnRate: number;
};

export type DesiredManualRobotControl = {
  speed: number;
  turnRate: number;
};

export class ControlState {
  private normalizedTurnRate = 0;
  private speed = 0;

  private desiredStateExpirationWatcher = new IsExpired(
    this.thresholdForIdealMs,
  );

  constructor(
    private readonly maxForwardSpeed: number,
    private readonly maxReverseSpeed: number,
    private readonly slowSpeedMaxTurnRate: number,
    private readonly highSpeedMaxTurnRate: number,
    private readonly reverseTurnRate: number,
    private readonly thresholdForIdealMs: number,
  ) {}

  setDesiredNormalizedState({
    normalizedSpeed,
    normalizedTurnRate,
  }: NormalizedManualRobotControl) {
    const maxSpeed =
      normalizedSpeed > 0 ? this.maxForwardSpeed : this.maxReverseSpeed;

    this.speed = maxSpeed * normalizedSpeed;
    this.normalizedTurnRate = normalizedTurnRate;

    this.desiredStateExpirationWatcher.reset();
  }

  isIdle() {
    return this.isStanding() && this.desiredStateExpirationWatcher.isExpired();
  }

  isStanding() {
    return this.speed === 0 && this.normalizedTurnRate === 0;
  }

  private isReverse() {
    return this.speed < 0;
  }

  private calcTurnRateSpeedScaling() {
    if (this.isReverse()) {
      return this.reverseTurnRate;
    }
    const speedFraction = Math.abs(this.speed / this.maxForwardSpeed);
    return linearInterpolation(
      this.slowSpeedMaxTurnRate,
      this.highSpeedMaxTurnRate,
      speedFraction,
    );
  }

  get(): ManualRobotControl {
    const speedBasedTurnRateScale = this.calcTurnRateSpeedScaling();
    return {
      speed: this.speed,
      turnRate: this.normalizedTurnRate * speedBasedTurnRateScale,
    };
  }
}
