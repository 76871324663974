import {
  IsString,
  IsDefined,
  IsEnum,
  IsOptional,
  IsInt,
} from 'class-validator';

export enum InfrastructureType {
  MITSUBISHI_ELEVATOR_GROUP = 'mitsubishi-elevator-group',
  MITSUBISHI_ELEVATOR = 'mitsubishi-elevator',
  MOCK_ELEVATOR_GROUP = 'mock-elevator-group',
  MOCK_ELEVATOR = 'mock-elevator',
}

export class InfrastructurePropertiesDto {
  @IsDefined()
  @IsString()
  externalInfrastructureId!: string;

  @IsDefined()
  @IsEnum(InfrastructureType)
  infrastructureType!: InfrastructureType;

  @IsOptional()
  @IsInt()
  parentInfrastructureId?: number;
}
