<div class="outer-container">
  <div class="inner-container">
    <div class="component-header-title">Collections</div>
    <div class="left-side-bar-collections">
      <div
        *ngFor="let collection of collections"
        (click)="setCollection(collection)"
        class="collection-item"
        [class.selected-collection]="selectedCollection?.id === collection.id"
      >
        {{ collection.name }}
      </div>
      <app-collection-name-input
        *ngIf="addCollectionModeOn"
        style="display: contents"
        (closeInput)="addCollectionModeOn = false"
        (createCollection)="createCollection($event)" 
      ></app-collection-name-input>
      <button
        *ngIf="!addCollectionModeOn"
        mat-icon-button
        (click)="addCollectionModeOn = true"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>
