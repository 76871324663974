export function intersectArrays<T>(
  array1: T[],
  array2: T[],
  equal: (value1: T) => (value2: T) => boolean,
) {
  const array1UniqueItems = [];
  const array2UniqueItems = [];

  const commonItems = [];

  for (const item of array1) {
    if (array2.some(equal(item))) {
      commonItems.push(item);
    } else {
      array1UniqueItems.push(item);
    }
  }

  for (const item of array2) {
    if (!array1.some(equal(item))) {
      array2UniqueItems.push(item);
    }
  }

  return [array1UniqueItems, commonItems, array2UniqueItems];
}
