import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { environment } from '../../environments/environment';

import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ErrorService } from './error-system/error.service';

function passThrough(error: HttpErrorResponse): Observable<never> {
  return throwError(() => error);
}

export abstract class BaseBackendService {
  private readonly backendUrl = `${this.baseBackendUrl}/v1`;
  private handleErrorOp = catchError((err) => this.handleError(err));

  constructor(
    private baseBackendUrl: string,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private errorService: ErrorService,
    private router: Router,
  ) {}

  get<T = any>(
    path: string,
    params?: HttpParams,
    errorHandler: (error: HttpErrorResponse) => Observable<T> = passThrough,
  ): Observable<T> {
    const userHandleErrorOp = catchError((err) => errorHandler(err));
    return this.http
      .get(
        this.backendUrl + path,
        params ? this.httpOptionsWithParams(params) : this.httpOptions(),
      )
      .pipe(userHandleErrorOp, this.handleErrorOp) as Observable<T>;
  }

  getWithHeader<T = any>(
    path: string,
    params?: HttpParams,
    errorHandler: (error: HttpErrorResponse) => Observable<T> = passThrough,
  ): Observable<HttpResponse<T>> {
    const getParams = params
      ? this.httpOptionsWithParams(params)
      : this.httpOptions();
    const userHandleErrorOp = catchError((err) => errorHandler(err));
    Object.assign(getParams, { observe: 'response' });
    return this.http
      .get(this.backendUrl + path, getParams)
      .pipe(userHandleErrorOp, this.handleErrorOp) as Observable<
      HttpResponse<T>
    >;
  }

  post<T = any>(
    path: string,
    body: any,
    errorHandler: (error: HttpErrorResponse) => Observable<T> = passThrough,
  ): Observable<T> {
    const userHandleErrorOp = catchError((err) => errorHandler(err));
    return this.http
      .post(this.backendUrl + path, body, this.httpOptions())
      .pipe(userHandleErrorOp, this.handleErrorOp) as Observable<T>;
  }

  patch<T = any>(
    path: string,
    body: any,
    errorHandler: (error: HttpErrorResponse) => Observable<T> = passThrough,
  ): Observable<T> {
    const userHandleErrorOp = catchError((err) => errorHandler(err));
    return this.http
      .patch(this.backendUrl + path, body, this.httpOptions())
      .pipe(userHandleErrorOp, this.handleErrorOp) as Observable<T>;
  }

  put<T = any>(
    path: string,
    body: any,
    errorHandler: (error: HttpErrorResponse) => Observable<T> = passThrough,
  ): Observable<T> {
    const userHandleErrorOp = catchError((err) => errorHandler(err));
    return this.http
      .put(this.backendUrl + path, body, this.httpOptions())
      .pipe()
      .pipe(userHandleErrorOp, this.handleErrorOp) as Observable<T>;
  }

  delete<T = any>(
    path: string,
    errorHandler: (error: HttpErrorResponse) => Observable<never> = passThrough,
  ): Observable<T> {
    const userHandleErrorOp = catchError((err) => errorHandler(err));
    return this.http
      .delete(this.backendUrl + path, this.httpOptions())
      .pipe(userHandleErrorOp, this.handleErrorOp) as Observable<T>;
  }

  private httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
  }
  private httpOptionsWithParams(params: HttpParams) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params,
    };
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    switch (error.status) {
      // BadRequestException
      case 400:
        this.errorService.reportError(`Request failed: ${error.message}`);
        return EMPTY;
      // UnauthorizedException
      case 401:
        {
          const authSnackBar = this.snackBar.open(
            'Authentication expired',
            'Login',
          );
          authSnackBar.onAction().subscribe(() =>
            this.router.navigate(['/login'], {
              queryParams: { returnUrl: this.router.url },
            }),
          );
        }
        return EMPTY;
      // ForbiddenException
      case 403:
        this.errorService.reportError(
          'Insufficient permissions, request access to this functionality by contacting Cartken',
        );
        return EMPTY;
      // NotImplementedException
      case 501:
        this.errorService.reportError(
          'Expected functionality is not available yet; please try again later',
        );
        return EMPTY;
      // TimeoutException
      case 504:
        this.errorService.reportError(
          'Request timed out; please try again later',
        );
        return EMPTY;
      default:
        if (error.error instanceof ErrorEvent || error.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
          this.errorService.reportError(
            'Unknown problem occurred; please try again later',
          );
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(`Backend returned error`, error);
        }
        // return an observable with a user-facing error message
        return throwError(error);
    }
  }
}
