import {
  CompositeLayer,
  CompositeLayerProps,
  DefaultProps,
  Layer,
  LayersList,
  UpdateParameters,
} from '@deck.gl/core/typed';
import { LocalizationMapTilesPropertiesDto } from '@cartken/map-types';
import {
  fetchTileLayers,
  getColorTexture,
  getHeightMap,
  getSemanticTexture,
  getSteepnessTexture,
  HeightMap,
  LocalizationMapLayers,
  Texture,
  TileLayerName,
} from './utils';
import { LocalizationMapTileLevelLayer } from './localization-map-tile-level-layer';
import { getLatLngAltFromEnu } from './tileset-utils';
import {
  PngGridLevel,
  PngGridMapTile,
} from '../../../../generated/slam_msgs/localization_map_tile_pb';
import { LayerName } from '../visualization-styles';

type NamedTexture = Texture & { layerName: LayerName };
type LayerOpacity = { layerName: LayerName; opacity: number };

export type TileLevelData = {
  heightMap: HeightMap;
  textureLayers: NamedTexture[];
  cellSize: number;
};

const defaultProps: DefaultProps<LocalizationMapTileLayerProps> = {
  tileIndex: { type: 'array', value: [0, 0], compare: true },
  data: {
    type: 'object',
    value: undefined,
    compare: -1,
  },
  layerOpacities: { type: 'array', value: undefined, compare: -1 },
  minDisplayAltitude: { type: 'number', value: 0.0 },
  maxDisplayAltitude: { type: 'number', value: Infinity },
  altitudeScale: { type: 'number', value: 1.0 },
  altitudeOffset: { type: 'number', value: 0.0 },
};

export type LocalizationMapTileLayerProps = _LocalizationMapTileLayerProps &
  CompositeLayerProps;

type _LocalizationMapTileLayerProps = {
  tileIndex: [number, number];
  data: LocalizationMapTilesPropertiesDto | undefined;
  layerOpacities: LayerOpacity[] | undefined;
  minDisplayAltitude?: number;
  maxDisplayAltitude?: number;
  altitudeScale?: number;
  altitudeOffset?: number;
};

export type LatLngHeight = [number, number, number];

function getLevel(
  tile: PngGridMapTile | undefined,
  levelIndex: number,
): PngGridLevel | undefined {
  return tile
    ?.getLevelsList()
    .find((level) => level.getLevelIndex() === levelIndex);
}

export class LocalizationMapTileLayer<
  ExtraPropsT extends object = {},
> extends CompositeLayer<
  ExtraPropsT & Required<_LocalizationMapTileLayerProps>
> {
  static override defaultProps = defaultProps;
  static override layerName = 'LocalizationMapTileLayer';
  override state!: {
    tileLevels?: TileLevelData[];
    tileOrigin?: number[];
  };

  override updateState(params: UpdateParameters<this>): void {
    const shouldReload =
      params.props.tileIndex[0] !== params.oldProps.tileIndex?.[0] ||
      params.props.tileIndex[1] !== params.oldProps.tileIndex?.[1] ||
      params.changeFlags.dataChanged;

    if (shouldReload) {
      this.loadTile(); // is not awaited, cannot throw
    }

    const shouldUpdateVisualization =
      params.props?.maxDisplayAltitude !== params.oldProps.maxDisplayAltitude ||
      params.props?.altitudeScale !== params.oldProps.altitudeScale ||
      params.props?.minDisplayAltitude !== params.oldProps.minDisplayAltitude ||
      params.props?.altitudeOffset !== params.oldProps.altitudeOffset ||
      params.props?.layerOpacities !== params.oldProps.layerOpacities;

    if (shouldUpdateVisualization) {
      this.setNeedsUpdate();
    }
  }

  private async loadTile() {
    if (!this.props.data) {
      return;
    }
    try {
      const startTime = Date.now();
      const tileLayers = await fetchTileLayers(
        this.props.data.tilesBaseUrl,
        this.props.tileIndex,
        this.props.data.layerNames,
      );
      const tileLevels = await this.populateTileLevels(tileLayers);
      const enuOrigin = [
        this.props.data.tilesOriginLongitude,
        this.props.data.tilesOriginLatitude,
        this.props.data.tilesOriginAltitude ?? 0,
      ];
      const enuOffset = [
        this.props.tileIndex[0] * this.props.data.tilesSize,
        this.props.tileIndex[1] * this.props.data.tilesSize,
        0,
      ];
      const tileOrigin = getLatLngAltFromEnu(enuOrigin, enuOffset);
      const totalDuration = Date.now() - startTime;
      console.log('Loaded tile', ...this.props.tileIndex, 'in', totalDuration);

      this.setState({ tileLevels, tileOrigin });
    } catch (e) {
      console.error('Error loading localization map tile data', e);
    }
  }

  private async populateTileLevels(
    tileLayers: LocalizationMapLayers,
  ): Promise<TileLevelData[]> {
    const tileLevels: TileLevelData[] = [];

    const heightTile = tileLayers.get(TileLayerName.HEIGHT)?.getHeightMapTile();
    const steepnessTile = tileLayers
      .get(TileLayerName.STEEPNESS)
      ?.getSteepnessMapTile();
    const semanticTile = tileLayers
      .get(TileLayerName.SEMANTIC)
      ?.getSemanticMapTile();
    const colorTile = tileLayers.get(TileLayerName.COLOR)?.getColorMapTile();
    const diffTile = tileLayers.get(TileLayerName.DIFF)?.getDiffMapTile();
    if (!heightTile || !steepnessTile || !semanticTile) {
      console.warn('Could not load all tile layers');
      return [];
    }
    const cellSize = heightTile.getGridCellSize() ?? 0;
    for (const heightLevel of heightTile.getLevelsList()) {
      const levelIndex = heightLevel.getLevelIndex() ?? 0;
      const steepnessLevel = getLevel(steepnessTile, levelIndex);
      const semanticLevel = getLevel(semanticTile, levelIndex);
      const colorLevel = getLevel(colorTile, levelIndex);
      const diffLevel = getLevel(diffTile, levelIndex);

      const textureLayers: NamedTexture[] = [
        {
          ...getColorTexture(colorLevel),
          layerName: LayerName.COLOR,
        },
        {
          ...getSteepnessTexture(steepnessLevel),
          layerName: LayerName.STEEPNESS,
        },
        {
          ...getSemanticTexture(semanticLevel),
          layerName: LayerName.SEMANTIC,
        },
        {
          ...getColorTexture(diffLevel),
          layerName: LayerName.DIFF,
        },
      ];

      tileLevels.push({
        heightMap: getHeightMap(heightLevel),
        textureLayers,
        cellSize,
      });
    }

    return tileLevels;
  }

  override renderLayers(): Layer | LayersList | null {
    if (!this.state.tileLevels || !this.state.tileOrigin) {
      return null;
    }

    return this.state.tileLevels.map((heightTileData, i) => {
      const textureOpacities = heightTileData.textureLayers.map(
        (texture) =>
          this.props.layerOpacities?.find(
            (entry) => entry.layerName === texture.layerName,
          )?.opacity ?? 0,
      );
      return new LocalizationMapTileLevelLayer(
        this.getSubLayerProps({
          id: i.toString(),
        }),
        {
          data: heightTileData.heightMap,
          textures: heightTileData.textureLayers,
          textureOpacities,
          tileOrigin: this.state.tileOrigin,
          cellSize: heightTileData.cellSize,
          minDisplayAltitude: this.props.minDisplayAltitude,
          maxDisplayAltitude: this.props.maxDisplayAltitude,
          altitudeScale: this.props.altitudeScale,
          altitudeOffset: this.props.altitudeOffset,
        },
      );
    });
  }
}
