<app-toolbar>
  <button mat-menu-item [routerLink]="''">Home</button>
</app-toolbar>

<div class="robot-filters">
  <mat-form-field appearance="fill" class="operation-id-selection-form">
    <mat-label>Operations</mat-label>
    <mat-select
      multiple
      [(value)]="selectedOperationIds"
      (selectionChange)="refetch()"
    >
      <mat-option
        *ngFor="let operationId of operationIds | async"
        [value]="operationId"
        >{{ operationId }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Standing duration in seconds</mat-label>
    <input
      matInput
      type="number"
      placeholder="Seconds"
      [(ngModel)]="standingDuration"
      (change)="refetch()"
    />
  </mat-form-field>
</div>

<div class="page-center">
  <div
    *ngFor="
      let robotCommunication of robotsCommunicationsOnCrossing;
      trackByRobotId
    "
    class="robot-communication-item"
  >
    <robot-control
      [robotCommunication]="robotCommunication"
      [activeControl]="false"
      [highResolution]="true"
    ></robot-control>

    <div
      *ngIf="getStandStillDuration(robotCommunication) | async as standStill"
      class="robot-stands-still-duration"
    >
      Stands since {{ standStill | number : '1.0-0' }} sec
    </div>
  </div>
</div>
