import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { locationToCord } from '../../../utils/geo-tools';
import { hasRobotArrived } from '../../../utils/robots';
import { RobotLastStateService } from '../robots-service/robot-last-state.service';

import * as Sentry from '@sentry/angular-ivy';
import {
  UserSessionEvent,
  UserSessionEventTrackingService,
} from './user-session-event-tracking.service';
import { millisBetween } from '../../../utils/millis-between';
import { UserSessionInteractionEventName } from './user-session-interaction-events';

export type BaseEvent = {
  eventName: string;
};

export type RobotEvent = {
  location?: [number, number];
  robotId?: string;
  hasOrder?: boolean;
  isArrived?: boolean;
};

export type UserEvent = UserSessionEvent['payload'] & BaseEvent & RobotEvent;

const USER_EVENT_DEBOUNCE_DURATION = 5 * 1000;
const SYSTEM_EVENT_DEBOUNCE_DURATION = 0.5 * 1000;

export interface RobotSessionId {
  robotId: string;
  connectedSince: Date;
}

function createEventToken(eventName: string, robotId?: string): string {
  if (robotId === undefined) {
    return `${eventName}`;
  }
  return `${robotId}-${eventName}`;
}

const UserSessionInteractionEventList = Object.values(
  UserSessionInteractionEventName,
) as string[];

@Injectable({
  providedIn: 'root',
})
export class UserEventService {
  private _userEvents$ = new Subject<UserEvent>();
  userEvents$ = this._userEvents$.asObservable();

  private eventLastOccurrence = new Map<string, Date>();

  constructor(
    private readonly robotStateService: RobotLastStateService,
    private readonly userTrackingService: UserSessionEventTrackingService,
  ) {
    this._userEvents$.subscribe((event) => {
      Sentry.addBreadcrumb({
        category: 'ui.user-event',
        message: event.eventName,
        level: 'info',
        data: event,
      });
    });

    this.userTrackingService.events$.subscribe((event) => {
      const robotId =
        event?.payload && 'robotId' in event?.payload && event.payload.robotId
          ? event.payload.robotId
          : undefined;
      const robotEvent = robotId ? this.getRobotBaseEvent(robotId) : {};
      const eventToken = createEventToken(event.type, robotId);
      const now = new Date();

      if (this.shallDebounce(event.type, eventToken, now)) {
        return;
      }

      this.eventLastOccurrence.set(eventToken, now);
      this._userEvents$.next({
        eventName: event.type,
        ...event.payload,
        ...robotEvent,
      });
    });
  }

  private shallDebounce(
    eventName: string,
    eventToken: string,
    now: Date,
  ): boolean {
    const lastOccurrence = this.eventLastOccurrence.get(eventToken);
    const eventDebounceDuration = UserSessionInteractionEventList.includes(
      eventName,
    )
      ? USER_EVENT_DEBOUNCE_DURATION
      : SYSTEM_EVENT_DEBOUNCE_DURATION;
    return (
      lastOccurrence !== undefined &&
      millisBetween(lastOccurrence, now) < eventDebounceDuration
    );
  }

  private getRobotBaseEvent(robotId: string): RobotEvent {
    const robotInfo = this.robotStateService.getRobotInformation(robotId);
    if (robotInfo === undefined) {
      return {
        robotId,
      };
    }
    const hasOrder = robotInfo.orders.length > 0;
    const isArrived = hasRobotArrived(robotInfo.robotState);
    return {
      location: locationToCord(robotInfo.robotState.location),
      robotId,
      hasOrder,
      isArrived,
    };
  }
}
