import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { environment } from '../../environments/environment';

export interface SiteSwitch {
  name: string;
  url: string;
}

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.sass'],
})
export class LandingPageComponent {
  switchSiteName = environment.siteSwitch.name;

  constructor(public auth: AuthService, private router: Router) {
    this.auth.user$.subscribe((user) => {
      if (user && user.landingPage) {
        this.router.navigate([user.landingPage]);
        return;
      }
    });
  }

  switchSite() {
    window.location.href = environment.siteSwitch.url;
  }
}
