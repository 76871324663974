export const RobotIssuesTypeOptions = [
  'short_text',
  'number',
  'drop_down',
] as const;

export type RobotIssuesTypes = (typeof RobotIssuesTypeOptions)[number];

export interface RobotIssueFieldOption {
  id: string;
  name: string;
  orderIndex: number;
  color?: string;
}

export interface RobotIssueFieldUsingOptions {
  id: string;
  options?: RobotIssueFieldOption[];
}

export interface RobotIssueField<T> extends RobotIssueFieldUsingOptions {
  id: string;
  name: string;
  type: RobotIssuesTypes;
  orderIndex?: number;
  color?: string;
  required?: boolean;
  value?: T;
}

export interface RobotIssueFieldsWithOptions {
  issueType?: RobotIssueFieldUsingOptions;
  operationId?: RobotIssueFieldUsingOptions;
  robotSerialNumber?: RobotIssueFieldUsingOptions;
  severity?: RobotIssueFieldUsingOptions;
}

export interface RobotIssueFields extends RobotIssueFieldsWithOptions {
  issueType?: RobotIssueField<string>;
  operationId?: RobotIssueField<string>;
  robotSerialNumber?: RobotIssueField<number>;
  severity?: RobotIssueField<string>;
}

export interface ExtendedRobotIssueFields extends RobotIssueFields {
  // Transforming all Custom Fields, not just the ones we explicitly defined
  [index: string]: RobotIssueField<unknown> | undefined;
}

export enum RobotIssueSeverity {
  INFORMATIONAL = 'Informational',
  DEGRADED = 'Degraded',
  BREAKING = 'Breaking',
}

export interface RobotIssue {
  id: string;
  customId: string;
  name: string;
  severity?: RobotIssueSeverity;
}

export interface RobotIssueTemplate {
  name?: string;
  description?: string;
  fields: ExtendedRobotIssueFields;
}

export interface RobotIssueFieldValue {
  id: string;
  value: string | number;
}

export interface CreateRobotIssue {
  name: string;
  description?: string;
  customFields: RobotIssueFieldValue[];
}

export interface CreateRobotIssueError {
  code: number;
  message: string;
}
