<mat-card>
  <mat-card-header>
    <h2 mat-card-title>Unsupervised autonomy aborted!</h2>
  </mat-card-header>
  <mat-card-content>
    <h3>Reported {{ inputData.stamp | prettyTime }}</h3>

    <h3>What was reported:</h3>
    <p>
      {{ inputData.report }}
    </p>

    <p>
      <a
        mat-raised-button
        color="primary"
        aria-label="Go to robot log"
        matTooltip="Robot Log"
        matTooltipPosition="above"
        [href]="getElasticLink()"
      >
        Open Robot logs
      </a>
    </p>

    <p>
      Please assess the robot's environment for any potential issues and address
      them accordingly. Once resolved, please confirm to proceed with
      unsupervised autonomy.
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button mat-dialog-close="false" cdkFocusInitial>
      Further Investigation Required
    </button>
    <button mat-button mat-dialog-close="true" cdkFocusInitial>
      Mark handled
    </button>
  </mat-card-actions>
</mat-card>
