import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-battery-status',
  template: `
    <div class="container">
      <mat-icon>{{ batteryIcon }}</mat-icon>
      <span>{{ batteryPercentage | number : '1.0-0' }} %</span>
    </div>
  `,
  styles: [
    `
          .container
            display: flex
            flex-direction: row
            justify-content: flex-start
            align-items: center
        `,
  ],
})
export class BatteryStatusComponent implements OnChanges {
  @Input() batteryPercentage: number | undefined;
  @Input() charging = false;
  batteryIcon = 'battery_full';

  ngOnChanges() {
    if (this.batteryPercentage === undefined) {
      this.batteryPercentage = 0;
    }
    if (this.charging) {
      this.batteryIcon = 'battery_charging_full';
    } else if (this.batteryPercentage > 90) {
      this.batteryIcon = 'battery_full';
    } else {
      // 6 bars for batteryPercentage >= 80
      // 1 bar for batteryPercentage >= 10
      // 80 * X + Y >= 6;
      // 10 * X + Y >= 1;
      //
      // Subtracting the two equations:
      // 70* X >= 5
      // X >= 5/70
      //
      // Solving for Y
      // Y >= 1 - 10 * X = 1 - 5/7 = 2/7
      const bars = Math.floor((this.batteryPercentage * 5) / 70 + 2 / 7);
      this.batteryIcon = `battery_${bars}_bar`;
    }
  }
}
