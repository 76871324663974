import {
  Component,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Validators, FormControl } from '@angular/forms';
import { RobotQueueEdgeHandover } from '../operation';
import { CreateOrderDto } from '../../core/order/order';
import { LatLng } from 'spherical-geometry-js';

function parseLatLonString(latLonString: string): LatLng | undefined {
  const latLon = latLonString.split(/, ?/);
  if (latLon.length !== 2) {
    return undefined;
  }
  // override check of undefined, length is checked
  const lat = parseFloat(latLon[0]!);
  const lon = parseFloat(latLon[1]!);
  if (isNaN(lat) || isNaN(lon)) {
    return undefined;
  }
  return new LatLng(lat, lon);
}

export interface RobotIdentifier {
  id?: string;
  robotId?: string;
  shortName?: string;
  name?: string;
}

export interface CreateOrderDialogInput {
  useExternalId?: boolean;
  usePhoneNumber?: boolean;
  preferredCountryCodes: string[];
  pickups: RobotQueueEdgeHandover[];
  dropoffs: RobotQueueEdgeHandover[];
  phone?: string;
  allowCustomPickupLocationsWithinBounds: boolean;
  allowCustomDropoffLocationsWithinBounds: boolean;
  robots?: RobotIdentifier[];
  pickupImmediatelyEnabled?: boolean;
}
enum NotificationMethod {
  TEXT_MESSAGES = 'TextMessages',
  PHONE_CALLS = 'PhoneCalls',
}

@Component({
  selector: 'app-create-order-dialog',
  templateUrl: './create-order-dialog.component.html',
  styleUrls: ['./create-order-dialog.component.sass'],
})
export class CreateOrderDialogComponent implements AfterViewInit, OnInit {
  @ViewChild('pickupLocation') pickupLocationElement!: ElementRef;
  @ViewChild('dropoffLocation') dropoffLocationElement!: ElementRef;

  order: CreateOrderDto = {
    operationId: '',
    pickupHandover: {},
    dropoffHandover: {},
  };
  phoneFormControl: FormControl;
  phoneNotificationMethod = NotificationMethod.TEXT_MESSAGES;
  readonly phoneNotificationMethods = Object.values(NotificationMethod);
  emailFormControl: FormControl;
  pickupAddress = '';
  dropoffAddress = '';
  testOrder = false;

  selectedPickup?: string;
  selectedDropoff?: string;

  pickups: string[] = [];
  dropoffs: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<CreateOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogInput: CreateOrderDialogInput,
  ) {
    this.phoneFormControl = new FormControl('', [
      Validators.required,
      Validators.pattern('[- +()0-9]{6,}'),
    ]);
    if (this.dialogInput.phone) {
      this.phoneFormControl.setValue(this.dialogInput.phone);
    }
    this.pickups = this.dialogInput.pickups.map((p) => p.displayName ?? p.name);
    this.dropoffs = this.dialogInput.dropoffs.map(
      (d) => d.displayName ?? d.name,
    );
    this.emailFormControl = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);
  }

  ngOnInit(): void {
    const pickup = this.dialogInput.pickups[0];
    if (
      pickup !== undefined &&
      !this.dialogInput.allowCustomPickupLocationsWithinBounds
    ) {
      this.selectPickup(pickup.displayName ?? pickup.name);
    }

    const dropoff = this.dialogInput.dropoffs[0];
    if (
      dropoff !== undefined &&
      !this.dialogInput.allowCustomDropoffLocationsWithinBounds
    ) {
      this.selectDropoff(dropoff.displayName ?? dropoff.name);
    }
  }

  ngAfterViewInit() {
    const options = {
      // bounds: defaultBounds,
      // types: ['address']
    };
    if (this.dialogInput.allowCustomPickupLocationsWithinBounds) {
      const pickupAutocomplete = new google.maps.places.Autocomplete(
        this.pickupLocationElement.nativeElement,
        options,
      );
      pickupAutocomplete.addListener('place_changed', () => {
        this.handoverLocationChanged(
          pickupAutocomplete.getPlace(),
          true /*isPickup*/,
        );
      });
    }
    if (this.dialogInput.allowCustomDropoffLocationsWithinBounds) {
      const dropoffAutocomplete = new google.maps.places.Autocomplete(
        this.dropoffLocationElement.nativeElement,
        options,
      );

      dropoffAutocomplete.addListener('place_changed', () => {
        this.handoverLocationChanged(
          dropoffAutocomplete.getPlace(),
          false /*isPickup*/,
        );
      });
    }
  }

  private handoverLocationChanged(
    place: google.maps.places.PlaceResult,
    isPickup: boolean,
  ) {
    let location = place.geometry?.location;
    if (!location) {
      const latLon = parseLatLonString(
        isPickup
          ? this.pickupLocationElement.nativeElement.value
          : this.dropoffLocationElement.nativeElement.value,
      );
      if (!latLon) {
        return;
      }
      location = new google.maps.LatLng(latLon.lat(), latLon.lng());
    }
    const createHandover = isPickup
      ? this.order.pickupHandover
      : this.order.dropoffHandover;
    createHandover.latitude = location.lat();
    createHandover.longitude = location.lng();
    createHandover.address = place.formatted_address;
    createHandover.displayName = place.formatted_address;
  }

  onCreateClick() {
    if (this.phoneFormControl.value && !this.phoneFormControl.valid) {
      return;
    }

    if (this.emailFormControl.value && !this.emailFormControl.valid) {
      return;
    }
    if (this.phoneFormControl.value && this.phoneFormControl.valid) {
      this.order.dropoffHandover.phone = this.phoneFormControl.value;
      if (this.phoneNotificationMethod === NotificationMethod.TEXT_MESSAGES) {
        this.order.dropoffHandover.phoneNotifications = true;
      }
      if (this.phoneNotificationMethod === NotificationMethod.PHONE_CALLS) {
        this.order.dropoffHandover.phoneCallNotifications = true;
      }
    }

    if (this.emailFormControl.value && this.emailFormControl.valid) {
      this.order.dropoffHandover.email = this.emailFormControl.value;
      this.order.dropoffHandover.emailNotifications = true;
    }

    if (this.testOrder) {
      this.order.testOrder = true;
    }
    this.dialogRef.close(this.order);
  }

  onCancelClick() {
    this.dialogRef.close(undefined);
  }

  selectPickup(selectedPickupName: string) {
    const selectedPickup = this.dialogInput.pickups.find(
      (d) =>
        d.displayName === selectedPickupName || d.name === selectedPickupName,
    );
    this.order.pickupHandover.locationId = selectedPickup?.name;
    this.selectedPickup = selectedPickupName;
  }

  selectDropoff(selectedDropoffName: string) {
    const selectedDropoff = this.dialogInput.dropoffs.find(
      (d) =>
        d.displayName === selectedDropoffName || d.name === selectedDropoffName,
    );
    this.order.dropoffHandover.locationId = selectedDropoff?.name;
    this.selectedDropoff = selectedDropoffName;
  }

  checkIfCanBeConfirmed(): boolean {
    return (
      (this.order.pickupHandover.locationId !== undefined ||
        (this.order.pickupHandover.latitude !== undefined &&
          this.order.pickupHandover.longitude !== undefined)) &&
      (this.order.dropoffHandover.locationId !== undefined ||
        (this.order.dropoffHandover.latitude !== undefined &&
          this.order.dropoffHandover.longitude !== undefined))
    );
  }
}
