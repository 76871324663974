import {
  distinctUntilChanged,
  map,
  share,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { BackendStatePolling } from './backend-state-polling';
import { Finalizable } from '../../../../utils/finalizable';
import { Observable, firstValueFrom } from 'rxjs';
import { BackendService } from '../../backend.service';

const SHOW_INITIAL_SKIP_ROBOT_INQUIRY_MILLIS = 5 * 1000;
const SHOW_REPEATED_SKIP_ROBOT_INQUIRY_MILLIS = 30 * 1000;
const MIN_REMAINING_DISTANCE = 30;

export const FINALIZE_ROBOT_COMMUNICATION_THRESHOLD_MILLIS = 5 * 1000;

export class RobotUnsupervisedAutonomy extends Finalizable {
  private accessGroups: string[] = [];
  private availableSinceMillis: number | null = null;

  readonly unsupervisedAutonomyAvailableForCurrentUserMillis$ =
    this.backendStatePolling.robotState$.pipe(
      tap((robot) => {
        this.accessGroups = robot.accessGroups ?? [];
      }),
      map((robot) => {
        if (
          !robot.unsupervisedAutonomyState?.available ||
          robot.unsupervisedAutonomyState?.remainingDistance === undefined ||
          robot.unsupervisedAutonomyState?.remainingDistance <
            MIN_REMAINING_DISTANCE
        ) {
          this.availableSinceMillis = null;
          return 0;
        }
        if (this.availableSinceMillis === null) {
          this.availableSinceMillis = Date.now();
        }
        return Date.now() - this.availableSinceMillis;
      }),
      share(),
    );

  private showSkipRobotSince: number | null = null;
  private skippedAt: number | null = null;

  readonly finalizeUnsupervisedAutonomyRobotCommunicationInMillis$: Observable<
    number | null
  > = this.unsupervisedAutonomyAvailableForCurrentUserMillis$.pipe(
    switchMap(async (unsupervisedAutonomyAvailableForCurrentUserMillis) => {
      if (
        unsupervisedAutonomyAvailableForCurrentUserMillis <
        SHOW_INITIAL_SKIP_ROBOT_INQUIRY_MILLIS
      ) {
        this.showSkipRobotSince = null;
        return null;
      }
      if (
        this.skippedAt &&
        Date.now() - this.skippedAt < SHOW_REPEATED_SKIP_ROBOT_INQUIRY_MILLIS
      ) {
        this.showSkipRobotSince = null;
        return null;
      }
      const { exists } = await firstValueFrom(
        this.backendService.get(
          `/robot-operator-matching/has-unassigned-robots-with-required-control?access_groups=${this.accessGroups.join(
            ',',
          )}`,
        ),
      );
      if (!exists) {
        this.showSkipRobotSince = null;
        return null;
      }

      const now = Date.now();
      if (this.showSkipRobotSince === null) {
        this.showSkipRobotSince = now;
      }

      return (
        FINALIZE_ROBOT_COMMUNICATION_THRESHOLD_MILLIS -
        (Date.now() - this.showSkipRobotSince)
      );
    }),
    distinctUntilChanged(),
    share(),
  );

  readonly robotWithUnsupervisedAutonomyAvailableIsReadyForFinalization$ =
    this.finalizeUnsupervisedAutonomyRobotCommunicationInMillis$.pipe(
      takeUntil(this.finalized$),
      map((finalizeBlockedRobotCommunicationInMillis) => {
        return (
          finalizeBlockedRobotCommunicationInMillis !== null &&
          finalizeBlockedRobotCommunicationInMillis <= 0
        );
      }),
    );

  constructor(
    private readonly backendService: BackendService,
    private readonly backendStatePolling: BackendStatePolling,
  ) {
    super();
  }

  snoozeSkipRobotInquiry() {
    this.availableSinceMillis = null;
    this.showSkipRobotSince = null;
    this.skippedAt = Date.now();
  }

  protected override async onFinalize(): Promise<void> {
    // nothing to do, just implement an interface
  }
}
