import { RobotCommunication } from '../core/robots-service/robot-communication';
import { RobotSupervisionTaskDto } from './operator-task.dto';

export class SupervisionTask {
  finalize() {
    this.robotCommunication.finalize();
  }
  constructor(
    public robotCommunication: RobotCommunication,
    public task: RobotSupervisionTaskDto,
  ) {}
}
