import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.auth.user$.pipe(
      take(1),
      map((user) => {
        const allowedRoles: string[] = route.data['allowedRoles'] || [];
        if (!user) {
          this.router.navigate(['/login'], {
            queryParams: { returnUrl: state.url, requiredRoles: allowedRoles },
          });
          return false;
        }
        const matchingRoles = allowedRoles.filter(
          (allowedRole) =>
            user.roles.includes(allowedRole) || allowedRole == '*',
        );
        if (matchingRoles.length === 0) {
          this.router.navigate(['/login'], {
            queryParams: { returnUrl: state.url, requiredRoles: allowedRoles },
          });
          return false;
        }
        return true;
      }),
    );
  }
}
