<div class="order-details-container">


<div class="header">
  <h2 class="title" mat-dialog-title>Order Details</h2>
  <button *ngIf="canEditOrderStatus$ | async" mat-raised-button (click)="updateOrder()">Update Order Status</button>
</div>
<mat-dialog-content class="mat-typography">
  <pre> {{ prettyJson }} </pre>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</mat-dialog-actions>
</div>