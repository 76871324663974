export class TemporaryValue<T> {
  private timeoutId?: NodeJS.Timeout;

  private _value = this.defaultValue;

  constructor(
    private readonly defaultValue: T,
    private readonly resetTimeoutMillis: number,
  ) {}

  get value() {
    return this._value;
  }

  set value(value: T) {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
    if (value !== this.defaultValue) {
      this.timeoutId = setTimeout(() => {
        this._value = this.defaultValue;
        this.timeoutId = undefined;
      }, this.resetTimeoutMillis);
    }
    this._value = value;
  }
}
