import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CollectionDto, FrameDto, SnippetDto } from '../ml-data-types';

export type DataItem = FrameDto | SnippetDto | CollectionDto;

@Component({
  selector: 'app-selected-item',
  templateUrl: './selected-item.component.html',
  styleUrls: ['./selected-item.component.sass'],
})
export class SelectedItemComponent {
  @Input()
  selectedItem?: DataItem = undefined;

  @Input()
  metadataItem?: DataItem = undefined;

  @Input()
  previewImage?: string = undefined;

  @Input()
  previewVideo?: string = undefined;

  @Input()
  snippetFrames: FrameDto[] = [];

  @Output()
  selectItem = new EventEmitter<DataItem>();
}
