import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { Integration } from '@sentry/types';
import {
  init as Sentry,
  BrowserTracing,
  Replay,
  routingInstrumentation,
} from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import git from './git-version.json';

const integrations: Integration[] = [
  new BrowserTracing({
    tracePropagationTargets: ['localhost', environment.backendUrl],
    routingInstrumentation,
  }),
];

if (environment.sentry.replay)
  integrations.push(
    new Replay({
      blockAllMedia: false,
      maskAllText: false,
    }),
  );

if (environment.sentry.enabled)
  Sentry({
    dsn: 'https://1d294c4164714b6aa072bcbbd806405e@o4505049490194432.ingest.sentry.io/4505049522044928',
    tunnel: environment.sentry.tunnel,
    environment: environment.name,
    release: environment.production ? git.revision : 'dev',
    integrations,

    tracesSampleRate: environment.sentry.tracing,
    replaysSessionSampleRate: environment.sentry.replaySessions,
    replaysOnErrorSampleRate: environment.sentry.replayErrors,
  });

if (environment.production) enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
