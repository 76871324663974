import { distinctUntilChanged, map } from 'rxjs/operators';
import { BackendStatePolling } from './backend-state-polling';
import { HazardLightsState, PowerMode } from './robot.dto';

const THRESHOLD_OF_DEATH_MS = 1000 * 60;

export class RobotDerivedState {
  readonly isAlive$ = this.backendStatePolling.robotState$.pipe(
    map((robotState) => {
      const updateTime = robotState.updatedAt?.getTime() ?? 0;
      const now = Date.now();
      return now - updateTime < THRESHOLD_OF_DEATH_MS;
    }),
    distinctUntilChanged(),
  );
  readonly isServingOrder$ = this.backendStatePolling.robotState$.pipe(
    map((robotState) => {
      const orderLength = robotState.scheduledStops?.[0]?.orders.length ?? 0;
      return orderLength > 0;
    }),
    distinctUntilChanged(),
  );
  readonly unsupervisedAutonomyState$ =
    this.backendStatePolling.robotState$.pipe(
      map((robotState) => {
        return robotState.unsupervisedAutonomyState;
      }),
    );
  readonly isPowerSaving$ = this.backendStatePolling.robotState$.pipe(
    map((robotState) => robotState.powerMode === PowerMode.SAVING),
    distinctUntilChanged(),
  );
  readonly isAutomaticPowerSaving$ = this.backendStatePolling.robotState$.pipe(
    map((robotState) => robotState.automaticPowerSaving === true),
    distinctUntilChanged(),
  );
  readonly hazardLightsState$ = this.backendStatePolling.robotState$.pipe(
    map((robot) => robot.hazardLightsState ?? HazardLightsState.AUTO),
    distinctUntilChanged(),
  );
  readonly desiredOperatorHazardLightsState$ =
    this.backendStatePolling.robotState$.pipe(
      map(
        (robot) =>
          robot.desiredOperatorHazardLightsState ?? HazardLightsState.AUTO,
      ),
      distinctUntilChanged(),
    );
  readonly readyForOrders$ = this.backendStatePolling.robotState$.pipe(
    map((robot) => !!robot.readyForOrders),
    distinctUntilChanged(),
  );

  constructor(
    readonly robotId: string,
    private readonly backendStatePolling: BackendStatePolling,
  ) {}
}
