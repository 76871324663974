import { RobotCommunication } from '../core/robots-service/robot-communication';
import { RobotSlotsConfig } from './supervision-settings/supervision-settings.service';

export type RobotSlotCell =
  | {
      readonly slotType: 'taken';
      readonly robotCommunication: RobotCommunication;
    }
  | {
      readonly slotType: 'empty';
      readonly lastRequestTime: Date;
    }
  | {
      readonly slotType: 'disabled';
    };

export function applyRobotSlotsConfig(
  robotSlots: RobotSlots,
  slotsConfiguration: RobotSlotsConfig,
): RobotSlots {
  return slotsConfiguration.map((isEnabled, index) => {
    const slot: RobotSlotCell = robotSlots[index]!;
    if (isEnabled) {
      switch (slot.slotType) {
        case 'disabled':
          return createEmptySlot();
        default:
          return slot;
      }
    } else {
      if (slot.slotType === 'taken') {
        slot.robotCommunication.finalize();
      }
      return disabledSlot;
    }
  }) as RobotSlots;
}

export function countEmptySlots(robotSlots: RobotSlots) {
  return robotSlots.filter((slot) => slot.slotType === 'empty').length;
}

export type RobotSlots = [
  RobotSlotCell,
  RobotSlotCell,
  RobotSlotCell,
  RobotSlotCell,
];

export function createEmptySlot(): RobotSlotCell {
  return {
    lastRequestTime: new Date(),
    slotType: 'empty',
  };
}

export const disabledSlot: RobotSlotCell = {
  slotType: 'disabled',
};
