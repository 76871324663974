import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  RendererFactory2,
  ViewChild,
} from '@angular/core';
import {
  of,
  Subject,
  Observable,
  EMPTY,
  combineLatest,
  merge,
  fromEvent,
  throwError,
} from 'rxjs';
import {
  exhaustMap,
  retry,
  map,
  switchMap,
  tap,
  shareReplay,
  take,
  takeUntil,
  filter,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {
  CreateOrderDialogComponent,
  CreateOrderDialogInput,
} from '../create-order-dialog/create-order-dialog.component';
import { AuthService } from '../../core/auth.service';
import {
  HandoverType,
  Order,
  OrderStatus,
  CreateOrderDto,
  CommunicationLogEntryType,
  CommunicationDeliveryStatus,
  CommunicationMedium,
  PhoneCallStatus,
} from '../../core/order/order';
import { BackendService } from '../../core/backend.service';
import { Operation, RobotQueueEdgeHandover } from '../operation';
import {
  Compartment,
  CompartmentState,
  RobotDto,
} from '../../core/robots-service/backend/robot.dto';
import { ActivatedRoute, Router } from '@angular/router';
import { orderStatusToString } from '../utils';

import { OrderDetailsDialogComponent } from '../../orders/order-details-dialog/order-details-dialog.component';
import {
  MatSnackBar,
  MatSnackBarRef,
  SimpleSnackBar,
} from '@angular/material/snack-bar';
import { ErrorService } from '../../core/error-system/error.service';

import {
  OrderManagementDialog,
  hasOrderManagementAccess,
} from '../order-management-dialog/order-management-dialog.component';
import { PolygonGeometryDto } from '@cartken/map-types';
import * as R from 'ramda';
import { Watchdog } from './watchdog';
import { RobotInfo } from './robot-info';
import { visiblePageTimer } from '../../../utils/page-visibility';
import {
  CancelOrderDialogComponent,
  CancelOrderDialogData,
  CancelOrderDialogOutput,
} from './cancel-order-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { toLatLng } from '../../../utils/geo-tools';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Role, User } from '../../../app/core/user';
import { RobotsBackendService } from '../../../app/core/robots-service/robots-backend.service';
import {
  formatCreated,
  formatHandoverString,
  formatIds,
} from '../../../app/core/order/order-utils';

function orderToTableRow(order: Order): OrderTableRow {
  return {
    order,
    robot: order.assignedRobotName ?? '',
    created: formatCreated(order),
    id: formatIds(order),
    pickup: formatHandoverString(order.handovers[0]),
    dropoff: formatHandoverString(order.handovers[1]),
    status: orderStatusToString(order),
    testOrder: !!order.testOrder,
    compartmentCanBeOpenend:
      order.status === OrderStatus.WAITING_FOR_HANDOVER &&
      !!order.compartmentLocked,
  };
}

const ROLES_WITH_LOCK_ACCESS: Set<string> = new Set([
  Role.ADMIN,
  Role.ROBOT_OPERATOR,
  Role.ROBOT_MAINTAINER,
]);

const ROLES_WITH_CANCEL_ORDER_ACCESS: Set<string> = new Set([
  Role.ADMIN,
  Role.OPERATIONS_MANAGER,
  Role.OPERATIONS_USER,
]);

function hasCancelOrderAccess(user?: User): boolean {
  return !!user?.roles.some((role) => ROLES_WITH_CANCEL_ORDER_ACCESS.has(role));
}

const ROLES_WITH_ORDER_CREATION_ACCESS: Set<string> = new Set([
  Role.ADMIN,
  Role.OPERATIONS_MANAGER,
  Role.OPERATIONS_USER,
]);

function hasCreateOrderAccess(user?: User): boolean {
  return !!user?.roles.some((role) =>
    ROLES_WITH_ORDER_CREATION_ACCESS.has(role),
  );
}

function hasRobotLockAccess(user?: User): boolean {
  return !!user?.roles.some((role) => ROLES_WITH_LOCK_ACCESS.has(role));
}

const SNACK_BAR_MSG_TIMEOUT = 3 * 1000;
const POLLING_INTERVAL_MS = 5 * 1000;
const POLLING_DEAD_INTERVAL_MS = POLLING_INTERVAL_MS * 3;

export type OrderTableRow = {
  order: Order;
  robot: string;
  id: string;
  created: Date;
  pickup: string;
  dropoff: string;
  status: string;
  testOrder: boolean;
  compartmentCanBeOpenend: boolean;
};

function createErrorMessage(httpError: HttpErrorResponse) {
  return httpError.error?.message?.match(/^[\w ]+:/i)
    ? httpError.error.message
    : `Order creation failed: ${httpError.error.message}`;
}

@Component({
  selector: 'app-operation-live-view',
  templateUrl: './operation-live-view.component.html',
  styleUrls: ['./operation-live-view.component.sass'],
})
export class OperationLiveViewComponent implements AfterViewInit, OnDestroy {
  private robotIdToExpectedCompartmentsStateMap = new Map<
    string,
    CompartmentState
  >();

  private destroy$: Subject<void> = new Subject();

  private readonly minimumDisplayedColumns: string[] = [
    'robot',
    'compartment',
    'id',
    'created',
    'testOrder',
    'pickup',
    'dropoff',
    'status',
    'icons',
  ];

  displayedColumns: string[] = [...this.minimumDisplayedColumns];

  readonly orderStatuses = Object.values(OrderStatus);
  orderStatusFilter = 'active';
  @ViewChild('textSearchInput') textSearchInput!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  resultsLength = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  selectedPickupHandoverAlias?: string;
  pickups?: RobotQueueEdgeHandover[];
  dropoffs?: RobotQueueEdgeHandover[];
  preferredCountryCodes?: string[];

  operationId$!: Observable<string>;
  operationDisplayName?: string;
  orderQueue = new MatTableDataSource<OrderTableRow>([]);
  readyForOrders = false;
  robotFilter: 'robots-with-order' | 'robots-without-order' | '' = '';

  robotInfos: RobotInfo[] = [];
  filteredRobotInfos: RobotInfo[] = [];

  robotsReady = 0;
  robotsCount = 0;

  private pickupImmediatelyEnabled?: boolean = false;

  private googleMap!: google.maps.Map;
  private renderer: Renderer2;

  private allowCustomPickupLocationsWithinBounds = false;
  private allowCustomDropoffLocationsWithinBounds = false;
  private operationRegion: Observable<PolygonGeometryDto | undefined>;

  readonly googleMapOptions = {
    center: { lat: 37.740667, lng: -122.201146 },
    zoom: 18,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    tilt: 0,
    clickableIcons: false,
    fullscreenControl: false,
    streetViewControl: false,
    disableDoubleClickZoom: true,
  };

  private readonly pickupIcon = {
    url: 'assets/shopping_basket.svg',
    scaledSize: new google.maps.Size(40, 40),
    anchor: new google.maps.Point(20, 5),
  };

  private readonly dropoffIcon = {
    url: 'assets/flag.svg',
    scaledSize: new google.maps.Size(40, 40),
    anchor: new google.maps.Point(9, 36),
  };

  private orderMapMarkers: (google.maps.Marker | google.maps.Polyline)[] = [];

  readonly waitingOrderStatuses = [OrderStatus.WAITING_FOR_HANDOVER];

  private createOrderDialogRef?: MatDialogRef<CreateOrderDialogComponent>;
  private refresh$ = new Subject<void>();

  canEditOrderStatus$: Observable<boolean>;
  canOpenCloseRobots$: Observable<boolean>;
  canCreateOrders$: Observable<boolean>;
  canCancelOrders$: Observable<boolean>;

  private watchdog = new Watchdog(POLLING_DEAD_INTERVAL_MS);

  constructor(
    private backendService: BackendService,
    private dialog: MatDialog,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private orderDialog: MatDialog,
    private errorService: ErrorService,
    private snackBar: MatSnackBar,
    private robotsBackendService: RobotsBackendService,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);

    this.operationId$ = this.route.paramMap.pipe(
      switchMap((params) => {
        const operationId = params.get('operation-id');

        if (operationId === null) {
          this.errorService.reportError(
            `URL is not correct, operation-id is not found`,
          );
          return EMPTY;
        }

        return of(operationId);
      }),
    );

    const pickupDisplayName$ = this.route.paramMap.pipe(
      switchMap((params) => {
        const pickupDisplayName = params.get('pickup-handover-alias');

        if (pickupDisplayName === null) {
          return of(undefined);
        }

        return of(pickupDisplayName);
      }),
    );

    const orderOperation$ = this.operationId$.pipe(
      switchMap((operationId) =>
        this.backendService
          .get<Operation>(`/operations/${operationId}`)
          .pipe(
            this.errorService.handleStreamErrors(
              `Operation with name '${operationId}' could not be retrieved.`,
            ),
          ),
      ),
      tap((operation) => {
        this.operationDisplayName = operation.displayName;
        this.pickupImmediatelyEnabled =
          operation.operationData?.pickupImmediatelyEnabled;
      }),
      shareReplay(1),
    );

    this.operationRegion = orderOperation$.pipe(
      map((operation) => operation.operationRegion),
    );

    combineLatest([orderOperation$, pickupDisplayName$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([operation, pickupDisplayName]) => {
        if (operation.operationData === undefined) {
          return;
        }

        operation.operationData.pickups = operation.operationData.pickups || [];
        operation.operationData.dropoffs =
          operation.operationData.dropoffs || [];
        if (pickupDisplayName) {
          if (
            operation.operationData.pickups.some(
              (pickup) => pickup.displayName === pickupDisplayName,
            )
          ) {
            this.selectedPickupHandoverAlias = pickupDisplayName;
          } else {
            console.warn(
              `Display name '${pickupDisplayName}' could not be retrieved for '${operation.id}' , redirecting.`,
            );
            this.router.navigate(['']);
          }
        }
        if (this.selectedPickupHandoverAlias) {
          operation.operationData.pickups =
            operation.operationData.pickups.filter(
              (p) => p.displayName === this.selectedPickupHandoverAlias,
            );
        }
        this.pickups = operation.operationData.pickups.sort(
          (a, b) =>
            a.displayName?.localeCompare(b.displayName || '', undefined, {
              sensitivity: 'base',
            }) || -1,
        );
        this.dropoffs = operation.operationData.dropoffs.sort(
          (a, b) =>
            a.displayName?.localeCompare(b.displayName || '', undefined, {
              sensitivity: 'base',
            }) || -1,
        );

        this.preferredCountryCodes =
          operation.operationData.preferredCountryCodes;

        this.allowCustomPickupLocationsWithinBounds =
          operation.operationData.allowCustomPickupLocationsWithinBounds ||
          false;
        this.allowCustomDropoffLocationsWithinBounds =
          operation.operationData.allowCustomDropoffLocationsWithinBounds ||
          false;
      });

    this.canEditOrderStatus$ = this.authService.user$.pipe(
      map(hasOrderManagementAccess),
    );

    this.canCreateOrders$ = this.authService.user$.pipe(
      map(hasCreateOrderAccess),
    );

    this.canCancelOrders$ = this.authService.user$.pipe(
      map(hasCancelOrderAccess),
    );

    this.canOpenCloseRobots$ = this.authService.user$.pipe(
      map(hasRobotLockAccess),
    );

    this.enableWatchdog();
  }

  ngAfterViewInit(): void {
    this.orderQueue.sort = this.sort;
    fromEvent(this.textSearchInput.nativeElement, 'keyup')
      .pipe(
        filter((v) => !!v),
        debounceTime(250),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.paginator.pageIndex = 0;
        this.refresh$.next(undefined);
      });

    this.subscribeOrders();
  }

  private subscribeOrders() {
    merge(
      visiblePageTimer(0, POLLING_INTERVAL_MS),
      this.paginator.page,
      this.refresh$,
    )
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.operationId$),
        exhaustMap((operationId) => {
          const baseQuery = `/orders?operation_id=${operationId}&status=${this.orderStatusFilter}&per_page=${this.paginator.pageSize}&page=${this.paginator.pageIndex}`;
          const textSearch = this.textSearchInput.nativeElement.value;
          const query =
            baseQuery + (textSearch ? `&text_match=${textSearch}` : '');
          return combineLatest([
            this.backendService.getWithHeader(query).pipe(
              this.errorService.handleStreamErrors(
                'Can not get updated operation state',
              ),
              map((data: HttpResponse<Order[]> | undefined) => {
                if (data === undefined) {
                  return [];
                }

                // Only refresh the result length if there is new data. In case of rate
                // limit errors, we do not want to reset the paginator to zero, as that
                // would prevent users from re-triggering requests.
                this.resultsLength = Number(data.headers.get('x-total-count'));
                return data.body ?? [];
              }),
            ),
            this.backendService
              .get(`/robots?assigned_operation_id=${operationId}`)
              .pipe(
                this.errorService.handleStreamErrors(
                  'Can not get updated robots status',
                ),
              ),
          ]);
        }),
      )
      .subscribe(([orders, robots]: [Order[], RobotDto[]]) => {
        this.updateState(orders, robots);
      });
  }

  private enableWatchdog() {
    let errorSnackBar: MatSnackBarRef<SimpleSnackBar> | null = null;
    this.watchdog.problemDetected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isProblem) => {
        if (isProblem) {
          errorSnackBar = this.snackBar.open(
            'Page update failed, please reload.',
            'reload',
          );
          errorSnackBar.onAction().subscribe(() => {
            window.location.reload(
              // @ts-ignore
              true, // for Firefox
            );
          });
        } else {
          errorSnackBar?.dismiss();
        }
      });
    this.watchdog.start();
  }

  onGoogleMap(googleMap: google.maps.Map) {
    this.googleMap = googleMap;
    this.authService.user$.subscribe((user) => {
      if (user) {
        this.refresh$.next(undefined);
      }
    });
    this.zoomToBoundingPolygon();
    this.addZoomToBoundingPolygonControl(this.googleMap);

    this.robotRouteMarker = new google.maps.Polyline({
      strokeColor: 'green',
      icons: [
        {
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 4,
          },
        },
      ],
    });
  }

  private addZoomToBoundingPolygonControl(map: google.maps.Map) {
    const zoomOutButton = this.renderer.createElement('div');
    zoomOutButton.style.backgroundColor = '#fff';
    zoomOutButton.style.borderRadius = '2px';
    zoomOutButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    zoomOutButton.style.cursor = 'pointer';
    zoomOutButton.style.marginRight = '10px';
    zoomOutButton.style.height = '40px';
    zoomOutButton.style.width = '40px';
    zoomOutButton.style.textAlign = 'center';
    zoomOutButton.title = 'Click to recenter the map';
    zoomOutButton.style.display = 'flex';
    zoomOutButton.style.alignItems = 'center';
    zoomOutButton.style.justifyContent = 'center';
    zoomOutButton.addEventListener('click', () => {
      this.zoomToBoundingPolygon();
    });

    const zoomOutIcon = this.renderer.createElement('mat-icon');
    this.renderer.appendChild(
      zoomOutIcon,
      this.renderer.createText('zoom_out_map'),
    );
    this.renderer.addClass(zoomOutIcon, 'mat-icon');
    this.renderer.addClass(zoomOutIcon, 'material-icons');
    zoomOutButton.appendChild(zoomOutIcon);

    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM]?.push(zoomOutButton);
  }

  ngOnDestroy() {
    this.destroy$.next(undefined);
    this.watchdog.destroy();
  }

  async logout() {
    await this.authService.logout();
    this.router.navigate(['/login']);
  }

  openCompartmentForOrder(orderId: string) {
    this.backendService
      .post(`/orders/${orderId}/open-compartment`, {})
      .pipe(retry(2))
      .subscribe();
  }

  onOpenTrackingLink(trackingCode: string) {
    window.open('/orders/' + `${trackingCode}`, '_blank');
  }

  editOrder({ order }: OrderTableRow) {
    this.dialog.open(OrderManagementDialog, { data: { order } });
  }

  onOrderStatusFilterChanged() {
    this.paginator.pageIndex = 0;
    this.refresh$.next(undefined);
  }

  onRobotFilterChanged() {
    this.filteredRobotInfos = this.robotInfos.filter(
      (robotInfo) =>
        !this.robotFilter ||
        robotInfo.hasOrders === (this.robotFilter === 'robots-with-order'),
    );

    this.robotInfos.forEach((robotInfo) => {
      if (
        this.filteredRobotInfos.find((r) => r.robotId === robotInfo.robotId)
      ) {
        robotInfo.robotMapMarker.setMap(this.googleMap);
      } else {
        robotInfo.robotMapMarker.setMap(null);
      }
    });
  }

  cancelOrder({ order }: OrderTableRow) {
    this.dialog
      .open<
        CancelOrderDialogComponent,
        CancelOrderDialogData,
        CancelOrderDialogOutput
      >(CancelOrderDialogComponent, { data: { orderId: order.id } })
      .afterClosed()
      .subscribe((output) => {
        if (output?.reason) {
          this.backendService
            .post(`/orders/${order.id}/cancel`, { reason: output.reason })
            .pipe(retry(5))
            .subscribe(
              () => {
                this.refresh$.next(undefined);
                this.snackBar.open(`Order ${order.id} is canceled`, undefined, {
                  verticalPosition: 'top',
                  duration: SNACK_BAR_MSG_TIMEOUT,
                });
              },
              () => {
                this.snackBar.open(
                  `Failed to cancel order ${order.id}`,
                  undefined,
                  { verticalPosition: 'top', duration: SNACK_BAR_MSG_TIMEOUT },
                );
              },
            );
        }
      });
  }

  completeCurrentHandovers(robotInfo: RobotInfo) {
    this.backendService
      .post(`/orders/complete-handovers/${robotInfo.robotId}`, {})
      .pipe(retry(5))
      .subscribe(() => {
        this.refresh$.next(undefined);
      });
  }

  openCreateOrderDialog() {
    this.createOrderDialogRef = this.dialog.open(CreateOrderDialogComponent, {
      position: { top: '100px' },
      minWidth: '500px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: {
        useExternalId: true,
        usePhoneNumber: true,
        pickups: this.pickups,
        dropoffs: this.dropoffs,
        preferredCountryCodes:
          this.preferredCountryCodes && this.preferredCountryCodes?.length > 0
            ? this.preferredCountryCodes
            : ['us'],
        allowCustomPickupLocationsWithinBounds:
          this.allowCustomPickupLocationsWithinBounds,
        allowCustomDropoffLocationsWithinBounds:
          this.allowCustomDropoffLocationsWithinBounds,
        robots: this.robotInfos.filter((robot) => robot.robotReadyForOrders),
        pickupImmediatelyEnabled: this.pickupImmediatelyEnabled,
      } as CreateOrderDialogInput,
    });
    this.createOrderDialogRef
      .afterClosed()
      .subscribe((orderData?: CreateOrderDto) => {
        if (!orderData) {
          return;
        }
        this.createOrder(orderData);
        this.createOrderDialogRef = undefined;
      });
  }

  createOrder(orderData: CreateOrderDto) {
    this.operationId$
      .pipe(
        exhaustMap((operationId) =>
          this.backendService.post(
            '/orders',

            { ...orderData, operationId },
            (httpError) => {
              if (httpError instanceof HttpErrorResponse) {
                const errorMessage = createErrorMessage(httpError);
                this.snackBar.open(errorMessage, 'Confirm', {
                  verticalPosition: 'top',
                });
                return EMPTY;
              }
              return throwError(httpError);
            },
          ),
        ),
      )
      .subscribe(() => {
        this.snackBar.open('Order was created successfully', undefined, {
          verticalPosition: 'top',
          duration: SNACK_BAR_MSG_TIMEOUT,
        });
        this.refresh$.next(undefined);
      });
  }

  hideOrderDetails(pickupHandoverAlias: string) {
    return (
      this.selectedPickupHandoverAlias &&
      this.selectedPickupHandoverAlias !== pickupHandoverAlias
    );
  }

  showOrderOnMap({ order }: OrderTableRow) {
    const pickup = order.handovers[0];
    if (pickup !== undefined) {
      this.orderMapMarkers.push(
        new google.maps.Marker({
          map: this.googleMap,
          icon: this.pickupIcon,
          position: new google.maps.LatLng(pickup.latitude, pickup.longitude),
        }),
      );
    }
    const dropoff = order.handovers[1];
    if (dropoff !== undefined) {
      this.orderMapMarkers.push(
        new google.maps.Marker({
          map: this.googleMap,
          icon: this.dropoffIcon,
          position: new google.maps.LatLng(dropoff.latitude, dropoff.longitude),
        }),
      );
    }

    if (order.robotRoute) {
      this.orderMapMarkers.push(
        new google.maps.Polyline({
          map: this.googleMap,
          path: order.robotRoute.map((coords) => {
            // override unsafe index access warning, since the value should be a tuple according to GeoJSON spec
            return new google.maps.LatLng(coords[1]!, coords[0]!);
          }),
          strokeColor: 'green',
        }),
      );
    }
  }

  hideOrderOnMap() {
    for (const marker of this.orderMapMarkers) {
      marker.setMap(null);
    }
    this.orderMapMarkers = [];
  }
  robotRouteMarker?: google.maps.Polyline;

  async showRobotRoute(robot: RobotInfo) {
    const route = await this.robotsBackendService.getRobotRoute(robot.robotId);
    if (!route?.geometry) {
      return;
    }
    this.robotRouteMarker?.setPath(
      route?.geometry.map(
        (coords) => new google.maps.LatLng(coords.latitude, coords.longitude),
      ) ?? [],
    );

    this.robotRouteMarker?.setMap(this.googleMap);
  }

  hideRobotRoute() {
    this.robotRouteMarker?.setMap(null);
  }

  getOperationDisplayName() {
    return this.operationId$.pipe(
      map((operationId) => {
        if (!this.operationDisplayName) {
          return operationId;
        }
        if (this.selectedPickupHandoverAlias) {
          return (
            this.selectedPickupHandoverAlias +
            ' at ' +
            this.operationDisplayName
          );
        }
        return this.operationDisplayName;
      }),
    );
  }

  showOrderDetails({ order }: OrderTableRow) {
    this.orderDialog.open(OrderDetailsDialogComponent, {
      data: order,
    });
  }

  private updateOrders(orders: Order[]) {
    this.orderQueue.data = orders
      .filter(
        (order) => order.handovers[1]?.handoverType === HandoverType.DROPOFF,
      )
      .sort(
        (order1, order2) =>
          new Date(order1.handovers[0]?.estimatedArrivalTime!).getTime() -
          new Date(order2.handovers[0]?.estimatedArrivalTime!).getTime(),
      )
      .map(orderToTableRow);
  }

  private updateRobotInfo(
    robotMap: Map<string, RobotDto>,
    robtoInfos: RobotInfo[],
    orders: Order[],
  ) {
    for (const robotInfo of robtoInfos) {
      const robot = robotMap.get(robotInfo.robotId)!;
      const expectedCompartmentState =
        this.robotIdToExpectedCompartmentsStateMap.get(robotInfo.robotId);
      if (
        (robot.compartments ?? []).every(
          (c) => c.state === expectedCompartmentState,
        )
      ) {
        this.robotIdToExpectedCompartmentsStateMap.delete(robotInfo.robotId);
      }

      robotInfo.robotMapMarker.setMap(this.googleMap);
      robotInfo.updateRobot(robot, orders);
    }
  }

  private cleanupRemovedRobots(robotInfos: RobotInfo[]) {
    for (const robotInfo of robotInfos) {
      robotInfo.robotMapMarker.setMap(null);
    }

    this.robotRouteMarker?.setMap(null);
  }

  private createNewRobotInfos(
    robotMap: Map<string, RobotDto>,
    existingRobotInfos: RobotInfo[],
  ) {
    for (const robotInfo of existingRobotInfos) {
      robotMap.delete(robotInfo.robotId);
    }

    return Array.from(robotMap.values()).map(
      (newRobot) => new RobotInfo(newRobot, this.googleMap),
    );
  }

  private joinRobotInfos(
    oldRobotInfos: RobotInfo[],
    newRobotInfos: RobotInfo[],
  ) {
    const robotInfos = [...oldRobotInfos, ...newRobotInfos];

    robotInfos.sort((a, b) => {
      if (
        (a.robotReadyForOrders && b.robotReadyForOrders) ||
        (!a.robotReadyForOrders && !b.robotReadyForOrders)
      ) {
        return (a.name ?? '').localeCompare(b.name, 'en', {
          numeric: true,
        });
      }
      if (a.robotReadyForOrders) {
        return -1;
      }
      return 1;
    });
    return robotInfos;
  }

  private updateState(orders: Order[], robots: RobotDto[]) {
    this.updateOrders(orders);

    const newDisplayedColumns = [...this.minimumDisplayedColumns];
    if (orders.some((order) => order.displayName)) {
      newDisplayedColumns.splice(2, 0, 'displayName');
    }
    if (orders.some((order) => order.communicationLog.length)) {
      newDisplayedColumns.splice(2, 0, 'communication');
    }
    this.displayedColumns = newDisplayedColumns;

    const robotMap = new Map(robots.map((robot) => [robot.id, robot]));

    const [robotInfoToUpdate, robotInfoToRemove] = R.partition(
      (robotInfo) => robotMap.has(robotInfo.robotId),
      this.robotInfos,
    );

    this.cleanupRemovedRobots(robotInfoToRemove);
    this.updateRobotInfo(robotMap, robotInfoToUpdate, orders);

    const newRobotInfos = this.createNewRobotInfos(robotMap, robotInfoToUpdate);
    this.updateRobotInfo(robotMap, newRobotInfos, orders);

    this.robotInfos = this.joinRobotInfos(robotInfoToUpdate, newRobotInfos);
    this.robotsReady = this.robotInfos.filter(
      (a) => a.robotReadyForOrders,
    ).length;
    this.robotsCount = this.robotInfos.length;

    this.readyForOrders = !!this.robotInfos.find(
      (robotInfo) => robotInfo.robotReadyForOrders,
    );

    this.onRobotFilterChanged();

    this.watchdog.reset();
  }

  isReadyForOrders() {
    return !this.errorService.isInErrorState() && this.readyForOrders;
  }

  zoomToRobot(robotInfo: RobotInfo) {
    const robotLocation = robotInfo.robotMapMarker.getPosition();
    if (!robotLocation || !robotInfo.robotReadyForOrders) {
      return;
    }
    this.googleMap.panTo(robotLocation);
    this.googleMap.setZoom(20);
  }

  areCompartmentsOpen(compartments: Compartment[]) {
    return compartments.some(
      (c) =>
        c.state === CompartmentState.OPEN ||
        c.state === CompartmentState.OPEN_AND_PRIMED,
    );
  }

  areAllCompartmentsLocked(compartments: Compartment[]) {
    return compartments.every(
      (c) => c.state === CompartmentState.CLOSED_AND_LOCKED,
    );
  }

  setRobotLockStatus(robotInfo: RobotInfo, lock: boolean) {
    if (lock === true) {
      this.robotIdToExpectedCompartmentsStateMap.set(
        robotInfo.robotId,
        CompartmentState.CLOSED_AND_LOCKED,
      );
      this.backendService
        .post(`/robots/${robotInfo.robotId}/close-compartments`, {})
        .pipe(retry(5))
        .subscribe();
    } else {
      this.robotIdToExpectedCompartmentsStateMap.set(
        robotInfo.robotId,
        CompartmentState.CLOSED_AND_UNLOCKED,
      );
      this.backendService
        .post(`/robots/${robotInfo.robotId}/open-compartments`, {})
        .pipe(retry(5))
        .subscribe();
    }
  }

  lidStatusToIconName(robotInfo: RobotInfo) {
    if (this.robotIdToExpectedCompartmentsStateMap.has(robotInfo.robotId)) {
      return 'lock_reset';
    }
    if (robotInfo.compartments.some((c) => c.state === CompartmentState.OPEN)) {
      return 'arrow_upward';
    }

    if (
      robotInfo.compartments.some(
        (c) => c.state === CompartmentState.CLOSED_AND_UNLOCKED,
      )
    ) {
      return 'lock_open';
    }

    if (
      robotInfo.compartments.every(
        (c) => c.state === CompartmentState.CLOSED_AND_LOCKED,
      )
    ) {
      return 'lock';
    }
    return 'no_encryption';
  }

  private zoomToBoundingPolygon() {
    if (!this.googleMap) {
      return;
    }

    this.operationRegion
      .pipe(take(1))
      .subscribe((operationRegion: PolygonGeometryDto | undefined) => {
        const bounds = new google.maps.LatLngBounds();
        operationRegion?.coordinates?.forEach((polygon) => {
          polygon.forEach((point: number[]) => {
            const latLon = toLatLng(point);
            if (latLon === undefined) {
              console.error('Point has incorrect shape', point);
              return;
            }
            bounds.extend(latLon);
          });
        });

        this.googleMap.fitBounds(bounds, 0);

        const zoom = this.googleMap.getZoom() ?? 14;
        this.googleMap.setZoom(zoom < 15 ? 15 : zoom); // restrict zoom level
      });
  }

  hasDeliveredMessages(order: Order): boolean {
    return order.communicationLog.some(
      (log) =>
        (log.communicationMedium === CommunicationMedium.SMS &&
          (log.messageDeliveryStatus ===
            CommunicationDeliveryStatus.DELIVERED ||
            log.messageDeliveryStatus === CommunicationDeliveryStatus.SENT)) ||
        ([
          CommunicationLogEntryType.ORDER_STATUS_UPDATE,
          CommunicationLogEntryType.ORDER_STATUS_UPDATE_REMINDER,
        ].includes(log.type) &&
          log.messageDeliveryStatus === undefined),
    );
  }

  hasPendingMessages(order: Order): boolean {
    return order.communicationLog.some(
      (log) =>
        log.communicationMedium === CommunicationMedium.SMS &&
        log.messageDeliveryStatus === CommunicationDeliveryStatus.SENDING,
    );
  }

  hasMessageSendFailures(order: Order): boolean {
    return order.communicationLog.some(
      (log) =>
        log.communicationMedium === CommunicationMedium.SMS &&
        (log.type === CommunicationLogEntryType.SEND_FAILURE ||
          log.messageDeliveryStatus === CommunicationDeliveryStatus.FAILED),
    );
  }

  hasInboundMessages(order: Order): boolean {
    return order.communicationLog.some(
      (log) =>
        log.communicationMedium === CommunicationMedium.SMS &&
        log.type === CommunicationLogEntryType.INBOUND,
    );
  }

  hasCompletedPhoneCall(order: Order): boolean {
    return order.communicationLog.some(
      (log) =>
        log.communicationMedium === CommunicationMedium.PHONE_CALL &&
        log.phoneCallStatus === PhoneCallStatus.COMPLETED,
    );
  }

  hasNotCompletedPhoneCall(order: Order): boolean {
    return order.communicationLog.some(
      (log) =>
        log.communicationMedium === CommunicationMedium.PHONE_CALL &&
        log.phoneCallStatus !== PhoneCallStatus.COMPLETED,
    );
  }

  hasSentEmail(order: Order): boolean {
    return order.communicationLog.some(
      (log) =>
        log.communicationMedium === CommunicationMedium.EMAIL &&
        log.messageDeliveryStatus === CommunicationDeliveryStatus.SENT,
    );
  }

  hasFailedEmail(order: Order): boolean {
    return order.communicationLog.some(
      (log) =>
        log.communicationMedium === CommunicationMedium.EMAIL &&
        log.messageDeliveryStatus === CommunicationDeliveryStatus.FAILED,
    );
  }
}
