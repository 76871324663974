import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Role } from '../../core/user';

export interface CreateUserData {
  username: string;
  password: string;
  displayName: string;
  roles: string[];
}

@Component({
  selector: 'app-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.sass'],
})
export class CreateUserDialogComponent {
  user: CreateUserData;
  availableRoles: string[];

  constructor(public dialogRef: MatDialogRef<CreateUserDialogComponent>) {
    this.availableRoles = Object.values(Role);
    this.user = {
      username: '',
      password: '',
      displayName: '',
      roles: [],
    };
  }

  onCancelClick() {
    this.dialogRef.close(undefined);
  }
}
