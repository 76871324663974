<app-toolbar>
  <button mat-menu-item (click)="createRobot()" *ngIf="isAdmin">
    Create Robot
  </button>
  <button mat-menu-item (click)="createVirtualRobot()" *ngIf="isAdmin">
    Create Virtual Robot
  </button>
</app-toolbar>

<div class="spinner-background" *ngIf="dataSource.data.length === 0">
  <mat-spinner></mat-spinner>
</div>

<div class="table-backgroud" [class.hidden]="dataSource.data.length === 0">
  <div class="container">
    <div class="robot-actions">
      <mat-form-field appearance="fill" class="filter-dropdown">
        <mat-label>Filter</mat-label>
        <input
          matInput
          (keyup)="applyFilter()"
          [(ngModel)]="textFilter"
          placeholder="Example: 10"
          #input
        />
      </mat-form-field>
      <mat-form-field appearance="fill" [ngClass]="'filter-dropdown'">
        <mat-label>Charging</mat-label>
        <mat-select
          [(value)]="chargingFilter"
          (selectionChange)="applyFilter()"
        >
          <mat-option value=""> -</mat-option>
          <mat-option value="charging">Charging</mat-option>
          <mat-option value="not-charging">Not Charging</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" [ngClass]="'filter-dropdown'">
        <mat-label>Online</mat-label>
        <mat-select [(value)]="onlineFilter" (selectionChange)="applyFilter()">
          <mat-option value=""> -</mat-option>
          <mat-option value="online">Online</mat-option>
          <mat-option value="offline">Offline</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" [ngClass]="'filter-dropdown'">
        <mat-label>Ready</mat-label>
        <mat-select [(value)]="readyFilter" (selectionChange)="applyFilter()">
          <mat-option value=""> -</mat-option>
          <mat-option value="ready">Ready</mat-option>
          <mat-option value="not-ready">Not Ready</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" [ngClass]="'filter-dropdown'">
        <mat-label>Updating</mat-label>
        <mat-select
          [(value)]="updatingFilter"
          (selectionChange)="applyFilter()"
        >
          <mat-option value=""> -</mat-option>
          <mat-option value="updating">Updating</mat-option>
          <mat-option value="not-updating">Not Updating</mat-option>
        </mat-select>
      </mat-form-field>

      <selection-dropbox
        label="Operation"
        [options]="operationIds"
        [(selected)]="selectedOperationId"
        (selectedChange)="applyFilter()"
      ></selection-dropbox>

      <selection-dropbox
        label="Access Group"
        [options]="accessGroups"
        [(selected)]="selectedAccessGroup"
        (selectedChange)="applyFilter()"
      ></selection-dropbox>

      <mat-form-field appearance="fill" [ngClass]="'filter-dropdown'">
        <mat-label>Severity</mat-label>
        <mat-select
          [(value)]="selectedSeverityFilter"
          (selectionChange)="applyFilter()"
        >
          <mat-option value=""> - </mat-option>
          <mat-option
            *ngFor="let severity of severityOptions"
            [value]="severity"
            >{{ severity }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <button
        *ngIf="canSuperviseRobots"
        mat-raised-button
        color="primary"
        [disabled]="selection.selected.length === 0"
        (click)="superviseRobots()"
        class="action-button"
      >
        Supervise {{ filteredAndSelectedRobot().length }} robots
        <mat-icon>launch</mat-icon>
      </button>
      <button
        *ngIf="canSuperviseRobots"
        mat-raised-button
        color="primary"
        [disabled]="selection.selected.length === 0"
        (click)="openMassAction()"
        matTooltip="Open mass action dialog"
        class="action-button"
      >
        Open Actions
      </button>
      <mat-paginator
        [pageSize]="25"
        [hidePageSize]="true"
        aria-label="Select page"
        class="paginator"
      ></mat-paginator>
    </div>

    <div>
      <table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
        matSort
        matSortActive="updatedAt"
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? toggleFilteredSelection() : null"
              [checked]="isAllFilteredSelected()"
              [indeterminate]="
                filteredAndSelectedRobot().length > 0 &&
                !isAllFilteredSelected()
              "
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let robot">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(robot.id) : null"
              [checked]="selection.isSelected(robot.id)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="picture">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let robot">
            <img
              *ngIf="robot.pictureUrl"
              src="{{ robot.pictureUrl }}"
              class="google-profile-img"
              [ngStyle]="{
                'background-color': robot.displayColor
                  ? robot.displayColor
                  : '#f8961d'
              }"
            />
          </td>
        </ng-container>
        <ng-container matColumnDef="robotView">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Robot View</th>
          <td mat-cell *matCellDef="let robot">
            <a href="/robots/supervise/{{ robot.id }}">
              <img
                *ngIf="robot.robotView"
                [src]="transform(robot.robotView)"
                class="robot-view"
              />
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let robot">
            <div
              *ngIf="
                robot.issues !== undefined && robot.issues.length > 0;
                else simpleStatus
              "
              class="status-circle {{ extractRobotStatus(robot) }}"
            >
              <app-robot-issues-popover
                [robot]="robot"
                [issues]="robot.issues"
              ></app-robot-issues-popover>
              {{ robot.issues | length }}
            </div>
            <ng-template #simpleStatus>
              <div class="status-circle {{ extractRobotStatus(robot) }}"></div>
            </ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="batteryPercentage">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Battery</th>
          <td mat-cell *matCellDef="let robot">
            <app-battery-status
              [batteryPercentage]="robot.batteryPercentage"
              [charging]="robot.isCharging"
            ></app-battery-status>
          </td>
        </ng-container>
        <ng-container matColumnDef="serialNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Robot Name</th>
          <td mat-cell *matCellDef="let robot">
            {{ robot.serialNumber }} ({{ robot.shortName }})
          </td>
        </ng-container>
        <ng-container matColumnDef="assignedOperationId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Assigned Operation
          </th>
          <td mat-cell *matCellDef="let robot" id="assigned-operation-id">
            {{ robot.assignedOperationId || '-' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="readyForOrders">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Ready</th>
          <td mat-cell *matCellDef="let robot" id="ready-for-orders">
            <mat-slide-toggle
              [ngModel]="robot.readyForOrders"
              (change)="toggleRobotReadyForOrder(robot)"
            ></mat-slide-toggle>
          </td>
        </ng-container>
        <ng-container matColumnDef="assignedOperator">
          <th mat-header-cell *matHeaderCellDef>Assigned Operator</th>
          <td mat-cell *matCellDef="let robot"></td>
        </ng-container>
        <ng-container matColumnDef="updatedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Seen</th>
          <td mat-cell *matCellDef="let robot">
            {{ robot.updatedAt | prettyTime }}
          </td>
        </ng-container>

        <ng-container matColumnDef="awxUpdate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Updating</th>
          <td mat-cell *matCellDef="let robot">
            <button
              *ngIf="robot.updateIcon"
              mat-icon-button
              aria-label="Open robot update history dialog"
              matTooltip="Open robot update history"
              matTooltipPosition="above"
              (click)="openRobotAwxUpdateHistory(robot)"
            >
              <mat-icon> {{ robot.updateIcon }}</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="connectivity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Connectivity
          </th>
          <td mat-cell *matCellDef="let robot">
            <app-connectivity-status
              *ngIf="robot.modem1Connected !== undefined"
              [modem1Connected]="robot.modem1Connected"
              [modem2Connected]="robot.modem2Connected"
              [modem3Connected]="robot.modem3Connected"
              [wifiConnected]="robot.wifiConnected"
            ></app-connectivity-status>
          </td>
        </ng-container>

        <ng-container matColumnDef="containerVersions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Container Versions
          </th>
          <td mat-cell *matCellDef="let robot">
            <span *ngFor="let version of robot.containerVersionStrings"
              >{{ version }}<br
            /></span>
          </td>
        </ng-container>

        <ng-container matColumnDef="action-buttons">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let robot">
            <div class="action-buttons-container">
              <a
                mat-icon-button
                color="primary"
                aria-label="Go to robot log"
                matTooltip="Robot Log"
                matTooltipPosition="above"
                [href]="getElasticRobotLogLink(robot.serialNumber)"
              >
                <mat-icon>list</mat-icon>
              </a>
              <button
                mat-icon-button
                color="primary"
                aria-label="Access robot blackbox"
                (click)="showRobotBlackbox(robot)"
              >
                <mat-icon>movie</mat-icon>
              </button>
              <button
                mat-icon-button
                color="primary"
                aria-label="Edit robot"
                (click)="editRobot(robot)"
              >
                <mat-icon>edit</mat-icon>
              </button>

              <button
                mat-icon-button
                aria-label="Open claim robot dialog"
                [matTooltip]="
                  robot.isClaimed
                    ? 'Check who claims the robot'
                    : 'Claim the robot'
                "
                matTooltipPosition="above"
                (click)="openClaimRobotDialog(robot)"
              >
                <mat-icon
                  [color]="robot.isClaimed === true ? 'accent' : 'primary'"
                  >handyman</mat-icon
                >
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr
          mat-row
          *matRowDef="let robot; columns: columnsToDisplay"
          class="element-row"
          [class.offline]="(robot.updatedAt | prettyTime) !== 'just now'"
        ></tr>
        <!-- Row shown when there is no matching data. -->
        <ng-container matColumnDef="notfound">
          <td mat-footer-cell *matFooterCellDef colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </ng-container>
        <tr
          mat-footer-row
          *matFooterRowDef="['notfound']"
          class="example-second-footer-row"
          [style.display]="
            dataSource.filteredData.length === 0 && dataSource.data.length > 0
              ? 'table-row'
              : 'none'
          "
        ></tr>
      </table>
    </div>
  </div>
</div>
