<div mat-dialog-content>
  <mat-form-field
    appearance="fill"
    class="filter-dropdown"
    style="width: 32rem"
  >
    <mat-label
      >Element ID, Location Name/ID, Address or Latitude,Longitude</mat-label
    >
    <input #location matInput placeholder="Location" />
  </mat-form-field>
  <button
    mat-icon-button
    color="primary"
    aria-label="Go to location"
    (click)="goToLocation()"
    [disabled]="!latLng"
  >
    <mat-icon>location_on</mat-icon>
  </button>
</div>
