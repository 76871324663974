import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CancelOrderDialogData {
  orderId: string;
}

export interface CancelOrderDialogOutput {
  reason?: string;
}

@Component({
  selector: 'app-cancel-order-dialog',
  templateUrl: './cancel-order-dialog.component.html',
  styleUrls: ['./cancel-order-dialog.component.sass'],
})
export class CancelOrderDialogComponent {
  cancelReason = '';
  cancelMessage: string;
  constructor(
    public dialogRef: MatDialogRef<
      CancelOrderDialogComponent,
      CancelOrderDialogOutput
    >,
    @Inject(MAT_DIALOG_DATA) public data: CancelOrderDialogData,
  ) {
    this.cancelMessage = `Enter reason for canceling of the order ${this.data.orderId}`;
  }

  confirmCancel() {
    this.dialogRef.close({ reason: this.cancelReason });
  }

  close() {
    this.dialogRef.close({ reason: undefined });
  }
}
