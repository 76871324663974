import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UnsupervisedAutonomyState } from '../../../../core/robots-service/backend/robot.dto';
import { UnsupervisedAutonomyAbortReportDialog } from './unsupervised-autonomy-abort-report-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-unsupervised-autonomy-interaction',
  templateUrl: './unsupervised-autonomy-interaction.component.html',
  styleUrls: ['./unsupervised-autonomy-interaction.component.sass'],
})
export class UnsupervisedAutonomyInteractionComponent {
  remainingUnsupervisedAutonomyDistanceText: string = '';
  unsupervisedAutonomyAvailable = false;
  unsupervisedAutonomyActive = false;
  unsupervisedAutonomyAbortReport = '';
  unsupervisedAutonomyAbortStamp: Date | undefined = undefined;

  private _state?: UnsupervisedAutonomyState;
  constructor(private dialog: MatDialog) {}

  @Input()
  set state(inputState: UnsupervisedAutonomyState | undefined | null) {
    this._state = inputState ?? undefined;
    if (this._state === undefined) {
      return;
    }
    this.unsupervisedAutonomyAvailable = this._state?.available ?? false;
    this.unsupervisedAutonomyActive = this._state?.active ?? false;
    this.unsupervisedAutonomyAbortReport = this._state.abortReport ?? '';
    this.unsupervisedAutonomyAbortStamp = this._state.abortStamp;
    const remainingDistance = this._state.remainingDistance;
    if (!remainingDistance || remainingDistance < 1 || !this._state.available) {
      this.remainingUnsupervisedAutonomyDistanceText = '';
      return;
    }
    if (remainingDistance > 99) {
      this.remainingUnsupervisedAutonomyDistanceText = `99+ m`;
      return;
    }
    this.remainingUnsupervisedAutonomyDistanceText = `${Math.trunc(
      remainingDistance,
    )}m`;
  }

  get state(): UnsupervisedAutonomyState | undefined | null {
    return this._state;
  }

  @Output() unsupervisedAutonomyAbortReportHandled =
    new EventEmitter<boolean>();

  openReportDialog() {
    if (!this.unsupervisedAutonomyAbortReport) {
      return;
    }
    const handle = this.dialog.open<UnsupervisedAutonomyAbortReportDialog>(
      UnsupervisedAutonomyAbortReportDialog,
      {
        data: {
          report: this.unsupervisedAutonomyAbortReport,
          stamp: this.unsupervisedAutonomyAbortStamp,
        },

        maxHeight: '80vh',
        maxWidth: '40vh',
        position: { top: '100px' },
      },
    );

    handle.afterClosed().subscribe(async (acknowledged) => {
      if (acknowledged) {
        this.unsupervisedAutonomyAbortReportHandled.emit(acknowledged);
      }
    });
  }

  acknowledgeAbortReport() {}
}
