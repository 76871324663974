<div
  class="unsupervised-autonomy-container"
  *ngIf="unsupervisedAutonomyAbortReport"
>
  <div class="robot-icon-background" (click)="openReportDialog()">
    <mat-icon class="report-icon" [class.blinking-border]="true">
      feedback
    </mat-icon>
    <div class="text">Report</div>
  </div>
</div>

<div
  class="unsupervised-autonomy-container"
  *ngIf="unsupervisedAutonomyAvailable && !unsupervisedAutonomyAbortReport"
>
  <div class="robot-icon-background">
    <mat-icon class="robot-icon" [class.blinking-border]="unsupervisedAutonomyActive">
      smart_toy
    </mat-icon>
    <div class="text">
      {{ remainingUnsupervisedAutonomyDistanceText }}
    </div>
  </div>
</div>
