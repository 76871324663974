import { Component, OnDestroy, OnInit } from '@angular/core';
import { BackendService } from '../core/backend.service';
import { visiblePageTimer } from '../../utils/page-visibility';
import {
  Observable,
  Subject,
  firstValueFrom,
  map,
  merge,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { RobotsService } from '../core/robots-service/robots.service';
import { RobotDto } from '../core/robots-service/backend/robot.dto';
import { RobotCommunication } from '../core/robots-service/robot-communication';
import { intersectArrays } from '../../utils/intersect-arrays';
import { Operation } from '../operations/operation';
import { CrossingMonitoringLocalStateService } from './crossing-monitoring-local-state.service';
import { millisBetween } from '../../utils/millis-between';

const ROBOT_POLLING_INTERVAL_MILLIS = 10000;

@Component({
  selector: 'app-robot-operator-overview',
  templateUrl: './crossing-monitoring.component.html',
  styleUrls: ['./crossing-monitoring.component.sass'],
})
export class CrossingMonitoringComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  private _refetch$ = new Subject<void>();

  selectedOperationIds =
    this.crossingMonitoringLocalStateService.getSelectedOperationIds();

  operationIds!: Observable<string[]>;
  robotsCommunicationsOnCrossing: RobotCommunication[] = [];

  standingDuration: number | null =
    this.crossingMonitoringLocalStateService.getStandingDurationThreshold();

  constructor(
    private backendService: BackendService,
    private robotsService: RobotsService,
    private crossingMonitoringLocalStateService: CrossingMonitoringLocalStateService,
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnInit(): void {
    merge(visiblePageTimer(0, ROBOT_POLLING_INTERVAL_MILLIS), this._refetch$)
      .pipe(
        takeUntil(this._destroy$),
        switchMap(() => this.getCrossingRobots()),
        map((robots) =>
          robots.filter(
            (robot) =>
              this.standingDuration === null ||
              millisBetween(
                robot.stopState?.stoppedSince !== undefined
                  ? new Date(robot.stopState?.stoppedSince)
                  : new Date(),
                new Date(),
              ) >
                this.standingDuration * 1000,
          ),
        ),
        switchMap((robots) =>
          this.robotsService.getRobotCommunications(
            robots.map((robot) => robot.id),
          ),
        ),
      )
      .subscribe(async (newRobotCommunications) => {
        const [
          robotsLeftCrossing,
          robotsHaveBeenStayingOnCrossing,
          robotsArrivingToCrossing,
        ] = intersectArrays(
          this.robotsCommunicationsOnCrossing,
          newRobotCommunications,
          (robotCommunication1) => (robotCommunication2) =>
            robotCommunication1.robotId === robotCommunication2.robotId,
        );

        this.robotsCommunicationsOnCrossing = [
          ...robotsHaveBeenStayingOnCrossing,
          ...robotsArrivingToCrossing,
        ];

        await Promise.all(robotsLeftCrossing.map((robot) => robot.finalize()));
      });

    this.operationIds = this.backendService
      .get<Operation[]>(`/operations/`)
      .pipe(
        takeUntil(this._destroy$),
        map((operations) => operations.map((operation) => operation.id)),
      );
  }

  trackByRobotId(index: number, robotCommunication: RobotCommunication) {
    return robotCommunication.robotId;
  }

  private async getCrossingRobotsForSelectedOperations(): Promise<RobotDto[]> {
    const robotsPerOperation = await Promise.all(
      this.selectedOperationIds.map((operationId) =>
        firstValueFrom(
          this.backendService.get<RobotDto[]>(
            `/robots?crossing=true&assigned-operation-id=${operationId}`,
          ),
        ),
      ),
    );
    return robotsPerOperation.flat();
  }

  private getCrossingRobots(): Promise<RobotDto[]> {
    return this.selectedOperationIds.length === 0
      ? firstValueFrom(
          this.backendService.get<RobotDto[]>('/robots?crossing=true'),
        )
      : this.getCrossingRobotsForSelectedOperations();
  }

  refetch() {
    this._refetch$.next();
    this.crossingMonitoringLocalStateService.setSelectedOperationIds(
      this.selectedOperationIds,
    );
    this.crossingMonitoringLocalStateService.setStandingDurationThreshold(
      this.standingDuration,
    );
  }

  getStandStillDuration(
    robotCommunication: RobotCommunication,
  ): Observable<number> {
    return robotCommunication.robotState$.pipe(
      map((robotState) =>
        robotState.stopState?.stoppedSince !== undefined
          ? millisBetween(
              new Date(robotState.stopState?.stoppedSince),
              new Date(),
            ) / 1000
          : 0,
      ),
    );
  }
}
