import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { BackendService } from '../../core/backend.service';
import { Operation } from '../../operations/operation';
import {
  ComponentVersion,
  RobotDto,
} from '../../core/robots-service/backend/robot.dto';
import { firstValueFrom, merge, Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { CreateRobotDialogComponent } from '../create-robot-dialog/create-robot-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../core/auth.service';
import { Role } from '../../core/user';
import { EditRobotDialogComponent } from '../edit-robot-dialog/edit-robot-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateRobot } from '../edit-robot-dialog/update-robot';
import { visiblePageTimer } from '../../../utils/page-visibility';
import {
  MassActionData,
  MassActionDialogComponent,
} from './mass-action-dialog.component';
import {
  ConfirmationDialogData,
  ConfirmationDialog,
} from '../../core/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Robot } from '../robot.types';
import { robotStatusFromIssueSeverity } from '../../core/robot-issues/robot-issues.utils';
import { RobotIssueSeverity } from '../../core/robot-issues/robot-issue.types';
import { ClaimRobotDialogComponent } from './claim-robot-dialog/claim-robot-dialog.component';
import { RobotUpdateHistoryDialogComponent } from './robot-update-history-dialog/robot-update-history-dialog.component';

function buildVersionString(version: ComponentVersion): string {
  return `${version.majorVersion ?? '0'}.${version.minorVersion ?? '0'}.${
    version.patchVersion ?? '0'
  }${version.versionAppendix ? '-' : ''}${version.versionAppendix ?? ''}`;
}

function getUpdateIconName(
  robot: RobotDto,
): 'downloading' | 'download_done' | 'error' | undefined {
  if (robot.awxUpdateActive) {
    return 'downloading';
  }
  if (robot.lastAwxUpdateSuccessful === undefined) {
    return undefined;
  }
  return robot.lastAwxUpdateSuccessful ? 'download_done' : 'error';
}

type ChargingFilter = 'charging' | 'not-charging' | '';
type ReadyFilter = 'ready' | 'not-ready' | '';
type OnlineFilter = 'online' | 'offline' | '';
type UpdatingFilter = 'updating' | 'not-updating' | '';

const ERROR_SNACK_BAR_DURATION = 2000;

interface Filter {
  textFilter: string;
  chargingFilter: ChargingFilter;
  onlineFilter: OnlineFilter;
  readyFilter: ReadyFilter;
  updatingFilter: UpdatingFilter;
  operationId: string | undefined;
  severityFilter: RobotIssueSeverity | undefined;
  accessGroupFilter: string | undefined;
}

@Component({
  selector: 'app-robots-view',
  templateUrl: './robots-view.component.html',
  styleUrls: ['./robots-view.component.sass'],
})
export class RobotsViewComponent implements AfterViewInit, OnDestroy {
  isAdmin = false;
  canSuperviseRobots = false;
  dataSource = new MatTableDataSource<Robot>([]);
  private destroyed$ = new Subject<void>();
  operationIds?: string[];
  accessGroups?: string[];
  chargingFilter: ChargingFilter = '';
  onlineFilter: OnlineFilter = '';
  textFilter = '';
  readyFilter: ReadyFilter = '';
  updatingFilter: UpdatingFilter = '';

  severityOptions = Object.values(RobotIssueSeverity);
  selectedSeverityFilter?: RobotIssueSeverity;

  selectedOperationId?: string;
  selectedAccessGroup?: string;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  private refresh$ = new Subject<void>();

  private readonly defaultColumnsToDisplay = [
    'select',
    'picture',
    'robotView',
    'status',
    'batteryPercentage',
    'connectivity',
    'serialNumber',
    'assignedOperationId',
    'readyForOrders',
    'updatedAt',
  ];
  columnsToDisplay = this.defaultColumnsToDisplay;
  selection = new SelectionModel<string>(true, []);

  constructor(
    private backendService: BackendService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private router: Router,
  ) {
    this.auth.user$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      this.isAdmin = !!user && user.roles.includes(Role.ADMIN);
      this.canSuperviseRobots =
        this.isAdmin || (!!user && user.roles.includes(Role.ROBOT_OPERATOR));

      if (this.isAdmin) {
        this.columnsToDisplay = [
          ...this.defaultColumnsToDisplay,
          'awxUpdate',
          'containerVersions',
          'action-buttons',
        ];
      } else {
        this.columnsToDisplay = this.defaultColumnsToDisplay;
      }
    });
    this.backendService
      .get<Operation[]>(`/operations/`)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((operations) => {
        this.operationIds = operations.map((operation) => operation.id);
      });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    const matches = (value: string | undefined, filter: string): boolean =>
      !!value && value.trim().toLowerCase().includes(filter);
    this.dataSource.filterPredicate = (
      robot: Robot,
      filterJson: string,
    ): boolean => {
      const filter = JSON.parse(filterJson) as Filter;
      const textFilterMatch =
        matches(`${robot.serialNumber}`, filter.textFilter) ||
        matches(robot.shortName, filter.textFilter) ||
        matches(robot.assignedOperationId, filter.textFilter) ||
        robot.containerVersionStrings.some((containerVersion) =>
          matches(containerVersion, filter.textFilter),
        );
      const chargeFilterMatch =
        !filter.chargingFilter ||
        (filter.chargingFilter === 'charging') === robot.isCharging;
      const robotOnline =
        Date.now() - (robot.updatedAt?.getTime() ?? 0) < 30000;
      const onlineFilterMatch =
        !filter.onlineFilter ||
        (filter.onlineFilter === 'online') === robotOnline;
      const isRobotReady: ReadyFilter =
        robot.readyForOrders === true ? 'ready' : 'not-ready';
      const isReadyFilterMatch =
        !filter.readyFilter || isRobotReady === filter.readyFilter;
      const isUpdating: UpdatingFilter =
        robot.awxUpdateActive === true ? 'updating' : 'not-updating';
      const isUpdatingFilterMatch =
        !filter.updatingFilter || isUpdating === filter.updatingFilter;
      const isOperationIdMatch =
        !filter.operationId || robot.assignedOperationId === filter.operationId;
      const isAccessGroupMatch =
        !filter.accessGroupFilter ||
        (robot.accessGroups ?? []).includes(filter.accessGroupFilter);
      const isSeverityMatch =
        !filter.severityFilter ||
        robot.highestIssueSeverity === filter.severityFilter;

      return (
        textFilterMatch &&
        chargeFilterMatch &&
        onlineFilterMatch &&
        isReadyFilterMatch &&
        isUpdatingFilterMatch &&
        isOperationIdMatch &&
        isAccessGroupMatch &&
        isSeverityMatch
      );
    };

    merge(visiblePageTimer(0, 10000), this.refresh$)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async () => await this.pullData());
  }

  ngOnDestroy() {
    this.destroyed$.next(undefined);
  }

  async pullData() {
    const robots = await firstValueFrom(
      this.backendService.get<RobotDto[]>(`/robots/`),
    );

    // Sort the currently online robots by serial number to stop constant
    // reordering. This is done by assigning "now" - displayName-sorted
    // array index position to updatedAt.
    robots.sort((a, b) => a.serialNumber - b.serialNumber);

    const accessGroupSet = new Set<string>(
      robots.flatMap((robot) => robot.accessGroups ?? ['']),
    );
    this.accessGroups = Array.from(accessGroupSet.values());

    const now = new Date();
    this.dataSource.data = robots.map((robot, index) => {
      let updatedAt = new Date(robot.updatedAt ?? '1970-1-1');
      if (now.getTime() - updatedAt.getTime() < 40000) {
        updatedAt = new Date(now.getTime() - index);
      }
      const autonomyEnabledUntil = robot.autonomyEnabledUntil
        ? new Date(robot.autonomyEnabledUntil)
        : undefined;
      return {
        ...robot,
        updatedAt,
        autonomyEnabledUntil,
        containerVersionStrings: this.getContainerVersionsString(
          robot.componentVersions,
        ),
        isClaimed:
          robot.userClaim &&
          robot.userClaim.claimedBy &&
          (!robot.userClaim.claimedUntil ||
            new Date(robot.userClaim.claimedUntil) > new Date()),
        updateIcon: getUpdateIconName(robot),
      };
    });

    const operations = await firstValueFrom(
      this.backendService.get<Operation[]>(`/operations/`),
    );

    this.operationIds = operations
      .map((operation) => operation.id)
      .filter(
        (operationId) =>
          robots.findIndex(
            (robot) => robot.assignedOperationId === operationId,
          ) !== -1,
      );
  }

  transform(image: Buffer) {
    return this.sanitizer.bypassSecurityTrustUrl(
      'data:image/jpeg;base64,' + image.toString('base64'),
    );
  }

  pushRobotChanges(robotId: string, updateRobot: UpdateRobot) {
    this.backendService
      .patch(`/robots/${robotId}`, updateRobot)
      .subscribe(() => this.pullData());
  }

  createRobot(): void {
    this.dialog
      .open(CreateRobotDialogComponent, {
        data: {
          type: 'cart',
        },
      })
      .afterClosed()
      .subscribe(async (newRobot?: Promise<Robot>) => {
        if (!newRobot) return;
        await newRobot;
        this.pullData();
      });
  }

  createVirtualRobot(): void {
    this.dialog
      .open(CreateRobotDialogComponent, {
        data: {
          type: 'virtual',
        },
      })
      .afterClosed()
      .subscribe(async (newRobot?: Promise<Robot>) => {
        if (!newRobot) return;
        await newRobot;
        this.pullData();
      });
  }

  editRobot(robot: RobotDto) {
    this.backendService
      .get(`/operations/`)
      .subscribe((operations: Operation[]) => {
        const robotCopy = Object.assign({}, robot);
        const editRobotDialogRef = this.dialog.open(EditRobotDialogComponent, {
          minWidth: '80vh',
          data: { robot: robotCopy, operations },
        });
        editRobotDialogRef
          .afterClosed()
          .subscribe((updateRobot?: UpdateRobot) => {
            if (!updateRobot) {
              this.pullData();
              return;
            }
            this.pushRobotChanges(robot.id, updateRobot);
          });
      });
  }

  showRobotBlackbox(robot: RobotDto) {
    this.router.navigate(['robots/blackbox', robot.id]);
  }

  isAllFilteredSelected() {
    if (!this.dataSource.filteredData.length) {
      return false;
    }
    return this.dataSource.filteredData.every((robot) =>
      this.selection.selected.includes(robot.id),
    );
  }

  filteredAndSelectedRobot(): Robot[] {
    const selectedRobots = new Set(this.selection.selected);
    return this.dataSource.filteredData.filter((robot) => {
      return selectedRobots.has(robot.id);
    });
  }

  toggleFilteredSelection() {
    const allFilteredSelected = this.isAllFilteredSelected();
    this.selection.clear();

    if (allFilteredSelected) {
      return;
    }
    this.selection.select(
      ...this.dataSource.filteredData.map((robot) => robot.id),
    );
  }

  superviseRobots() {
    const robotIds = this.filteredAndSelectedRobot()
      .map((robot) => robot.id)
      .join(',');
    this.router.navigate(['/robots/supervise/', robotIds]);
  }

  applyFilter() {
    const filter: Filter = {
      textFilter: this.textFilter.trim().toLowerCase(),
      chargingFilter: this.chargingFilter,
      onlineFilter: this.onlineFilter,
      readyFilter: this.readyFilter,
      updatingFilter: this.updatingFilter,
      operationId: this.selectedOperationId,
      accessGroupFilter: this.selectedAccessGroup,
      severityFilter: this.selectedSeverityFilter,
    };
    this.dataSource.filter = JSON.stringify(filter);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  extractRobotStatus(robot: Robot): string {
    return robotStatusFromIssueSeverity(robot.highestIssueSeverity);
  }

  getFrontendVersionUrlPart(): string {
    const currentUrl = document.location.href;

    if (currentUrl.includes('staging-ops')) {
      return 'staging-';
    } else if (currentUrl.includes('sandbox-ops')) {
      return 'sandbox-';
    } else {
      return '';
    }
  }

  openMassAction(): void {
    this.dialog.open<MassActionDialogComponent, MassActionData>(
      MassActionDialogComponent,
      {
        data: {
          selectedRobots: this.filteredAndSelectedRobot(),
          updateState: () => {
            this.refresh$.next(undefined);
          },
        },
      },
    );
  }

  toggleRobotReadyForOrder(robot: Robot) {
    const isReady = !robot.readyForOrders;
    const robotName = robot.serialNumber;
    const confirmationDialogMessage = isReady
      ? `Set robot ${robotName} ready for order`
      : `Set robot ${robotName} not ready for order`;

    this.dialog
      .open<ConfirmationDialog, ConfirmationDialogData>(ConfirmationDialog, {
        data: {
          message: confirmationDialogMessage,
        },
      })
      .afterClosed()
      .subscribe(async (isConfirmed) => {
        if (isConfirmed === true) {
          try {
            await this.backendService
              .post(`/robots/${robot.id}/set-ready-for-orders`, {
                readyForOrders: isReady,
              })
              .toPromise();
          } catch (e) {
            const errorMessage = `Failed to set ${robotName} ready for orders state to '${isReady}'`;
            console.error(errorMessage, e);
            this.snackBar.open(errorMessage, undefined, {
              duration: ERROR_SNACK_BAR_DURATION,
            });
            this.refresh$.next(undefined);
          }
        } else {
          this.refresh$.next(undefined);
        }
      });
  }

  openClaimRobotDialog(robot: Robot) {
    const handle = this.dialog.open<ClaimRobotDialogComponent>(
      ClaimRobotDialogComponent,
      {
        data: {
          robot,
        },
      },
    );

    handle.afterClosed().subscribe(async () => {
      this.refresh$.next(undefined);
    });
  }

  openRobotAwxUpdateHistory(robot: Robot) {
    const handle = this.dialog.open<RobotUpdateHistoryDialogComponent>(
      RobotUpdateHistoryDialogComponent,
      {
        data: {
          robotId: robot.id,
        },

        autoFocus: false,
        height: '80vh',
        maxHeight: '80vh',
      },
    );

    handle.afterClosed().subscribe(async () => {
      this.refresh$.next(undefined);
    });
  }

  getContainerVersionsString(componentVersions?: ComponentVersion[]): string[] {
    const containerVersions =
      componentVersions?.filter((componentVersion) =>
        componentVersion.componentName?.includes('container'),
      ) ?? [];

    const containerVersionsToComponentNamesMap = new Map<string, string[]>();
    for (const containerVersion of containerVersions) {
      const versionString = buildVersionString(containerVersion);

      const componentNamesForVersion =
        containerVersionsToComponentNamesMap.get(versionString);
      if (componentNamesForVersion !== undefined) {
        componentNamesForVersion.push(containerVersion.componentName ?? '');
      } else {
        containerVersionsToComponentNamesMap.set(versionString, [
          containerVersion.componentName ?? '',
        ]);
      }
    }

    // Only visualize version if:
    // - more than two different container versions have been reported
    // - all of the containers are using the same version
    // - no devel container is running
    if (containerVersionsToComponentNamesMap.size === 1) {
      const [containerComponentNames] =
        containerVersionsToComponentNamesMap.values();
      if (
        containerComponentNames &&
        !containerComponentNames.some((name) => name.includes('devel'))
      ) {
        return Array.from(containerVersionsToComponentNamesMap.keys()); //only one entry
      }
    }

    const containerVersionStrings: string[] = [];
    Array.from(containerVersionsToComponentNamesMap.entries()).forEach(
      ([versionString, componentNames]) =>
        componentNames.forEach((componentName) =>
          containerVersionStrings.push(
            `${componentName ?? ''}: ${versionString ?? ''}`,
          ),
        ),
    );
    return containerVersionStrings.sort((a, b) => a.localeCompare(b));
  }

  getElasticRobotLogLink(serialNumber: number) {
    const displayNameUrl = `%20%22Cart%20${serialNumber}%22`;
    return `https://robot-logs.kb.europe-west3.gcp.cloud.es.io:9243/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15m,to:now))&_a=(columns:!(),filters:!(),index:'23c15690-cfca-11ed-8cb8-0dc8a99b65a1',interval:auto,query:(language:kuery,query:'robotDisplayName.keyword%20:%20${displayNameUrl}'),sort:!(!('@timestamp',desc)))`;
  }
}
