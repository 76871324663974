import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  firstValueFrom,
  identity,
  merge,
  of,
} from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { RobotCommunication } from '../core/robots-service/robot-communication';
import { RobotsService } from '../core/robots-service/robots.service';
import { EnableExtraSlotsService } from './enable-extra-slots.service';
import {
  RobotSlotsConfig,
  SupervisionSettingsService,
} from './supervision-settings/supervision-settings.service';
import {
  applyRobotSlotsConfig,
  createEmptySlot,
  disabledSlot,
  RobotSlotCell,
  RobotSlots,
} from './supervision-slot';
import { UserSessionService } from '../core/user-session/user-session.service';
import { UserSessionEventTrackingService } from '../core/user-session/user-session-event-tracking.service';
import { UserSessionSystemEventName } from '../core/user-session/user-session-system-events';

const FAILED_SUBSEQUENT_CONNECTION_ATTEMPTS_COUNT_THRESHOLD = 5;

@Injectable()
export class SupervisedRobotSlotsConfigService implements OnDestroy {
  private readonly _destroy = new Subject<void>();

  private _robotSlots$ = new BehaviorSubject<RobotSlots>([
    disabledSlot,
    disabledSlot,
    disabledSlot,
    disabledSlot,
  ]);

  robotSlots$: Observable<RobotSlots> = this._robotSlots$.asObservable();

  constructor(
    private readonly supervisionSettingsService: SupervisionSettingsService,
    private readonly userSessionService: UserSessionService,
    private readonly robotService: RobotsService,
    private readonly enableExtraSlotsService: EnableExtraSlotsService,
    private readonly userSessionEventTrackingService: UserSessionEventTrackingService,
  ) {
    const robotCommunications$ = merge(
      of([] as RobotCommunication[]),
      this.userSessionService.assignedRobots$.pipe(
        filter(() => this.isPollingForRobots),
        switchMap((robotIds) =>
          this.robotService.getRobotCommunications(robotIds),
        ),
      ),
    );

    combineLatest([
      supervisionSettingsService.robotSlots$,
      robotCommunications$,
    ]).subscribe(async ([robotSlotsConfig, robotCommunications]) => {
      await this.updateRobotSlots(robotSlotsConfig, robotCommunications);
    });

    this._robotSlots$.pipe(takeUntil(this._destroy)).subscribe((robotSlots) => {
      this.enableExtraSlotsService.updateRobotSlots(robotSlots);
    });
  }

  private async updateRobotSlots(
    robotSlotsConfig: RobotSlotsConfig,
    robotCommunications: RobotCommunication[],
  ) {
    const robotSlots = applyRobotSlotsConfig(
      this._robotSlots$.getValue(),
      robotSlotsConfig,
    );
    const existingRobotCommunications = robotSlots.flatMap((slot) =>
      slot.slotType === 'taken' ? [slot.robotCommunication] : [],
    );
    const expectedRobotIds = robotCommunications.map(
      (robotCommunication) => robotCommunication.robotId,
    );
    const existingRobotIds = existingRobotCommunications.map(
      (robotCommunication) => robotCommunication.robotId,
    );

    const robotSlotsWithNewRobots = this.addNewRobots(
      robotCommunications,
      expectedRobotIds,
      existingRobotIds,
      robotSlots,
    );

    this.userSessionService.acknowledgeRobots(
      robotCommunications.map(({ robotId }) => robotId),
    );

    this._robotSlots$.next(robotSlotsWithNewRobots);

    this.finalizeAllStaleRobots(robotSlotsWithNewRobots as RobotSlots);
  }

  private addNewRobots(
    robotCommunications: RobotCommunication[],
    expectedRobotIds: string[],
    existingRobotIds: string[],
    robotSlots: RobotSlots,
  ): RobotSlots {
    const updatedRobotSlots = [...robotSlots];
    const newRobotCommunication = robotCommunications.filter(
      (robotCommunication) =>
        !existingRobotIds.includes(robotCommunication.robotId),
    );

    for (const robotCommunication of newRobotCommunication) {
      this.userSessionEventTrackingService.trackSystemEvent(
        UserSessionSystemEventName.ROBOT_ASSIGNED,
        { robotId: robotCommunication.robotId },
      );
      this.subscribeRobotCommunicationFinalizationHandler(robotCommunication);
      const emptySlotIndex = updatedRobotSlots.findIndex((slot) => {
        const slotIsEmpty = slot.slotType === 'empty';
        const slotIsTakeByOldRobot =
          slot.slotType === 'taken' &&
          !expectedRobotIds.includes(slot.robotCommunication.robotId);
        return slotIsTakeByOldRobot || slotIsEmpty;
      });
      if (emptySlotIndex === -1) {
        break;
      }
      const newRobotSlot: RobotSlotCell = {
        slotType: 'taken',
        robotCommunication: robotCommunication,
      };
      firstValueFrom(robotCommunication.connected$.pipe(filter(identity))).then(
        () => {
          this.userSessionService.robotConnectionEstablished(
            robotCommunication.robotId,
          );
        },
      );
      updatedRobotSlots[emptySlotIndex] = newRobotSlot;
    }
    return updatedRobotSlots as RobotSlots;
  }

  async ngOnDestroy(): Promise<void> {
    this._destroy.next(undefined);
    await this.stopSupervision();
  }

  private isPollingForRobots = false;

  startSupervision() {
    this.isPollingForRobots = true;
  }

  async stopSupervision(...keepRobotCommunications: RobotCommunication[]) {
    this.isPollingForRobots = false;

    this.robotService.finalizeRobots(...keepRobotCommunications);
    const robotIdsToRetain = keepRobotCommunications.map(
      (robotCommunication) => robotCommunication.robotId,
    );
    const robotSlots = this._robotSlots$.getValue().map((slot) => {
      return slot.slotType !== 'taken' ||
        robotIdsToRetain.includes(slot.robotCommunication.robotId)
        ? slot
        : createEmptySlot();
    }) as RobotSlots;
    this._robotSlots$.next(robotSlots);
  }

  subscribeRobotCommunicationFinalizationHandler(
    robotCommunication: RobotCommunication,
  ) {
    robotCommunication.finalized$.subscribe(async () => {
      this.userSessionEventTrackingService.trackSystemEvent(
        UserSessionSystemEventName.ROBOT_FINALIZED,
        { robotId: robotCommunication.robotId },
      );
      const robotSlots = this._robotSlots$.getValue();
      const robotSlotIndex = robotSlots.findIndex(
        (slot) =>
          slot.slotType === 'taken' &&
          slot.robotCommunication === robotCommunication,
      );
      const currentRobotSlot = robotSlots[robotSlotIndex];
      if (
        currentRobotSlot?.slotType === 'taken' &&
        currentRobotSlot.robotCommunication === robotCommunication
      ) {
        const newRobotSlots: RobotSlots = [...robotSlots];
        const robotSlotConfig = await firstValueFrom(
          this.supervisionSettingsService.robotSlots$,
        );
        const currentSlotConfig = robotSlotConfig[robotSlotIndex];
        newRobotSlots[robotSlotIndex] = currentSlotConfig
          ? createEmptySlot()
          : disabledSlot;
        this._robotSlots$.next(newRobotSlots);
      }
      this.userSessionService.unassignRobots(
        [robotCommunication.robotId],
        false,
      );
    });
    robotCommunication.failedSubsequentConnectionAttemptsCount$
      .pipe(takeUntil(this._destroy))
      .subscribe((failedSubsequentConnectionAttemptsCount) => {
        if (
          failedSubsequentConnectionAttemptsCount >=
          FAILED_SUBSEQUENT_CONNECTION_ATTEMPTS_COUNT_THRESHOLD
        ) {
          robotCommunication.finalize();
        }
      });
  }

  private finalizeAllStaleRobots(robotSlots: RobotSlots) {
    const robotCommunications = robotSlots.flatMap((slot) =>
      slot.slotType === 'taken' ? [slot.robotCommunication] : [],
    );
    this.robotService.finalizeRobots(...robotCommunications);
  }
}
