import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackendService } from '../../core/backend.service';
import { Role, User } from '../../core/user';
import { UserUpdate } from '../user-update.dto';

type EditUserDialogData = {
  user: User;
  userUpdate: UserUpdate;
};

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.sass'],
})
export class EditUserDialogComponent {
  allOperationAccessGroups: string[] = [];
  allRoles = Object.values(Role);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditUserDialogData,
    private backendService: BackendService,
  ) {
    data.userUpdate = {};
    this.backendService
      .get<string[]>('/operations/access-groups')
      .subscribe((accessGroups: string[]) => {
        this.allOperationAccessGroups = accessGroups;
      });
  }
}
