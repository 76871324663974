import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { WebRtcManager } from './webrtc-manager';
import { LatencyWatchDog } from './latency-watch-dog';

import {
  RobotSessionId,
  UserEventService,
} from '../../user-session/user-events.service';
import { RtcStreamManager } from './rtc-stream-manager';
import { Finalizable } from '../../../../utils/finalizable';
import { resolveWithTimeout } from '../../../../utils/resolve-with-timeout';
import { completeAll } from '../../../../utils/complete-all';
import { SignalingConnection } from './signaling-connection';
import { RtcNetworkSelectionManager } from './rtc-network-selection-manager';
import { RtcNetworkInterfaces } from './rtc-network-interfaces';
import { UserSessionEventTrackingService } from '../../user-session/user-session-event-tracking.service';
import { UserSessionSystemEventName } from '../../user-session/user-session-system-events';
import { StopWatch } from '../../../../utils/stop-watch';

const DEFAULT_CONNECTION_TIMEOUT = 3000;
const EXTENDED_CONNECTION_TIMEOUT = 7000;
const MAX_LATENCY_MILLIS = 1500;
const MAX_PING_TIMEOUT_MILLIS = 3000;
const LATENCY_WATCHDOG_START_TIMEOUT = 5000;
const LATENCY_WATCHDOG_TRIGGERS_IN_ROW_COUNT_COOL_DOWN_DURATION_MILLIS =
  1000 * 60;
const BAD_LATENCY_COUNT_CONNECTION_SWITCH_THRESHOLD = 1;

export class RobotRtcConnectionManager extends Finalizable {
  private readonly _connected$ = new BehaviorSubject<boolean>(false);
  readonly connected$ = this._connected$.asObservable();

  private readonly latencyWatchDog: LatencyWatchDog;
  private readonly rtcNetworkSelectionManager: RtcNetworkSelectionManager;

  private isConnecting = false;

  private connectionStopWatch = new StopWatch(true);

  constructor(
    private readonly robotSession: RobotSessionId,
    private readonly rtcStreams: RtcStreamManager,
    private readonly userSessionEventTrackingService: UserSessionEventTrackingService,
    private readonly webRtcManager: WebRtcManager,
    private readonly signalingConnection: SignalingConnection,
    rtcNetworkInterfaces: RtcNetworkInterfaces,
  ) {
    super();

    this.rtcNetworkSelectionManager = new RtcNetworkSelectionManager(
      rtcNetworkInterfaces,
    );
    this.latencyWatchDog = new LatencyWatchDog(
      this.robotSession,
      this.rtcStreams.latency$,
      MAX_LATENCY_MILLIS,
      MAX_PING_TIMEOUT_MILLIS,
      async (triggerInRowCount) => {
        console.error(
          'LATENCY IS TOO HIGH',
          triggerInRowCount,
          this.robotSession.robotId,
        );
        this.userSessionEventTrackingService.trackSystemEvent(
          UserSessionSystemEventName.LATENCY_WATCHDOG_TRIGGERED,
          {
            robotId: this.robotSession.robotId,
            triggerInRowCount,
          },
        );
        this._connected$.next(false);
        if (
          triggerInRowCount >= BAD_LATENCY_COUNT_CONNECTION_SWITCH_THRESHOLD
        ) {
          await this.changeConnection();
        } else {
          await this.connect();
        }
      },
      LATENCY_WATCHDOG_TRIGGERS_IN_ROW_COUNT_COOL_DOWN_DURATION_MILLIS,
    );

    this.connect();
  }

  private async changeConnection() {
    const bannedInterface =
      await this.rtcNetworkSelectionManager.banSelectedInterface();
    const bestNetworkInterface =
      this.rtcNetworkSelectionManager.getBestInterface();
    this.userSessionEventTrackingService.trackSystemEvent(
      UserSessionSystemEventName.BAN_NETWORK_INTERFACE,
      {
        oldNetworkInterface: bannedInterface,
        newNetworkInterface: bestNetworkInterface,
        robotId: this.robotSession.robotId,
      },
    );
    await this.selectNetworkInterface(bestNetworkInterface);
  }

  private computeLatencyWatchDogStartTimeout(connectTimeoutMillis: number) {
    return LATENCY_WATCHDOG_START_TIMEOUT + connectTimeoutMillis * 3;
  }

  private readonly _failedSubsequentConnectionAttemptsCount$ =
    new BehaviorSubject<number>(0);

  readonly failedSubsequentConnectionAttemptsCount$ =
    this._failedSubsequentConnectionAttemptsCount$.asObservable();

  private selectConnectTimeoutMillis() {
    return this._failedSubsequentConnectionAttemptsCount$.value === 0
      ? DEFAULT_CONNECTION_TIMEOUT
      : EXTENDED_CONNECTION_TIMEOUT;
  }

  async connect(forceConnectionSwitch = false) {
    if (this.finalized || this.isConnecting) {
      return;
    }
    this.isConnecting = true;
    const connectTimeoutMillis = this.selectConnectTimeoutMillis();
    this.userSessionEventTrackingService.trackSystemEvent(
      UserSessionSystemEventName.WEB_RTC_CONNECTION_STARTED,
      { robotId: this.robotSession.robotId },
    );
    this.connectionStopWatch.start();
    const latencyWatchDogStartTimeout =
      this.computeLatencyWatchDogStartTimeout(connectTimeoutMillis);
    // make sure that latency watch dog is started no matter what
    this.latencyWatchDog.start(latencyWatchDogStartTimeout);
    this._connected$.next(false);
    try {
      await resolveWithTimeout(
        this.webRtcManager.connect(forceConnectionSwitch),
        connectTimeoutMillis,
        'WebRTC connection timeout',
      );
      console.log('WebRTC connection started', this.robotSession.robotId);
      await resolveWithTimeout(
        this.webRtcManager.waitCompleteSignalingOffer(),
        connectTimeoutMillis,
        'Signaling completion timeout',
      );
      console.log('Signalling established', this.robotSession.robotId);
      await resolveWithTimeout(
        this.rtcStreams.latency$.pipe(take(3)).toPromise(),
        connectTimeoutMillis,
        'Latency measurement connection timeout',
      );
      console.log('Latency received', this.robotSession.robotId);
      this.userSessionEventTrackingService.trackSystemEvent(
        UserSessionSystemEventName.WEB_RTC_CONNECTED,
        {
          connectionDurationSeconds:
            (this.connectionStopWatch.getMillisAndStop() ?? 0) / 1000,
          robotId: this.robotSession.robotId,
        },
      );

      this._connected$.next(true);
      this._failedSubsequentConnectionAttemptsCount$.next(0);

      // start latency watch dog earlier
      this.latencyWatchDog.start(LATENCY_WATCHDOG_START_TIMEOUT);
    } catch (e) {
      console.error('Failed to connect', e);
      this._connected$.next(false);
      const connectionAttemptCount =
        this._failedSubsequentConnectionAttemptsCount$.value;

      this.userSessionEventTrackingService.trackSystemEvent(
        UserSessionSystemEventName.WEB_RTC_CONNECTION_FAILED,
        {
          connectionDurationSeconds:
            this.connectionStopWatch.getMillisAndStop() ?? 0 / 1000,
          robotId: this.robotSession.robotId,
          errorMessage:
            typeof e === 'object' && e && 'message' in e
              ? (e['message'] as string)
              : 'Unknown error',
          connectionAttemptCount,
        },
      );
      this._failedSubsequentConnectionAttemptsCount$.next(
        this._failedSubsequentConnectionAttemptsCount$.value + 1,
      );
      // setTimeout is necessary to due to recursive call
      setTimeout(async () => {
        switch (connectionAttemptCount % 3) {
          case 0:
            return await this.connect();
          case 1:
            return await this.changeConnection();
          case 2:
            return await this.resetConnection();
        }
      });
    }
    this.isConnecting = false;
  }

  async selectNetworkInterface(selectedNetworkInterface: string) {
    this.signalingConnection.selectNetworkInterface(selectedNetworkInterface);
    if (navigator.userAgent.indexOf('Chrome') !== -1) {
      this.webRtcManager.close(); // Hack as Chrome needs reset
    }
    await this.connect();
  }

  private async resetConnection() {
    this.webRtcManager.close();
    await this.signalingConnection.reconnect();
    await this.connect();
  }

  protected async onFinalize(): Promise<void> {
    completeAll(this._connected$);
    this.latencyWatchDog.stop();
    this._failedSubsequentConnectionAttemptsCount$.complete();
  }
}
