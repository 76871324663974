import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { visiblePageTimer } from '../../utils/page-visibility';

@Injectable({
  providedIn: 'root',
})
export class ServerUpdatesService {
  constructor(private serverUpdates: SwUpdate, private snackbar: MatSnackBar) {}

  checkForUpdates() {
    if (!this.serverUpdates.isEnabled) {
      console.info('software updates not enabled');
      return;
    }
    // every 10 minutes
    visiblePageTimer(0, 1000 * 10 * 60).subscribe(() => {
      this.serverUpdates.checkForUpdate();
    });

    this.serverUpdates.available.subscribe(() => {
      this.serverUpdates.activateUpdate().then(() => {
        const snackBar = this.snackbar.open('Update Available', 'Reload');
        snackBar
          .onAction()
          .pipe(take(1))
          .subscribe(() => {
            document.location.reload(
              // @ts-ignore for Firefox only, otherwise ignored
              true,
            );
          });
      });
    });
  }
}
