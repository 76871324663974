import { Order, OrderStatus } from '../../../app/core/order/order';
import { filterOnlineRobots } from '../../../app/robots/robot-operation/robot-operator-view/robot-quick-add-dialog.component';
import {
  HazardLightsState,
  Compartment,
  RobotDto,
} from '../../core/robots-service/backend/robot.dto';

export class RobotInfo {
  private readonly robotIcon = {
    url: 'assets/robot.png', // url
    scaledSize: new google.maps.Size(40, 40), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(0, 0), // anchor
    labelOrigin: new google.maps.Point(50, 15),
  };
  isOnline!: boolean;
  robotId: string;
  robotMapMarker: google.maps.Marker;
  batteryPercentage!: number;
  isCharging!: boolean;
  hasOrders = false;
  name!: string;
  robotReadyForOrders!: boolean;
  displayColor!: string;
  handoverInProgress!: boolean;
  hazardLightsState!: HazardLightsState;
  isVirtualRobot?: boolean;
  isWaitingForHandover = false;
  compartments: Compartment[];

  constructor(robot: RobotDto, map: google.maps.Map) {
    this.robotId = robot.id;
    this.robotMapMarker = new google.maps.Marker({
      map,
      label: {
        text: robot.shortName!,
        fontSize: '20px',
        fontWeight: 'bold',
      },
    });
    this.compartments = robot.compartments ?? [];
    this.updateRobot(robot);
  }

  updateRobot(robot: RobotDto, orders: Order[] = []) {
    this.robotMapMarker.setVisible(robot.readyForOrders || false);
    this.robotMapMarker.setPosition(
      new google.maps.LatLng(robot.location.latitude, robot.location.longitude),
    );

    this.robotMapMarker.setIcon(this.robotIcon);
    this.name = robot.shortName ?? 'Nobody';
    this.hazardLightsState = robot.hazardLightsState || HazardLightsState.AUTO;
    this.isVirtualRobot = robot.isVirtualRobot;
    this.batteryPercentage = robot.batteryPercentage ?? 0;
    this.isCharging = robot.isCharging ?? false;
    this.robotReadyForOrders = !!robot.readyForOrders;

    const ordersForThisRobot = orders.filter(
      (order) => order.assignedRobotId === this.robotId,
    );
    this.hasOrders = robot.assignedOrderIds.length > 0;
    this.isWaitingForHandover = ordersForThisRobot.some(
      (order) => order.status === OrderStatus.WAITING_FOR_HANDOVER,
    );

    this.isOnline = filterOnlineRobots([robot]).includes(robot);
    this.compartments = robot.compartments ?? [];
  }
}
