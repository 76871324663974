import { Injectable } from '@angular/core';
import { MlBackendService } from '../core/ml-backend.service';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { CollectionDto, LabelTypeDto } from './ml-data-types';
import { HttpErrorResponse } from '@angular/common/http';

export type CreateCollectionDatasetSyncConfigParams = {
  datasetName: string;
  collectionId: number;
  labelTypeId: number;
};

function addBodyErrorToMessage(error: HttpErrorResponse): Observable<never> {
  return throwError(() => {
    if (typeof error.error == 'object') {
      if ('error' in error.error) {
        const newMessage = error.message + ` Err: '${error.error.error}'`;
        (error.message as any) = newMessage;
      }
    }
    return error;
  });
}

@Injectable()
export class MlActionService {
  constructor(private mlBackendService: MlBackendService) {}

  createCollections(collectionName: string): Promise<CollectionDto> {
    const url = `/collections`;

    return firstValueFrom(
      this.mlBackendService.post(
        url,
        { name: collectionName },
        addBodyErrorToMessage,
      ),
    );
  }

  deleteSegmentsAiSyncConfig(datasetConfigId: number): Promise<void> {
    const url = `/labeling-providers/segments-ai/sync-configs/${datasetConfigId}`;

    return firstValueFrom(
      this.mlBackendService.delete(url, addBodyErrorToMessage),
    );
  }

  createSegmentsAiSyncConfig(
    createCollectionDatasetSyncConfigParams: CreateCollectionDatasetSyncConfigParams,
  ) {
    const url = `/labeling-providers/segments-ai/sync-configs`;

    return firstValueFrom(
      this.mlBackendService.post(
        url,
        createCollectionDatasetSyncConfigParams,
        addBodyErrorToMessage,
      ),
    );
  }

  addLabelType(name: string): Promise<LabelTypeDto> {
    const url = `/label-types`;

    return firstValueFrom(
      this.mlBackendService.post(url, { name }, addBodyErrorToMessage),
    );
  }

  addFrameToCollection(collectionId: number, frameId: number): Promise<void> {
    const url = `/collections/${collectionId}/frames/${frameId}`;
    return firstValueFrom(
      this.mlBackendService.post(url, {}, addBodyErrorToMessage),
    );
  }
}
