import { combineLatest, fromEvent, interval, merge, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

// Shorten an interval to get value check more frequently
const ACCURACY_CHECK_INTERVAL_FACTOR = 0.45;

export function detectInactivity(activityDeadlineMs: number) {
  const lastActionDate = merge(
    of({}),
    fromEvent(window, 'keyup'),
    fromEvent(window, 'mousemove'),
  ).pipe(
    map(() => {
      return Date.now();
    }),
  );
  const checkInInterval = interval(
    activityDeadlineMs * ACCURACY_CHECK_INTERVAL_FACTOR,
  );

  return combineLatest([lastActionDate, checkInInterval]).pipe(
    map(([lastActionDate]) => {
      const nowTimestampMs = Date.now();
      return nowTimestampMs - lastActionDate;
    }),
    filter((inactiveForMs) => inactiveForMs > activityDeadlineMs),
  );
}
