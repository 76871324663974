import { Injectable } from '@angular/core';
import { MlBackendService } from '../core/ml-backend.service';
import { firstValueFrom, map } from 'rxjs';
import { pageToLimitOffset } from './page-to-limit-offset';
import {
  CollectionDto,
  DatasetSyncConfigDto,
  FrameDto,
  LabelTypeDto,
  SnippetDto,
} from './ml-data-types';

export const PAGE_SIZE = 52;
export const SORTING_ORDER = ['newest', 'oldest', 'random'] as const;
export type DataSortingOrder = (typeof SORTING_ORDER)[number];

@Injectable()
export class MlDataService {
  constructor(private mlBackendService: MlBackendService) {}

  getFrames(
    page?: number,
    searchString?: string,
    storingOrder: DataSortingOrder = 'newest',
  ): Promise<FrameDto[]> {
    const { limit, offset } = pageToLimitOffset(page, PAGE_SIZE);
    const baseUrl = `/frames?limit=${limit}&offset=${offset}&sorting-order=${storingOrder}`;
    const url = searchString
      ? `${baseUrl}&search-string=${searchString}`
      : baseUrl;

    return firstValueFrom(this.mlBackendService.get(url));
  }

  getFrameById(frameId: number): Promise<FrameDto> {
    return firstValueFrom(
      this.mlBackendService
        .get(`/frames?search-string=frames.id=${frameId}`)
        .pipe(map(([frame]) => frame)),
    );
  }

  getAllSnippetFrames(snippetId: number): Promise<FrameDto[]> {
    return firstValueFrom(
      this.mlBackendService.get(
        `/frames?search-string=frames.snippet_id=${snippetId}`,
      ),
    );
  }

  getSnippets(
    page?: number,
    searchString?: string,
    storingOrder: DataSortingOrder = 'newest',
  ): Promise<SnippetDto[]> {
    const { limit, offset } = pageToLimitOffset(page, PAGE_SIZE);
    const baseUrl = `/snippets?limit=${limit}&offset=${offset}&sorting-order=${storingOrder}`;
    const url = searchString
      ? `${baseUrl}&snippet-search-string=${searchString}`
      : baseUrl;

    return firstValueFrom(this.mlBackendService.get(url));
  }

  getSnippetById(snippetId: number): Promise<SnippetDto> {
    return firstValueFrom(
      this.mlBackendService
        .get(`/snippets?snippet-search-string=snippets.id=${snippetId}`)
        .pipe(map(([snippet]) => snippet)),
    );
  }

  getCollections(page?: number): Promise<CollectionDto[]> {
    const { limit, offset } = pageToLimitOffset(page, PAGE_SIZE);
    const url = `/collections?limit=${limit}&offset=${offset}`;

    return firstValueFrom(this.mlBackendService.get(url));
  }

  getCollectionsFrames(
    collectionId: number,
    page?: number,
  ): Promise<FrameDto[]> {
    const { limit, offset } = pageToLimitOffset(page, PAGE_SIZE);
    const url = `/collections/${collectionId}/frames?limit=${limit}&offset=${offset}`;

    return firstValueFrom(this.mlBackendService.get(url));
  }

  async getSegmentsAiSyncConfigByCollectionId(
    collectionId: number,
  ): Promise<DatasetSyncConfigDto[]> {
    const url = `/labeling-providers/segments-ai/sync-configs?collection-id=${collectionId}`;
    return firstValueFrom(this.mlBackendService.get(url));
  }

  async getLabelTypes(): Promise<LabelTypeDto[]> {
    const url = `/label-types`;
    const labelsTypes = this.mlBackendService.get(url).pipe(
      map((labelTypes) =>
        labelTypes.map((labelType: any) => ({
          ...labelType,
          creationTimestamp: new Date(labelType.creationTimestamp),
        })),
      ),
    );
    return firstValueFrom(labelsTypes);
  }
}
