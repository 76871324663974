<app-toolbar>
  <button mat-menu-item [routerLink]="''">Home</button>
</app-toolbar>
<div class="loading-center" *ngIf="!(supervisionTask$ | async)">
  <waiting-robots
    (triggerTask)="triggerRobotSupervision($event)"
  ></waiting-robots>
  <mat-spinner></mat-spinner>
  <h2 *ngIf="!establishingConnection">Waiting for task...</h2>
  <h2 *ngIf="establishingConnection">Connecting to robot...</h2>
</div>
<div *ngIf="supervisionTask$ | async">
  <app-robot-task [supervisionTask]="supervisionTask$ | async"></app-robot-task>
</div>
