import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'full-screen-overlay',
  templateUrl: './full-screen-overlay.component.html',
  styleUrls: ['./full-screen-overlay.component.sass'],
})
export class FullScreenOverlayComponent {
  @Input()
  message: string = '';

  @Input()
  buttonMessage: string = '';

  @Input()
  countDown: number = 0;

  @Output()
  buttonClick = new EventEmitter<number>();

  onButtonClicked() {
    this.buttonClick.emit(this.countDown / 1000);
  }
}
