import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { BackendService } from '../../../app/core/backend.service';
import { MapElementDto } from '@cartken/map-types';
import {
  parseId,
  getLatLng,
  parseLatLonString,
  parseLocationName,
} from './location-helpers';

export interface ViewLocationDialogData {
  currentMapVersion?: number;
}

@Component({
  selector: 'app-view-location-dialog',
  templateUrl: './view-location-dialog.component.html',
})
export class ViewLocationDialogComponent implements AfterViewInit {
  @ViewChild('location') locationElement!: ElementRef;
  latLng?: google.maps.LatLng;
  locationAutocomplete!: google.maps.places.Autocomplete;

  constructor(
    public dialogRef: MatDialogRef<ViewLocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ViewLocationDialogData,
    private backendService: BackendService,
  ) {}

  ngAfterViewInit() {
    const options: google.maps.places.AutocompleteOptions = {};

    this.locationAutocomplete = new google.maps.places.Autocomplete(
      this.locationElement.nativeElement,
      options,
    );

    this.locationAutocomplete.addListener('place_changed', () => {
      this.updateGooglePlace();
    });

    fromEvent(this.locationElement.nativeElement, 'keyup')
      .pipe(
        map(() => this.locationElement.nativeElement.value),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe(() => this.updatePlace());
  }

  private updatePlace() {
    const str = this.locationElement.nativeElement.value;

    const id = parseId(str);
    if (id) {
      this.backendService
        .get(`/map/history?ids=${id}&version=${this.data.currentMapVersion}`)
        .subscribe((data: MapElementDto[]) => {
          if (data.length === 1) {
            this.latLng = getLatLng(data[0]);
          }
        });
      return;
    }

    const locationLatLong = parseLatLonString(str);
    if (locationLatLong) {
      this.latLng = locationLatLong;
      return;
    }

    const locationName = parseLocationName(str);
    if (locationName) {
      this.backendService
        .get(
          `/map/history?location-names=${locationName}&version=${this.data.currentMapVersion}`,
        )
        .subscribe((data: MapElementDto[]) => {
          if (data.length >= 1) {
            this.latLng = getLatLng(data[0]);
          }
        });
    }
  }

  private updateGooglePlace() {
    const googlePlace = this.locationAutocomplete.getPlace();
    const googleLocation = googlePlace?.geometry?.location;
    if (googleLocation) {
      this.latLng = new google.maps.LatLng(
        googleLocation.lat(),
        googleLocation.lng(),
      );
      return;
    }
    this.latLng = undefined;
  }

  goToLocation() {
    if (!this.latLng) {
      return;
    }
    this.dialogRef.close(this.latLng);
  }
}
