import 'reflect-metadata';

export * from './map-element.dto';

// GeoJSON Geometry.
export * from './point-geometry.dto';
export * from './linestring-geometry.dto';
export * from './polygon-geometry.dto';

// Map element properties.
export * from './april-tag-properties.dto';
export * from './pole-properties.dto';
export * from './road-edge-properties.dto';
export * from './robot-edge-properties.dto';
export * from './robot-queue-edge-properties.dto';
export * from './handover-location-properties.dto';
export * from './operation-region-properties.dto';
export * from './slippy-tiles-properties.dto';
export * from './localization-map-tiles-properties.dto';
export * from './movable-platform-edge-properties.dto';
export * from './infrastructure-properties.dto';

// Versioning.
export * from './versions.dto';
export * from './deploy-version.dto';

// Map changesets.
export * from './map-changeset-conflict.dto';
export * from './create-or-replace-map-changeset.dto';
export * from './map-changeset.dto';

// Ephemeral auxiliary data for map elements.
export * from './mapping-data-acquisition-info.dto';
export * from './map-element-transient-data.dto';
export * from './upsert-map-element-transient-data.dto';
