import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { RobotsModule } from '../robots/robots.module';
import { FormsModule } from '@angular/forms';
import { RobotOperatorOverviewComponent } from './robot-operator-overview.component';
import { RobotOperatorInfoCardComponent } from './robot-operator-info-card.component';
import { ViewNamePipe } from './viewName.pipe';
import { RobotOperatorOverviewDisplayStateService } from './robot-operator-overview-display-state.service';
import { OperatorBlackboxAggregationViewComponent } from './operator-blackbox-aggregation-view/operator-blackbox-aggregation-view.component';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';

@NgModule({
  declarations: [
    RobotOperatorOverviewComponent,
    RobotOperatorInfoCardComponent,
    ViewNamePipe,
    OperatorBlackboxAggregationViewComponent,
  ],
  providers: [RobotOperatorOverviewDisplayStateService],
  imports: [
    CoreModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    RobotsModule,
    NgxMatDatetimePickerModule,
  ],
})
export class RobotOperatorOverviewModule {}
