<div *ngIf="operationId$ | async" style="height: calc(100vh - 64px)">
  <mat-toolbar>
    <span>{{ getOperationDisplayName() | async }}</span>
    <div>
      <button
        *ngIf="canCreateOrders$ | async"
        mat-raised-button
        (click)="openCreateOrderDialog()"
        class="header-buttons"
        [disabled]="!isReadyForOrders()"
      >
        <span *ngIf="isReadyForOrders()">Add Order</span>
        <span *ngIf="!isReadyForOrders()">No Active Robot</span>
      </button>
    </div>
    <div>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          [routerLink]="'handovers'"
          queryParamsHandling="true"
        >
          <mat-icon>access_time</mat-icon>
          <span>Locations</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>

  <div style="width: 100%; height: 100%">
    <as-split direction="vertical">
      <as-split-area [size]="60" [minSize]="30">
        <mat-drawer-container style="width: 100%; height: 100%">
          <mat-drawer
            mode="side"
            position="end"
            opened
            style="width: fit-content"
          >
            <div class="operation-robot-overview">
              {{ robotsReady }} / {{ robotsCount }} available
            </div>
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>Filter</mat-label>
              <mat-select
                [(value)]="robotFilter"
                (selectionChange)="onRobotFilterChanged()"
              >
                <mat-select-trigger *ngIf="robotFilter === 'robots-with-order'">
                  Only <mat-icon>shopping_basket</mat-icon>
                </mat-select-trigger>
                <mat-select-trigger
                  *ngIf="robotFilter === 'robots-without-order'"
                >
                  Exclude <mat-icon>shopping_basket</mat-icon>
                </mat-select-trigger>
                <mat-option value=""> - </mat-option>

                <mat-option value="robots-with-order">
                  Only <mat-icon>shopping_basket</mat-icon></mat-option
                >
                <mat-option value="robots-without-order"
                  >Exclude <mat-icon>shopping_basket</mat-icon></mat-option
                >
              </mat-select>
            </mat-form-field>
            <table>
              <tbody>
                <tr *ngFor="let robotInfo of filteredRobotInfos">
                  <div
                    class="robot-status-container"
                    (click)="zoomToRobot(robotInfo)"
                    (mouseover)="showRobotRoute(robotInfo)"
                    (mouseout)="hideRobotRoute()"
                  >
                    <div
                      class="robot-img-name"
                      [class.inactive]="!robotInfo.robotReadyForOrders"
                    >
                      <img
                        src="assets/robot.png"
                        width="30"
                        style="margin-right: 5px"
                      />
                      <span class="robot-name">
                        {{ robotInfo.name }}
                      </span>
                    </div>

                    <div class="battery-status-section">
                      <app-battery-status
                        [batteryPercentage]="robotInfo.batteryPercentage"
                        [charging]="robotInfo.isCharging"
                      ></app-battery-status>
                    </div>

                    <span
                      *ngIf="canOpenCloseRobots$ | async"
                      class="lock-icon"
                      [class.inactive]="
                        areCompartmentsOpen(robotInfo.compartments) ||
                        !robotInfo.isOnline
                      "
                    >
                      <mat-icon>{{ lidStatusToIconName(robotInfo) }}</mat-icon>
                      <mat-slide-toggle
                        [ngModel]="
                          areAllCompartmentsLocked(robotInfo.compartments)
                        "
                        (change)="setRobotLockStatus(robotInfo, $event.checked)"
                      ></mat-slide-toggle>
                    </span>
                    <div class="robot-status-section">
                      <mat-icon *ngIf="robotInfo.hasOrders"
                        >shopping_basket</mat-icon
                      >
                    </div>
                    <button
                      *ngIf="
                        robotInfo.isVirtualRobot &&
                        robotInfo.robotReadyForOrders
                      "
                      mat-mini-fab
                      (click)="completeCurrentHandovers(robotInfo)"
                      [disabled]="!robotInfo.isWaitingForHandover"
                    >
                      <mat-icon>redo</mat-icon>
                    </button>
                  </div>
                </tr>
              </tbody>
            </table>
          </mat-drawer>
          <mat-drawer-content>
            <map
              [mapOptions]="googleMapOptions"
              (mapReady)="onGoogleMap($event)"
            ></map>
          </mat-drawer-content>
        </mat-drawer-container>
      </as-split-area>
      <as-split-area [size]="40" [minSize]="5">
        <div>
          <div class="filter">
            <mat-form-field appearance="fill">
              <mat-label>Text Search</mat-label>
              <input
                matInput
                #textSearchInput
                placeholder='"Some Street" -ExcludeThis'
                value=""
              />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Order Status</mat-label>
              <mat-select
                [(value)]="orderStatusFilter"
                (selectionChange)="onOrderStatusFilterChanged()"
              >
                <mat-option value="active">Active</mat-option>
                <mat-option value="final">Final</mat-option>
                <mat-option
                  *ngFor="let status of orderStatuses"
                  [value]="status"
                >
                  {{ status }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <table mat-table matSort [dataSource]="orderQueue">
            <ng-container matColumnDef="robot">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Robot</th>
              <td mat-cell *matCellDef="let row">
                <div *ngIf="row" class="robot-img-name">
                  <img
                    src="assets/robot.png"
                    width="30"
                    style="margin-right: 5px"
                  />

                  <span class="robot-name">
                    {{ row.robot }}
                  </span>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="created">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Order Created
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row.created | prettyTime }}
              </td>
            </ng-container>

            <ng-container matColumnDef="testOrder">
              <th mat-header-cell *matHeaderCellDef>Test Order</th>
              <td mat-cell *matCellDef="let row">
                <mat-icon *ngIf="row.testOrder">build</mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="id">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                ID / External ID
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row.id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="compartment">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Compartment
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row.order.assignedCompartmentId }}
              </td>
            </ng-container>

            <ng-container matColumnDef="communication">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Communication
              </th>
              <td mat-cell *matCellDef="let row" class="communication-icons">
                <mat-icon
                  class="material-icons-outlined"
                  *ngIf="hasDeliveredMessages(row.order)"
                  >chat</mat-icon
                >
                <mat-icon
                  class="material-icons-outlined"
                  *ngIf="hasPendingMessages(row.order)"
                  >sms</mat-icon
                >
                <mat-icon
                  class="material-icons-outlined"
                  *ngIf="hasMessageSendFailures(row.order)"
                  >sms_failed</mat-icon
                >
                <mat-icon
                  class="material-icons-outlined"
                  *ngIf="hasInboundMessages(row.order)"
                  >comment</mat-icon
                >

                <mat-icon
                  class="material-icons-outlined"
                  *ngIf="hasCompletedPhoneCall(row.order)"
                  >call</mat-icon
                >

                <mat-icon
                  class="material-icons-outlined"
                  *ngIf="hasNotCompletedPhoneCall(row.order)"
                  >call_quality</mat-icon
                >

                <mat-icon
                  class="material-icons-outlined"
                  *ngIf="hasCompletedPhoneCall(row.order)"
                  >call</mat-icon
                >

                <mat-icon
                  class="material-icons-outlined"
                  *ngIf="hasSentEmail(row.order)"
                  >send</mat-icon
                >

                <mat-icon
                  class="material-icons-outlined"
                  *ngIf="hasFailedEmail(row.order)"
                  >cancel_schedule_send</mat-icon
                >
                <communication-log-overlay
                  class="communication-overlay"
                  *ngIf="row.order.communicationLog.length"
                  [order]="row.order"
                >
                </communication-log-overlay>
              </td>
            </ng-container>

            <ng-container matColumnDef="displayName">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Display Name
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row.order.displayName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="pickup">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Pickup</th>
              <td mat-cell *matCellDef="let row">
                {{ row.pickup }}
              </td>
            </ng-container>

            <ng-container matColumnDef="dropoff">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Dropoff</th>
              <td mat-cell *matCellDef="let row">
                {{ row.dropoff }}
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Order Status
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row.status }}
              </td>
            </ng-container>

            <ng-container matColumnDef="icons">
              <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row">
                <div class="icons">
                  <button
                    [disabled]="!row.compartmentCanBeOpenend"
                    mat-icon-button
                    matTooltip="Open compartment"
                    (click)="
                      $event.stopPropagation(); openCompartmentForOrder(row.id)
                    "
                  >
                    <mat-icon>lock_open</mat-icon>
                  </button>
                  <button
                    *ngIf="canEditOrderStatus$ | async"
                    mat-icon-button
                    matTooltip="Edit Order State"
                    (click)="$event.stopPropagation(); editOrder(row)"
                  >
                    <mat-icon>edit_note</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    (click)="
                      $event.stopPropagation();
                      onOpenTrackingLink(row.order.trackingCode)
                    "
                  >
                    <mat-icon>exit_to_app</mat-icon>
                  </button>
                  <span style="padding-right: 5px"></span>
                  <button
                    *ngIf="canCancelOrders$ | async"
                    mat-icon-button
                    aria-label="Cancel Order"
                    (click)="$event.stopPropagation(); cancelOrder(row)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              [class.order-table-grey]="selectedPickupHandoverAlias"
              [class.order-table-red]="row.actionRequired"
              class="element-row"
              (click)="showOrderDetails(row)"
              (mouseover)="showOrderOnMap(row)"
              (mouseout)="hideOrderOnMap()"
            ></tr>
          </table>
          <mat-paginator
            #paginator
            [length]="resultsLength"
            [pageSizeOptions]="pageSizeOptions"
            aria-label="Select page"
          >
          </mat-paginator>
        </div>
      </as-split-area>
    </as-split>
  </div>
</div>
